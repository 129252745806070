export const HELPFUL_LINKS = [
    {
      label: 'Home',
      url: '/'
    },
    {
      label: 'Treatment on Medical Departures',
      url: '/treatments'
    },
    {
      label: 'Become a Partner',
      url: '/partner/signup'
    },
    {
      label: 'Locations',
      url: '/locations'
    },

    {
      label: 'Sitemap',
      url: '/sitemap'
    },
  ];