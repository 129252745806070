
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedPrimeNgModule } from './shared-prime-ng.module';
import { CoreComponentsModule } from './components/core-components.module';
import { CorePipesModule } from './pipes/core-pipes.module';
import { SharedDirectivesModule } from '@lib/shared-directives/shared-directives.module';

@NgModule({
  imports: [
    SharedPrimeNgModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreComponentsModule,
    CorePipesModule,
    SharedDirectivesModule
  ],
  exports: [
    SharedPrimeNgModule,
    CoreComponentsModule,
    CorePipesModule,
    SharedDirectivesModule
  ],
  declarations: []
})
export class SharedCoreModule { }
