import {Inject, Injectable} from '@angular/core';
import { Observable, observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {ApiResponse} from '@lib/models/api-response.model';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  TOKEN_TYPE_EMAIL_CONFIRMATION = 100;
  TOKEN_TYPE_EMAIL_CHANGING_CONFIRMATION = 101;
  TOKEN_TYPE_ACCESS_TOKEN = 200;
  TOKEN_TYPE_SET_PASSWORD = 102;

  apiHost: string;

  constructor(
    private http: HttpClient,
    @Inject('env') private env: any
  ) {
    this.apiHost = env.api.medid_v2;
  }

  issueEmailConfirmationToken() {
    this.issueToken(this.TOKEN_TYPE_EMAIL_CONFIRMATION);
  }

  issueEmailChangingConfirmationToken() {
    // this.TOKEN_TYPE_EMAIL_CHANGING_CONFIRMATION
    console.warn('issueEmailChangingConfirmationToken() is Not Supported');
  }

  issueSetPasswordToken() {
    this.issueToken(this.TOKEN_TYPE_SET_PASSWORD);
  }

  issueToken(tokenType: number): string {
    return 'TOKEN IS NOT IMPLEMTNED';
  }

  validateToken(token: string): boolean {
    // Make sure token is active and not expired
    return false;
  }

  validateSetPasswordToken(token: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.apiHost + 'verify-token/' + this.TOKEN_TYPE_SET_PASSWORD, { token });
  }

  validateEmailConfirmationToken(token: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.apiHost + 'verify-token/' + this.TOKEN_TYPE_EMAIL_CONFIRMATION, { token });
  }

  validateEmailConfirmationTokenV2(code: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.apiHost + 'verify-confirmation-code', { code });
  }

  getAccessToken(): string {
    return window.localStorage.getItem('access_token') as any;
  }

  setAccessToken(token: string): void {
    return window.localStorage.setItem('access_token', token);
  }
}
