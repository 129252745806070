<section class="why-partners step-partner-wrapper">
  <div class="container-main">
    <div class="row desktop-only title-partner-fixed">
      <div class="col-md-6">
          <h1 class="step-title">Why Become A {{ siteName == 'dental' ? 'Dental' : 'Medical'}} Departures Partner </h1>
          <p class="step-subtitle" i18n="@@partnerSignUpDescription">
            {{siteName | titlecase}} Departures is the #1 site to find a global clinic. When our
            customers visit a global clinic they save 70% on their dental care.
            {{siteName | titlecase}} Departures partners with {{ clinicsCount }}+ clinics in {{ countriesCount }}
            different countries
            around the globe.
          </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mobile-only">
        <div class="title-partner-wrapper">
          <h1 class="step-title">Why Become A Dental Departures Partner </h1>
          <p class="step-subtitle" i18n="@@partnerSignUpDescription">
            {{siteName | titlecase}} Departures is the #1 site to find a global clinic. When our
            customers visit a global clinic they save 70% on their dental care.
            {{siteName | titlecase}} Departures partners with {{ clinicsCount }}+ clinics in {{ countriesCount }}
            different countries
            around the globe.
          </p>
        </div>
      </div>
      <div class="col-md-6"></div>
      <div class="col-md-6">
        <div class="feature-list-wrapper">
          <div class="features-list" *ngFor="let why of whyDepartures; let i = index">
            <div class="feature-item">
              <span class="feature-title">{{i + 1}}</span>
              <div>
                <h4 class="feature-title">{{why.title}}</h4>
                <p class="feature-subtitle">{{why.subtitle}}</p>
              </div>
            </div>
            <img alt="reason to partner icon" [src]="why.icon">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="clinic-partner-footer">
    <div class="container-main">
      <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6 text-right"> <button pButton class="mw-151 wb-100-m"
            (click)="partnerFormState.emit(partnershipFormEnum.SIGNUP_FORM)">Get Started</button></div>
      </div>
    </div>
  </div>
</section>