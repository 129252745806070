<div class="container-main container-body container-not-found ">
  <div class="row">
    <div class="col-md-8">
      <h2 i18n="@@pageNotFoundDescription">Sorry, the page you were looking for cannot be found</h2>
      <p>Here are some helpful links instead:</p>
      <div class="links">
        <ng-container *ngFor="let h of helpFulLinks">
          <a [routerLink]="h.url">{{h.label}}</a>
        </ng-container>
      </div>
    </div>
    <div class="col-md-4 text-center">
      <img src="https://md-global-static.s3.ap-southeast-1.amazonaws.com/404.gif" />
    </div>
  </div>
</div>