export const environment = {
  production: true,
  site_id: 4,
  host: 'https://www.medicaldepartures.com',
  host_domain: 'medicaldepartures.com',
  api: {
    core: 'https://api.medicaldepartures.com/core/v1/',
    keendex: 'https://api.medicaldepartures.com/keendex/v1/',
    medid: 'https://api.medicaldepartures.com/medid/v1/',
    medid_v2: 'https://api.medicaldepartures.com/medid/v2/',
    staglCore: 'https://stagl-api.medicaldepartures.com/core/v1/',
    staglKeendex: 'https://stagl-api.medicaldepartures.com/keendex/v1/',
    jwt_prefix: 'JWT',
    accepted_term_id: 1
  },
  domains: {
    account: 'https://account.medicaldepartures.com',
    dd: 'https://www.dentaldepartures.com'
  },
  url: {
    account_login: 'https://account.medicaldepartures.com/external-login',
    account_logout: 'https://account.medicaldepartures.com/external-logout',
    account_profile: 'https://account.medicaldepartures.com/external-edit-profile',
    amp_domain: 'https://amp.medicaldepartures.com',
    csv_sitemaps: 'https://cdn.medicaldepartures.com/sitemap'
  },
  folder: {
    staticSearch: 'clinics',
    clinic: 'clinic'
  },
  buckets: {
    static: 'https://md-global-static-staging.s3.amazonaws.com/'
  },
  links: {
    reviewcentre_write_review: 'http://www.reviewcentre.com/write-a-review-3603145.html',
    feefo: ''
  },
  recaptcha_site_key: '6LdOuLEUAAAAAGShYqw1CDiJpLkRSz79w-NDuqRR',
  recaptcha_enabled: true,
  schema: {
    name: 'Medical Departures',
    logo: 'https://img.medicaldepartures.com/md-logo-icon.png',
    url: 'https://www.medicaldepartures.com',
    sameAs: [
      'https://www.facebook.com/medicaldepartures/',
      'https://www.instagram.com/medical_departures/',
      'https://twitter.com/MedDepartures',
      'https://www.linkedin.com/company/medical-departures-inc-',
      'https://www.youtube.com/channel/UC__yzy9ywBjR9gm34ABsZlA'
    ],
    email: 'care@medicaldepartures.com'
  },
  phones: {
    ctm: '+1-855-912-8960',
    au: '61-1300-293-192',
    gb: '44-800-011-9602',
    us: '+1-855-912-8960',

    mx: '52-81-4160-5467',
    ca: '1-888-673-6134',
    nz: '64-4-888-0127',
    th: '66-2508-8449'
  },
  social_app_id: {
    google: '224518026735-l50n55jto7h7vr6s6pmtv2f0oij5bh1v.apps.googleusercontent.com',
    facebook: '203605636796586'
  },
  apple_credentials: {
    clientId: 'com.medicaldepartures.bundle.backend',
    scope: 'name email',
    redirectURI: 'https://www.medicaldepartures.com',
    state: 'initial',
    usePopup: true
  }
};
