import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { load } from 'recaptcha-v3';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { RecaptchaActions } from './recaptcha.models';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  private recaptchaPromise!: Promise<any>;
  constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject('env') private env: any) { }

  init = () => {
    if (isPlatformBrowser(this.platformId)) {
      this.recaptchaPromise = load(this.env.recaptcha_site_key);
      return this.recaptchaPromise;
    }

    return null;
  }

  generateToken = (action: RecaptchaActions): any => {
    return new Observable(subscriber => {
      if (this.env.recaptcha_enabled) {
        this.recaptchaPromise.then(recaptcha => {
          recaptcha.execute(action).then((token: any) => {
            subscriber.next(token);
          });
        });
      } else {
        subscriber.next(null);
      }
    });
  }
}
