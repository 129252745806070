<button pButton class="wb-100 mb-5 p-button-outlined p-button-label-zero p-button p-button-secondary" *ngIf="cta && cta.id==null">
    <span i18n="@@saveOnANewSmile">Save up to 70% on a new smile</span>
</button>
<ng-container *ngIf="cta && cta.id!=null">
    <a [href]="cta.landingPage" [target]="'_blank'">
        <button pButton class="wb-100 mb-5 p-button-outlined p-button-label-zero p-button p-button-secondary">
            <span>{{cta.cta}}</span>
        </button>
    </a>
</ng-container>
