<div class='price-wrapper'>
    <ng-content></ng-content>
    <p-accordion activeIndex="0" [multiple]="true" class="accordion-price-wrapper">
        <ng-container *ngFor="let accordionData of clinicPrices; let i = index;">
            <p-accordionTab header="{{( i + 1 ) + '. ' +accordionData.name}}">
                <app-dynamic-table
                  [tableData]="{ data: accordionData.children,cols: pricesColumn }"
                  [currency]="currency"
                  [showAdditionalInfo]="showAdditionalInfo"
                  [(selectedTableRow)]="selectedProcedures"
                  [allowSelect]="allowSelect"
                  [allowHeaderSelect]="allowHeaderSelect"
                  (onSelectRowOrDataChange)="onSelectRowOrDataChange($event)">
                </app-dynamic-table><br/>
            </p-accordionTab>
        </ng-container>
    </p-accordion>
    <br/>
    <ng-container *ngIf="hasPrivacy">
        <div class="agree-terms" i18n="@@buttonPaymentPolicy">
            <p>By selecting the button below, I agree to the
                <a [routerLink]="'/terms'">Terms & Conditions</a> and I acknowledge the  <a [routerLink]="'/privacy'">Privacy Policy</a>.
            </p>
            <br/>
        </div>
        <br/><br/>
    </ng-container>
    <ng-container *ngIf="isInModal">
        <br/>  <br/> <br/> <br/>
    </ng-container>
    <div class="footer-prices-wrapper" *ngIf="isInModal">
        <div class="progressBar" *ngIf="isLoadingEmailSend">
            <p-progressBar mode="indeterminate" [style]="{ height: '3px' }"></p-progressBar>
        </div>
        <div class="row" [class.email-sending]="isLoadingEmailSend">
            <div class="col">
                <div class="info-price">
                    <div class="title" i18n="@@clinicQuoteSave">
                        You save
                    </div>
                    <div class="value">
                        {{ this.summary.diff ? this.summary.diff : 0 }}%
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="info-price">
                    <div class="title" i18n="@@clinicQuoteTotal">
                        Total
                    </div>
                    <div class="value">
                        {{ this.summary.discounted | priceTag: { sourceCurrency: currency, showSymbol:
                        true } }}
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="info-price">
                    <div class="title">
                        {{ durationSummary ? clinicQuoteVisitDuration : '' }}
                    </div>
                    <div class="value">
                        {{ durationSummary }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6" *ngIf="form">
                <form [formGroup]="form" (submit)="isLoadingEmailSend ? null : onSubmit()">
                    <div class="p-inputgroup">
                        <input type="email" pInputText i18n i18n-placeholder="@@formLabelEmail" placeholder="Email Address" formControlName="email_address" />
                        <button type="submit" pButton  class="custom-btn-email"
                            [disabled]="isLoadingEmailSend">
                            <i class="fa fa-paper-plane"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</div>

<p-toast position="bottom-center" key="bcprice"></p-toast>
