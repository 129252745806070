import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../core/src/lib/data/core-api.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  constructor(private apiService: ApiService) { }

  submitClinicReview(model: any) {
    return this.apiService.reviewClinicPOST(model);
  }
}
