export const COUNTRY_EXCEPTIONS = [
  'philippines',
  'malaysia'
];


export const COUNTRY_EXCEPTIONS_CASE_2 = [
  'mexico',
  'turkey',
  'colombia',
  'india',
  'united arab emirates',
];
