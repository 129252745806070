export class MenuItem {
  public label: string;
  public link: string;
  public isExternalLink: boolean;
  public tooltip: string;
  public cssClass: string;
  public icon: string;
  public children: MenuItem[];

  constructor(
    label: string = '',
    link: string = '',
    isExternalLink = false,
    tooltip: string = '',
    cssClass: string = '',
    icon: string = '',
    children: MenuItem[] = []
  ) {
    this.label = label;
    this.link = link;
    this.isExternalLink = isExternalLink;
    this.tooltip = tooltip;
    this.cssClass = cssClass;
    this.icon = icon;
    this.children = children;
  }

  static createInternalLink(label = '', link = ''): MenuItem {
    return new MenuItem(label, link, false, label, '', '', []);
  }

  static createInternalLinkWithCustomCssClass(label = '', link = '', cssClass = ''): MenuItem {
    return new MenuItem(label, link, false, label, cssClass, '', []);
  }

  static createInternalLinkWithIcon(label: string = '', link: string = '', icon: string = '') {
    return new MenuItem(label, link, false, label, '', '', []);
  }

  static createInternalLinkWithChildren(label = '', link = '', children: MenuItem[] = []): MenuItem {
    return new MenuItem(label, link, false, label, '', '', children);
  }

  static createInternalLinkWithIconAndChildren(label = '', link = '', icon = '', children: MenuItem[] = []): MenuItem {
    return new MenuItem(label, link, false, label, '', icon, children);
  }

  static createExternalLink(label = '', link = ''): MenuItem {
    return new MenuItem(label, link, true, label, '', '', []);
  }

  static createSocialLink(link: string = '', icon: string = ''): MenuItem {
    return new MenuItem('', link, true, '', '', icon, []);
  }
}
