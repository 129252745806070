<div class="swiper-wrapper-carousel">
    <swiper class="swiper-container" *ngIf="'photo-only' == type || 'photo-modal-only' == type"
        [ngClass]="customWrapper" fxFlex="auto" [config]="config" [disabled]="disabled"
        (indexChange)="onIndexChange($event)" (swiperTransitionStart)="onSwiperEvent('transitionStart')"
        (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
        <ng-container *ngFor="let item of items">
            <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
                <ng-container *ngIf="'photo-only' == type">
                    <div class="photo photo-wrapper" (click)='onClickEvent.emit(true)'>
                        <img src="{{item.image}}" alt="{{item.clinicName}}">
                    </div>
                </ng-container>
                <ng-container *ngIf="'photo-modal-only' == type">
                    <div class="photo" (click)='onClickEvent.emit(item.index)'
                        style="background-image: url({{item.image}});">
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </swiper>
    <ng-container *ngIf="hasTotalSlide">
        <div class="total-slide-wrapper">
            {{currentIndex + 1}} / {{ items ? items.length : 0 }}
        </div>
    </ng-container>
</div>

<div [class]="customWrapper" *ngIf="'photo-only' != type && 'photo-modal-only' != type">
    <p-carousel [value]="items" [indicatorStyleClass]='"circular"' [showIndicators]="showIndicators"
        [showNavigators]="showNavigators" [numVisible]="1" [autoplayInterval]='autoPlayInterval' [numScroll]="1"
        [responsiveOptions]="responsiveOptions">
        <ng-container *ngIf="'photo' == type">
            <ng-template let-item pTemplate="items">
                <div class="photo" style="background-image: url({{item.image}});" (click)='onClickEvent.emit(true)'>
                </div>
            </ng-template>
        </ng-container>
        <!-- <ng-container *ngIf="'photo-modal-only' == type">
            <ng-template let-item let-i="index" pTemplate="items">
                <div class="photo" (click)='onClickEvent.emit(item.index)'
                    style="background-image: url({{item.image}});">
                </div>
            </ng-template>
        </ng-container> -->
        <ng-container *ngIf="'clinic' == type">
            <ng-template let-item pTemplate="items">
                <app-card-departures [clinic]="item" class="clinic"></app-card-departures>
            </ng-template>
        </ng-container>
    </p-carousel>
</div>
