<div class="about-clinic-wrapper">
    <ng-container *ngIf="hasAboutUs">
        <h1>{{title}}</h1>
        <div>
            <p-scrollPanel [style]="{ width: '100%', height: '201px' }">
                <div [innerHTML]="clinic.info.about"></div>
            </p-scrollPanel>
        </div>
    </ng-container>
    <hr />
    <h3 *ngIf="imagesBeforeAndAfter" i18n="@@beforeAndAfter">Before & After</h3>
    <div class="carousel" *ngIf="imagesBeforeAndAfter">
        <swiper class="swiper-containers" fxFlex="auto" [config]="config">
            <ng-container *ngFor="let clinicImages of imagesBeforeAndAfter">
                <div class="swiper-slide swiper-options-header">
                    <div fxLayout="column">
                        <div class="wrapper-images"
                            [ngStyle]="{'background-image': 'url(' + clinicImages.image + '?width=320px' + ')'}"
                            (click)="openImageGallery(clinicImages)">

                        </div>
                    </div>
                </div>
            </ng-container>
        </swiper>
    </div>
    <hr />
    <p-accordion  class="accordion-more-wrapper" [activeIndex]="0">
        <ng-container>
            <p-accordionTab [header]="additionalServices">
                <div class="clinic-extras">
                    <div *ngFor="let item of this.extras">
                        <ng-container *ngIf="item.isAvailable">
                            <i class="fas fa-{{ item.icon }}"></i>
                            <div class="label">
                                {{ item.label }}
                            </div>
                        </ng-container>
                    </div>
                </div>
            </p-accordionTab>
        </ng-container>
    </p-accordion>
    <hr />
    <ng-container *ngIf="clinic && clinic.info && clinic.info.payment_methods && clinicPaymentMethodLists">
    <h3 i18n="@@acceptedPaymentMethod">Accepted Payment Methods</h3>
    <div>
            <ng-container *ngFor="let p of clinicPaymentMethodLists;">
                <ng-container *ngIf="p.isAvailable">
                    <span >
                        <img [src]="p.photoLink" [pTooltip]="p.label" [tooltipPosition]="'top'" >
                    </span> &nbsp;
                </ng-container>
            </ng-container>
        <hr />
    </div>
</ng-container>
    <p-accordion [multiple]="true" class="accordion-more-wrapper">
        <ng-container>
            <p-accordionTab>
                <p-header>
                    <h3 class="custom-tab-title"><em i18n="@@operationHours">Operation Hours</em> <span> {{ clinic.timezone}} </span></h3>
                 </p-header>
                <div class="custom-table-no-border">
                    <app-dynamic-table [tableData]="{
                    data: workingHrsList,
                    cols: workingHrsColumn }"></app-dynamic-table>
                </div>
            </p-accordionTab>
        </ng-container>
    </p-accordion>
</div>

<app-image-gallery [activeIndex]="currentGalleryIndex" [images]="imagesBeforeAndAfter"
    (onChangeVisible)="onChangeVisible($event)" [isVisible]="visibleGallery"></app-image-gallery>
