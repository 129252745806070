import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export class CustomValidators {

  public matchEmailsValidator(matchTo: string): (AbstractControl: any) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
      !!control.parent.value &&
      control.value === (control.parent.controls as any)[matchTo].value
        ? null
        : { isMatching: true };
    };
  }

  public promotionCodeValidator(availablePromotionCodes: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        return availablePromotionCodes.find(c => c.toLowerCase() === control.value.toLowerCase().trim())
          ? null
          : { promoCode: { value: control.value } };
      }

      return null;
    };
  }

  public checkWhiteSpace(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      return !value.trim() ? {whiteSpace: true} : null;
    };

  }
}
