import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Params, Router } from '@angular/router';
import { AuthService } from '@lib/auth/auth.service';
import { FavoriteService } from '@lib/features/favorite/favorite.service';
import { LogService } from '@lib/features/log/log.service';
import { PageService } from '@lib/features/page/page.service';
import { IClinicSearchResultModel, ISearchResult } from '@lib/modules/search/search.models';
import { SearchService } from '@lib/modules/search/search.service';
import { BreadcrumbItem } from '@lib/shared/components/breadcrumb/breadcrumb.models';
import { PagingService } from '@lib/shared/components/paging/paging.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { UserActivityService } from '@lib/features/user-activity/user-activity.service';
import { UserActivities } from '@lib/constants/user-activities';
import { IClinicSearchResult } from '@lib/modules/search/search.models';
import { WindowResizeHelper } from '@lib/shared-core/helper/window.resize.helper';
import { zip } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';


@UntilDestroy()
@Component({
  selector: 'app-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss']
})
export class SearchPanelComponent implements OnInit, OnDestroy {


  private readonly mobilePageSize: number = 4;
  private readonly desktopPageSize: number = 8;

  @Input() clinicSearchResult: IClinicSearchResult | undefined | any;

  @Input()
  isLoadMore: boolean = true;

  @Input()
  name: any = $localize`:@@siteName:Dental`;

  @Input()
  isHomePage: boolean = false;

  @Input()
  isOtherPage: boolean = false;

  @Input()
  isStaticClinicSearch: boolean = false;

  @Input()
  staticUrl: any;

  @Input()
  isLoading: boolean = true;

  @Input()
  showPromoButton: boolean = true;

  @Input()
  homepageDefaultSearchKey: string = '';

  isMobile: boolean = false;
  isMaxPage: boolean = false;
  isError: boolean = false;
  model: IClinicSearchResultModel | any;
  currentUser: any;
  currentPage: number = 1;
  currentLinks: any;

  private params!: Params | any ;
  private sort = '';
  private filter = '';
  isBrowser: boolean;
  isSSR: boolean;
  baseUrl = '';
  private query: any = '';
  private initiallyLoaded = false; // flag loaded content
  private platformWindow;

  constructor(
    @Inject(PLATFORM_ID) platformId: any,
    @Inject(DOCUMENT) private dom: any,
    private searchService: SearchService,
    private pageService: PageService,
    private pagingService: PagingService,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private logService: LogService,
    public favoriteService: FavoriteService,
    private windowResizeHelper: WindowResizeHelper,
    private userActivityService: UserActivityService,
    @Inject('env') public env: any) {

      this.platformWindow = isPlatformBrowser(platformId) ? window : this.dom;
      this.isLoading = true;
      this.isBrowser = isPlatformBrowser(platformId);
      this.isSSR = isPlatformServer(platformId);
      if (isPlatformBrowser(platformId)) {
        this.isMobile = window.innerWidth < this.windowResizeHelper.mobileBreakpoint;
      }
  }

  ngOnInit() {
    this.baseUrl = this.env.host + ( this.env.site_id == 4 ?  '/clinic/' : '/dentist/');
    this.windowResizeHelper.isMobile().pipe(untilDestroyed(this)).subscribe(isMobile => {
      this.isMobile = isMobile;
    });

    this.searchService.query.pipe(untilDestroyed(this)).subscribe( query => this.query = query);

    // Handles sort and filter observables
    // Zip combines multiple observables and only emits when all observables have emitted values
    // With zip, values only emit once at a time
    // In this use case ensure querySortParams and queryFilterParams emits together. See this.resetStates()
    zip(
      this.searchService.querySortParams, this.searchService.queryFilterParams)
      .pipe(untilDestroyed(this)).subscribe(([sort, filter]) => {

        this.sort = sort ?? '';
        this.filter = filter ?? '';

        if (sort !== null || filter !== null) { // Check if any of sort or filter has value after resetting both
          // Check for value or empty string (i.e. either has been unselected)
          if ((sort || filter) || ((sort === '') || (filter === '')) && !this.isHomePage) {
            if (this.model) {
              this.model.clinics = [];
              this.model.meta.total = 0;
            }
            this.isLoading = true;
            this.onSortOrFilterChanges();
          }
        }
      });

    this.route.params.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe((params: Params) => {
      this.model = null as any;
      this.params = params;
      if (this.initiallyLoaded && this.isStaticClinicSearch) {
        this.fetchSearchResult();
      }

    });

    // Handles routes path params and Query params
    this.route.paramMap.pipe(untilDestroyed(this)).subscribe((p: ParamMap) => {
      this.isLoading = true;
      this.model = undefined as any;
      this.query = p.get('query')?.trim();


      if (this.query && !this.isStaticClinicSearch && !this.isHomePage) {
        this.searchService.updateQuery(this.query);
      }

      this.filter = new URLSearchParams(window.location.search).get('clinics') ?? '';
      this.sort = new URLSearchParams(window.location.search).get('sort') ?? '';

      if (!this.isStaticClinicSearch) {
        if (this.model) {
          this.model.clinics = [];
          this.model.meta.count = 0;
        }
        this.isLoading = true;
        this.onSortOrFilterChanges();
      }
    });

    if (this.isStaticClinicSearch) {
      this.processStaticSearch();
    } else {
      this.isLoading = true;
      const initSize = this.isMobile ? this.mobilePageSize : this.desktopPageSize;
      if (this.isHomePage) {
        this.searchService
          .getSearchResult(this.homepageDefaultSearchKey, '', '', initSize, '')
          .then((res: ISearchResult) => {
            this.handleSearchResultResponse(res);
            this.isLoading = false;
          });
      } else {
        const getSearchResult = this.searchService
          .getSearchResult(this.query ?? '', this.sort ?? '', '', initSize, this.filter ?? '');

        getSearchResult.then((res: ISearchResult) => {
          this.isLoading = false;
          this.handleSearchResultResponse(res);
          this.trackSponsoredAd();
        }).catch((error) => {
          this.isLoading = false;
          this.isError = true;
        });
      }
    }
    this.initiallyLoaded = true;
  }

  private processStaticSearch() {
    this.pagingService.redirectPagingQueryStringToFriendlyUrl(this.route, this.router.url);
    this.pagingService.firstPageHasNumberInUrl(404)
      .then(result => {

        if (result === false) {
          if (!this.initiallyLoaded) this.initiallyLoaded = true;
          this.fetchSearchResult(this.sort);
        }
      })
      .catch((err) => this.logService.error(err));
  }

  private fetchSearchResult(sort: string = this.sort) {
    this.sort = sort;
    this.isLoading = true;
    this.searchResult();
  }

  get hasSortOrFilter(): boolean {
    return (!!this.sort || !!this.filter);
  }


  private searchResult() {
    const initSize = this.isMobile ? this.mobilePageSize : this.desktopPageSize;
    this.searchService.getStaticSearchResult(this.params, this.sort, initSize, 0, this.filter)
      .then((result: ISearchResult) => {
        this.isLoading = false;
        this.model = this.searchService.processStaticSearchResult(result, !this.hasSortOrFilter) as any;
        this.isLoading = false;
        this.currentLinks = this.model.searchResult.raw.links;
        this.updateMaxPage();
      })
      .catch((error: any) => {
        this.isLoading = false;
        this.logService.error(error);
        this.pageService.response404(null);
      });
  }


  private updateMaxPage() {
    if (this.model && this.model.searchResult) {
      if (this.currentLinks && ('self' in this.currentLinks && !('last' in this.currentLinks))) {
        this.isMaxPage = true;
      }
      if (this.currentLinks && ('first' in this.currentLinks && !('last' in this.currentLinks))) {
        this.isMaxPage = true;
      }
    } else {
      this.isMaxPage = true;
    }
  }

  private navigatePaging(link: string) {
    this.isLoading = true;
    let newLink = link;
    if (newLink && newLink.includes(`size=${this.desktopPageSize}`)) {
      newLink = newLink.replace(`size=${this.desktopPageSize}`, `size=15`);
    }
    else if (newLink && newLink.includes(`size=${this.mobilePageSize}`)) {
      newLink = newLink.replace(`size=${this.mobilePageSize}`, `size=15`);
    }
    this.searchService.getSearchResult(
      this.model.query, this.model.searchResult.raw.meta.sort, newLink, 15, this.filter)
      .then((res: ISearchResult) => {
        this.isLoading = false;
        this.handleSearchResultResponse(res);
        this.updateMaxPage();
      });
  }

  private loadMoreStaticPage() {
    this.isLoading = true;
    this.searchService.getStaticSearchResult(
      this.params, this.model.searchResult.raw.meta.sort, 10, this.currentPage + 1, this.filter)
      .then((res: ISearchResult | any) => {
        const newResult = this.searchService.processStaticSearchResult(res, !this.hasSortOrFilter);
        if (newResult) {
          this.model.clinics = [...this.model.clinics, ...newResult.clinics];
          this.currentLinks = newResult.searchResult.raw.links;
        }
        this.currentPage = this.currentPage + 1;
        this.isLoading = false;
        if (!this.isLoadMore) {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }
        this.updateMaxPage();
      });
  }

  private handleSearchResultResponse(res: ISearchResult) {
    const result = this.searchService.processSearchResult(res);
    if (result.clinics && result.clinics.length > 0) {
      if (!this.model) {
        this.model = result;
      } else {
        this.model.clinics = [...this.model.clinics, ...result.clinics];
        this.model.links = result.links;
        this.model.query = result.query;
        this.model.meta = result.meta;
        this.currentLinks = result.links;
      }
    }
    this.updateMaxPage();
    if ((result.clinics.length === 1) && !this.hasSortOrFilter) {
      this.router.navigateByUrl(`${( this.env.site_id == 4 ?  '/clinic/' : '/dentist/')}${result.clinics[0].slug}`, { replaceUrl: true });
    }
    this.searchService.updateQuery(result.query);
    this.pageService.setDescription('Search Result');
    this.pageService.setBreadcrumb([
      new BreadcrumbItem($localize`:@@searchBreadcrumbs:`, this.router.url)
    ]);
    if (!this.initiallyLoaded) this.initiallyLoaded = true;


  }

  private trackSponsoredAd() {
      this.userActivityService.IPInfo.pipe((untilDestroyed(this))).subscribe( res => {
        if (res && this.model) {
          const [sponsoredClinic] = this.model?.clinics.filter((clinic: any) => clinic.type === 'SPONSORED'
            && this.model.searchCriteria.location);
          if (sponsoredClinic) {
            this.userActivityService.logSponsoredAdActivity(sponsoredClinic.id,
              UserActivities.SponsoredAdAction.Impression, this.model.searchCriteria.location[0].id);
          }
        }
      });
  }

  onNextPage = (): void => {
    if (this.isStaticClinicSearch) {
      this.loadMoreStaticPage();
    }
    else { this.navigatePaging(this.model.links.next); }
  }
  onPreviousPage = (): void => this.navigatePaging(this.model.links.prev);


  onSortOrFilterChanges = (): void => {

    const queryParams = this.queryParamBuilder();

    if (this.isStaticClinicSearch && this.initiallyLoaded
      && (!this.platformWindow.location.href.includes('/search/')
        && this.platformWindow.location.pathname !== '/')) {

      this.processStaticSearch();
      if (queryParams) {
        this.router.navigate(['/clinics', this.params?.treatment ?? ''],
          {queryParams});
      } else {
        this.router.navigate(['/clinics', this.params?.treatment ?? '']);
      }
    } else {

      if (this.initiallyLoaded && this.platformWindow.location.href.includes('/search/')) {
        this.searchService.getSearchResult((this.model?.query || this.query), this.sort, '', 0, this.filter)
          .then((res: ISearchResult) => this.handleSearchResultResponse(res))
          .finally(() => this.isLoading = false);
        if (queryParams) {
          this.router.navigate(['/search', (this.model?.query || this.query) ?? ''],
            {queryParams});
        } else {
          this.router.navigate(['/search', (this.model?.query || this.query) ?? '']);
        }
      }

    }

  }

  queryParamBuilder() {
    let queryParams;

    if (this.sort && this.filter) {

      queryParams = {sort: this.sort, clinics: this.filter};
    } else if (!this.sort && this.filter) {

      queryParams = {clinics: this.filter};
    } else if (this.sort && !this.filter) {

      queryParams = {sort: this.sort};
    }

    return queryParams;
  }

  resetStates() {
    this.searchService.updateSort(null);
    this.searchService.updateFilter(null);
  }

  ngOnDestroy() {
    this.resetStates();
  }

}
