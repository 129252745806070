import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { ApiService } from '../../../../../core/src/lib/data/core-api.service';
import { PageService } from '../../../../../core/src/lib/features/page/page.service';
import { BreadcrumbItem } from '../../../../../core/src/lib/shared/components/breadcrumb/breadcrumb.models';
import { environment } from '../../../environments/environment';
import { HELPFUL_LINKS } from '../../constants/links.constants';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  helpFulLinks = HELPFUL_LINKS;
  
  constructor(
    @Optional() @Inject(REQUEST) protected request: any,
    public location: Location,
    private pageService: PageService,
    private apiService: ApiService) { }

  ngOnInit() {

    let deprecated_path: string = this.request
      ? this.request.originalUrl
      : location.pathname;

    const isAMP = deprecated_path.indexOf('/amp/') === 0;
    deprecated_path = deprecated_path.replace('/amp/', '/');

    if (isAMP && deprecated_path.indexOf('/clinic/') === 0) {
      this.pageService.responseRedirect(deprecated_path, 301);
    } else {
      this.apiService.redirectCheckGET(deprecated_path).subscribe(
        res => {
          if (res) {
            if (res['new_path'].indexOf('/info/') === 0 && isAMP) {
              this.pageService.redirectToExternal(environment.url.amp_domain + res['new_path']);
            } else {
              this.pageService.responseRedirect(res['new_path'], +res['http_status']);
            }
          }
        }, error => {
          if (error instanceof HttpErrorResponse && error.status === 404) {
            this.pageService.response404(error);
          }
        }, () => {
          this.pageService.updateCurrentPage();
          this.pageService.setTitle($localize`:@@pageNotFoundMetaTitle:Not Found`);
          this.pageService.setDescription('');
          this.pageService.setBreadcrumb([new BreadcrumbItem($localize`:@@breadcrumbPageNotFound:Not Found`, '/404')]);
        });
    }

  }
}
