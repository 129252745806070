<section class="partner-signup">

  <div class="">
    <app-signup-form-step1 [hidden]="this.step !== 1"
                           [countriesList]="countriesList"
                           (stepSubmitted)="this.onStepSubmitted($event)">
    </app-signup-form-step1>
    <app-signup-form-step2 [hidden]="this.step !== 2"
                           [signUpForm]="this.signUpForm"
                           [countriesList]="countriesList"
                           [certificatesList]="certificatesList"
                           (stepBack)="this.onStepBack($event)"
                           (stepSubmitted)="this.onStepSubmitted($event)">
    </app-signup-form-step2>
    <app-signup-form-step3 [hidden]="this.step !== 3"
                           (stepBack)="this.onStepBack($event)"
                           (stepSubmitted)="this.onStepSubmitted($event)">

    </app-signup-form-step3>
    <app-signup-form-step4 [hidden]="this.step !== 4"
                           (stepBack)="this.onStepBack($event)"
                           (stepSubmitted)="this.onStepSubmitted($event)"
                           [isLoading]="isLoading"
                           [siteName]="siteName">
    </app-signup-form-step4>
  </div>
</section>
