import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  UserResponse,
  User,
  UserLinkAccount,
  UserLinkAccountResponse, UserPersonalInfo
} from '@lib/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  apiHost: string;

  constructor(
    private http: HttpClient,
    @Inject('env') private env: any
  ) {
    this.apiHost = env.api.medid_v2;
  }

  fetchUserProfile(): Observable<UserResponse> {
    return this.http.get<UserResponse>(this.apiHost + 'me');
  }

  updateUserProfile(user: User) {
    return this.http
      .post<UserResponse>(this.apiHost + 'update-profile', user)
      .pipe();
  }

  fetchUserPersonalInfo(): Observable<UserPersonalInfo> {
    return this.http.get<UserPersonalInfo>(this.apiHost + 'user');
  }

  updateUserPersonalInfo(userPersonalInfo: UserPersonalInfo): Observable<UserPersonalInfo> {
    return this.http.put<UserPersonalInfo>(this.apiHost + 'user', userPersonalInfo);
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    const password = {
      old_password: oldPassword,
      new_password: newPassword
    };
    return this.http.post(this.apiHost + '', password);
  }

  /** Facebook */

  linkFacebook(accountId: string): Observable<UserLinkAccountResponse> {
    return this.linkAccount('FACEBOOK', accountId);
  }

  unlinkFacebook(accountId: string): Observable<UserLinkAccountResponse> {
    return this.unLinkAccount('FACEBOOK', accountId);
  }

  /** Google */

  linkGoogle(accountId: string): Observable<UserLinkAccountResponse> {
    return this.linkAccount('GOOGLE', accountId);
  }

  unlinkGoogle(accountId: string): Observable<UserLinkAccountResponse> {
    return this.unLinkAccount('GOOGLE', accountId);
  }

  /** Generic */
  private linkAccount(provider: string, accountId: string): Observable<UserLinkAccountResponse> {
    const account: UserLinkAccount = {
      provider,
      account_id: accountId
    };
    this.apiHost = this.env.api.url;
    return this.http
      .post<UserLinkAccountResponse>(this.apiHost + 'link-account', account)
      .pipe();
  }

  private unLinkAccount(provider: string, accountId: string): Observable<UserLinkAccountResponse> {
    const account: UserLinkAccount = {
      provider,
      account_id: accountId
    };
    this.apiHost = this.env.api.url;
    return this.http
      .post<UserLinkAccountResponse>(this.apiHost + 'unlink-account', account)
      .pipe();
  }
}
