import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { I18nService } from '../../../../../../core/src/lib/i18n/i18n.service';

@Component({
  selector: 'app-star-review-input-core',
  templateUrl: './star-review-input.component.html',
  styleUrls: ['./star-review-input.component.scss']
})
export class StarReviewInputComponent implements OnInit {


  @Input()
  ratingSelected!: number;
  @Input() editable: boolean = true;

  @Output() ratingSelectionChanges = new EventEmitter<number>();

  starText!: string;
  ratingHovering!: number;

  constructor(private i18n:I18nService) {}

  ngOnInit() {
    this.starText = '';
  }

  onHover(rating: number) {
    this.ratingHovering = rating;
    switch (rating) {
      case 1: {
        this.starText = $localize`:@@reviewVeryBad:`;
        break;
      }
      case 2: {
        this.starText = $localize`:@@reviewBad:`;
        break;
      }
      case 3: {
        this.starText = $localize`:@@reviewOk:`;
        break;
      }
      case 4: {
        this.starText = $localize`:@@reviewGood:`;
        break;
      }
      case 5: {
        this.starText = $localize`:@@reviewVeryGood:`;
        break;
      }
    }
  }

  onLeave() {
    this.ratingHovering = 0;
    this.starText = '';
  }

  onClick(rating: number) {
    this.ratingSelected = this.ratingSelected === rating ? 0 : rating;
    this.ratingSelectionChanges.emit(this.ratingSelected);
  }
}
