<div class="general-margin">
  <div class="row justify-content-center justify-content-md-center justify-content-lg-center justify-content-xl-center my-5">
    <h3 i18n="@@featuredOn" (click)="onClickUrl()">Featured On</h3>
  </div>
  <div class="row">
    <div class="col">
      <div class="press-list">
          <swiper class="swiper-containers" fxFlex="auto" [config]="config" [disabled]="disabled"
          (indexChange)="onIndexChange($event)" (swiperTransitionStart)="onSwiperEvent('transitionStart')"
          (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
          <ng-container *ngFor="let press of pressList">
              <div class="swiper-slide swiper-options-header">
                  <div fxLayout="column">
                    <div class="press-list-card">
                      <a class="press-list__item-link" [attr.aria-label]="'Read more about Dental Departures on ' + press.pressName" href="{{ press.link }}" target="_blank">
                        <img class="press-list__item-image" src="{{ press.photo }}" [alt]="'logo of ' + press.pressName" loading="lazy"/>
                      </a>
                    </div>
                  </div>
              </div>
          </ng-container>
      </swiper>
      </div>
    </div>
  </div>
</div>
