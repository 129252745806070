import { Inject, Injectable } from '@angular/core';
import { ILocationInfo, ILocationInfoItem } from '../../common/common.models';
import { ApiService } from '../../data/core-api.service';
import { I18nService } from '../../i18n/i18n.service';
import {COUNTRY_EXCEPTIONS, COUNTRY_EXCEPTIONS_CASE_2} from '../../../../../dental/src/seo/country-exceptions.constant';
import {TREATMENT_EXCEPTIONS} from '../../../../../dental/src/seo/treatment-exceptions.constant';
import {TitleCasePipe} from '@angular/common';
import {DashToSpacePipe} from '@lib/pipes/dash-to-space.pipe';

@Injectable({
  providedIn: 'root'
})
export class PremiumContentService {
  private isDD: boolean;

  constructor(
    @Inject('env') private env: any, private i18nService: I18nService,
    private apiService: ApiService,
    private titleCasePipe: TitleCasePipe,
    private dashToSpace: DashToSpacePipe) {
    this.isDD = this.env.schema.name.includes('Dental Departures');

  }

  get(treatment: string, country: string, province: string, city: string) {
    if (treatment === 'treatments') {
      treatment = '';
    }

    return this.apiService.premiumContentGET(treatment, country, province, city);
  }

  getOld(slug: string) {
    return this.apiService.premiumContentOldGET(slug);
  }

  getTopClinics(treatmentId?: number, locationId?: number) {
    return this.apiService.clinicsTopGET(treatmentId, locationId, 10);
  }

  getPopularLocation(treatmentId?: number, locationId?: number) {
    return this.apiService.locationPopularGET(treatmentId, locationId);
  }

  getPopularTreatments(locationId?: number) {
    return this.apiService.treatmentPopularGET(locationId);
  }

  generateFullSlug(treatment: { id: number, slug: string, name: string }, location: ILocationInfo): string {

    const slugs: string[] = [];

    if (treatment) {
      slugs.push(treatment.slug);
    }

    if (location) {
      if (location.country) {
        slugs.push(location.country.slug);
      }
      if (location.province) {
        slugs.push(location.province.slug);
      }
      if (location.city) {
        slugs.push(location.city.slug);
      }
    }

    return slugs.filter(Boolean).join('/');
  }

  private capitalize(text: string) {
    if (text !== '') {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }

    return text;
  }

  generateStaticSearchDescription(treatment: string, city: string, country: string, maxLength: number = 60): string {
    if (treatment === '' && city === '' && country === '') {
      return '';
    }

    let conjunction = '';
    let suffix = '';
    switch (this.i18nService.getCurrentLocale()) {
      case 'en':
      case 'en-US':
        conjunction = 'in';
        suffix = 'Browse listings, compare prices, read real patient reviews and book your dental appointment online.';
        break;
      case 'de':
        conjunction = 'in';
        suffix = 'Angebote durchsuchen, Preise vergleichen, echte Patientenbewertungen lesen und Ihren Zahnarzttermin online buchen.';
        break;
      case 'es':
        conjunction = 'en';
        suffix = 'Explora listados, compara precios, lee reseñas de pacientes reales y reserva tu cita dental en línea.';
        break;
      case 'fr':
        conjunction = 'à';
        suffix = 'Parcourez les annonces, comparez les prix, lisez les avis de patients réels et réservez votre rendez-vous dentaire en ligne.';
        break;
      case 'th':
        conjunction = 'ใน';
        suffix = 'ค้นหารายชื่อ, เปรียบเทียบราคา, อ่านความคิดเห็นจากคนไข้ผู้ใช้บริการจริง และนัดหมายทันตกรรมออนไลน์';
        break;
    }

    // case #1 {{treatment}} - {{suffix}}
    // case #2 {{city}}, {{country}} - {{suffix}}
    // case #3 {{treatment}} in {{city}}, {{location}} - {{suffix}}

    const description = (treatment !== '' && (city !== '' || country !== ''))
      ? `${treatment} ${conjunction} ${[city, country].filter(x => x).join(', ')} - ${suffix}`
      : `${treatment}${[city, country].filter(x => x).join(', ')} - ${suffix}`;

    return description;
  }

  get schemaIsMedicalDepartures(): boolean {
    return this.env.schema.name.includes('Medical Departures');
  }

  generateStaticSearchTitle(treatment: string, province: string, city: string, country: string, maxLength: number = 60): string {

    if (this.schemaIsMedicalDepartures) {
      if (treatment === '' && province === '' && city === '' && country === '') {
        return $localize`:@@staticClinicSearchMetaTitle:Abroad | Clinics, Cost, Medics and Reviews`;
      }
    } else {
      if (treatment === '' && city === '' && country === '') {
        return '';
      }
    }

    let prefixes = ['', $localize`:@@staticClinicSearchMetaTitlePrefixes:Dentists`];
    const conjunctions = [$localize`:@@staticClinicSearchMetaTitleConjunctions:in`, $localize`:@@staticClinicSearchMetaTitleConjunctions1:for`];
    let suffixes = [
          $localize`:@@staticClinicSearchMetaTitleSuffixes:Dental Departures`,
          $localize`:@@staticClinicSearchMetaTitleSuffixes1:Get A Quote`,
          $localize`:@@staticClinicSearchMetaTitleSuffixes2:Get Your Quote`,
          $localize`:@@staticClinicSearchMetaTitleSuffixes3:Get A Free Quote`,
          $localize`:@@staticClinicSearchMetaTitleSuffixes4:Book Now`,
          $localize`:@@staticClinicSearchMetaTitleSuffixes5:Save Up To 70%`,
          $localize`:@@staticClinicSearchMetaTitleSuffixes6:Best Price Guaranteed`
        ];
    let extras = [
          $localize`:@@staticClinicSearchMetaTitleExtra:abroad`,
          $localize`:@@staticClinicSearchMetaTitleExtra1:Clinics, Cost, Medics and Reviews`,
          $localize`:@@staticClinicSearchMetaTitleExtra2:Best clinics and price guaranteed`,
          $localize`:@@staticClinicSearchMetaTitleExtra2:Cosmetic & plastic surgery`,
          $localize`:@@staticClinicSearchMetaTitleExtra4:Best price guaranteed`
        ];

    prefixes = prefixes.sort((a, b) => b.length - a.length);
    suffixes = suffixes.sort((a, b) => b.length - a.length);

    // case #1 - {{treatment}} - {{suffix}}
    // case #2 - {{city}}, {{country}} - {{suffix}}
    // case #3 - {{treatment}}, {{city}}, {{country}} - {{suffix}}
    // case #4 - Dentists for {{treatment}} - {{suffix}}
    // case #5 - Dentists in {{city/country}} - {{suffix}}
    // case #6 - Dentists in {{city/country}} for {{treatment}} - {{suffix}}

    let allCases: any[] = [];
    prefixes.forEach(prefix => {
      let hasOnlyTreatment = false;
      let hasOnlyCountry = false;

      let title = '';

      if (this.schemaIsMedicalDepartures) {
        const locationArray = [];
        if (province && !city) {
          locationArray.push(province);
        }
        if (city) {
          locationArray.push(city);
        }
        if (country) {
          locationArray.push(country);
        }
        const location = locationArray.join(', ');

        if (treatment) {
          treatment = this.titleCasePipe.transform(this.dashToSpace.transform(treatment));
          if (location) {
            title = `${treatment} ${conjunctions[0]} ${location}`;
          } else {
            title = `${treatment} ${extras[0]}`;
            hasOnlyTreatment = true;
          }
        } else {
          if (location) {
            title = `${location}`;
            if (!province && !city && country) {
              hasOnlyCountry = true;
            }
          } else {
            title = `${extras[0]} | ${extras[1]}`;
          }
        }


      } else {
        if (prefix === '') {
          title = [treatment, city, country].filter(x => x).join(', ');
        } else {
          if (city !== '' || country !== '') {
            const location = city !== '' ? city : country;
            if (treatment !== '') {
              title = `${prefix} ${conjunctions[0]} ${location} ${conjunctions[1]} ${treatment}`;
            } else {
              title = `${prefix} ${conjunctions[0]} ${location} ${conjunctions[1]} ${treatment}`;
            }
          } else {
            title = `${prefix} ${conjunctions[1]} ${treatment}`;
          }
        }

        suffixes.forEach(suffix => {
          allCases.push(`${title} - ${suffix}`);
        });
      }

      if (this.schemaIsMedicalDepartures) {
        suffixes.forEach(suffix => {
          allCases.push(`${title} | ${suffix}`);
        });
        if (hasOnlyCountry) {
          allCases.push(`${extras[3]} ${conjunctions[0]} ${title} | ${extras[2]}`);
        } else if (hasOnlyTreatment) {
          allCases.push(`${title} | ${extras[1]}`);
        } else if (!treatment && location) {
          allCases.push(`${extras[3]} ${conjunctions[0]} ${title} | ${extras[4]}`);
        } else if (treatment && country && !province && !city) {
          allCases.push(`${title} | ${extras[2]}`);
        }
      }

      if (this.isDD && !province && !city) {
        if (COUNTRY_EXCEPTIONS.includes(('' + country).toLocaleLowerCase()) && TREATMENT_EXCEPTIONS.includes(('' + treatment).toLocaleLowerCase())) {
          allCases = [(this.generateExceptionalTitle(country, treatment, true))];
        }
      }
    });

    // if (!this.env.production) {
    //   allCases.forEach(title => {
    //     console.log(`(${title.length}) ${title}`);
    //   });
    // }

    return allCases
      .sort((a, b) => b.length - a.length)
      .reduce((a, b) => a.length <= maxLength ? a : b);
  }

  generateExceptionalTitle(country: string, treatment: string, isClinicsPage: boolean): string {
    country = country.toLocaleLowerCase();
    treatment = treatment.toLocaleLowerCase();


    const prefixes = [
          $localize`:@@staticClinicSearchExceptionalTitlePrefixes:Book the best dentists`,
          $localize`:@@staticClinicSearchExceptionalTitlePrefixes1:Your guide`
        ];
    const conjunctions = [
          $localize`:@@staticClinicSearchExceptionalTitleConjunctions:in`,
          $localize`:@@staticClinicSearchExceptionalTitleConjunctions1:for`,
          $localize`:@@staticClinicSearchExceptionalTitleConjunctions2:to`
        ];
    const suffixes = [
          $localize`:@@staticClinicSearchExceptionalTitleSuffixes:Best price Guaranteed`,
          $localize`:@@staticClinicSearchExceptionalTitleSuffixes1:Book for free`,
          $localize`:@@staticClinicSearchExceptionalTitleSuffixes2:: procedure, duration, booking`
        ];

    let title = '';

    // Clinics page
    if (isClinicsPage) {
      if (treatment === 'veneers' && (country === 'mexico' || country === 'turkey' || country === 'colombia'
        || country === 'philippines' || country === 'malaysia' || country === 'india')) {
        title = `${this.titleCasePipe.transform(treatment)} - ${prefixes[0]} ${conjunctions[0]} ${this.titleCasePipe.transform(country)}` +
          ` - ${suffixes[0]}`;
      }

      if (treatment === 'dental-implants' && (country === 'philippines' || country === 'turkey'
        || country === 'mexico')) {
        title = `${this.dashToSpace.transform(this.titleCasePipe.transform(treatment))} ${conjunctions[0]} ${this.titleCasePipe.transform(country)}` +
          ` - ${suffixes[0]} - ${suffixes[1]}`;
      }

      if (treatment === 'whitening' && country === 'turkey') {
        title = `Teeth ${this.titleCasePipe.transform(treatment)} ${conjunctions[0]} ${this.titleCasePipe.transform(country)}` +
          ` - ${suffixes[0]} - ${suffixes[1]}`;
      }

      if ((treatment === 'crowns' || treatment === 'dentures') && country === 'united arab emirates') {
        title = `${treatment === 'crowns' ? 'Dental ' + this.titleCasePipe.transform(treatment) : this.titleCasePipe.transform(treatment)} ${conjunctions[0]} UAE` +
          ` - ${suffixes[0]} - ${suffixes[1]}`;
      }

    } else {
      if (treatment === 'veneers' && (country === 'philippines' || country === 'malaysia')) {
        title = `${prefixes[1]} ${conjunctions[2]} ${treatment} ${conjunctions[0]} ${this.titleCasePipe.transform(country)}${suffixes[2]}`;
      }

      if (treatment === 'dental implants' && ( country === 'philippines')) {

        title = `${prefixes[1]} ${conjunctions[2]} ${treatment} ${conjunctions[0]} ${this.titleCasePipe.transform(country)}${suffixes[2]}`;
      }

    }

    return title;
  }

  generateExceptionalTitleSecondCase(country: string, treatment: string): string {
    country = country?.toLocaleLowerCase();
    treatment = treatment?.toLocaleLowerCase();


    const prefixes = [
      $localize`:@@premiumContentExceptionalTitlePrefixes:Top 10+ Clinics`,
      $localize`:@@premiumContentExceptionalTitlePrefixes1:Top 10+ Dentist`
    ];
    const conjunctions = [
      $localize`:@@staticClinicSearchExceptionalTitleConjunctions:in`,
      $localize`:@@staticClinicSearchExceptionalTitleConjunctions1:for`,
      $localize`:@@staticClinicSearchExceptionalTitleConjunctions3:&`
    ];
    const suffixes = [
      $localize`:@@premiumContentExceptionalTitleSuffixes:Get your affordable Hollywood smile!`,
      $localize`:@@premiumContentExceptionalTitleSuffixes1:costs, top clinics, tips for traveling`,
      $localize`:@@premiumContentExceptionalTitleSuffixes2:costs, best clinics, reviews`,
      $localize`:@@premiumContentExceptionalTitleSuffixes3:Clinics`
    ];

    let title = '';

    // Clinics page

    if (!treatment && country === 'turkey') {
      title = $localize`:@@premiumContentExceptionalTitleBookNow:Book Now`;

      if (this.isDD) {
        title = `${prefixes[1]} ${conjunctions[2]} ${suffixes[3]} ${conjunctions[0]} ${this.titleCasePipe.transform(country)} | ${title}`;
      }
    }

    if (treatment === 'veneers' && (country === 'mexico' || country === 'turkey' || country === 'colombia' || country === 'india')) {
      title = `${prefixes[0]} ${conjunctions[1]} ${this.titleCasePipe.transform(treatment)} ${conjunctions[0]} ${this.titleCasePipe.transform(country)}`;
    }

    if (((treatment === 'dental implants') || (treatment === 'dental-implants')) && (country === 'mexico' || country === 'turkey')) {

      title = `${prefixes[0]} ${conjunctions[1]} ${this.titleCasePipe.transform(treatment)} ${conjunctions[0]} ${this.titleCasePipe.transform(country)}`;
    }

    if (treatment === 'whitening' && country === 'turkey') {
      title = `${prefixes[0]} ${conjunctions[1]} ${this.titleCasePipe.transform(treatment)} ${conjunctions[0]} ${this.titleCasePipe.transform(country)}`;
    }

    if ((treatment === 'crowns' || treatment === 'dentures') && (country === 'united arab emirates')) {
      title = `${prefixes[0]} ${conjunctions[1]} ${this.titleCasePipe.transform(treatment)} ${conjunctions[0]} ${this.titleCasePipe.transform(country)}`;
    }

    return title;
  }

  generateTitle( treatment: string, location: string): string {
    // Should sanitized location
    const temp = location.split('-');

    location = '';
    temp.forEach(c => {
      if (location === '') {
        location = this.capitalize(c);
      } else {
        location += ' ' + this.capitalize(c);
      }
      return this.capitalize(c);
    });

    const openers: string[] = [
      '10 Best Clinics',
      'The 10 Best Clinics',
      'Top 10 Clinics',
      'The Top 10 Clinics'
    ].sort((a, b) => b.length - a.length);

    const callToActions: string[] = [
      ' | Book Now',
      ' | Book Now!',
      ' | Save up to 70%',
      ' | Get a Quote',
      ' | Get a Free Quote',
      ' | Get Your Free Quote',
      ' | Get a Free Quote Now',
      ' | Get Your Free Quote Now'
    ].sort((a, b) => b.length - a.length);

    const brand = ` - ${this.env.schema.name}`;

    // Opener + Location  + Treatment + CTA => 60 chars max
    let title = '';
    title += treatment ? ` for ${treatment}` : '';
    title += location ? ` in ${location}` : '';

    const cube: any[] = [];
    openers.forEach(o => {
      cube.push([o]);
      callToActions.forEach(c => {
        cube.push([o, c]);
      });
    });

    const titleAndBrand = +title.length + +brand.length;
    const maxLength = 60;
    const fitting = cube.reduce((prev, curr) => {
      return Math.abs(maxLength - (curr.join().length + titleAndBrand)) < Math.abs(maxLength - (prev.join().length + titleAndBrand))
        ? (curr > maxLength)
          ? prev
          : curr
        : prev;
    });


    title = `${fitting[0]}${title}${fitting[1] ? fitting[1] : ''}`;
    if (this.isDD) {
      const isException = COUNTRY_EXCEPTIONS.includes(location.toLocaleLowerCase())
        && TREATMENT_EXCEPTIONS.includes(treatment.toLocaleLowerCase());

      const isExceptionCase2 = COUNTRY_EXCEPTIONS_CASE_2.includes(location.toLocaleLowerCase())
        && TREATMENT_EXCEPTIONS.includes(treatment.toLocaleLowerCase());

      // Check country exception but ignore philippines
      if (isExceptionCase2) {
        title = this.generateExceptionalTitleSecondCase(location, treatment);
      } else if (isException) {
        title = this.generateExceptionalTitle(location, treatment, false);
      }
    }

    return title;
  }

  checkLocationWithNoTreatment(location: string, treatment: string): boolean {
    // check for if location is Turkey and no treatment
    return (location?.toLowerCase() === 'turkey') && !treatment;
  }

  generateDescription(treatment: string, location: string): string {
    // Old PC pattern

    // Bangkok Plastic Surgery - Browse listings,
    // compare prices, read real patient reviews and book your plastic surgery appointment online.

    // Thailand Plastic Surgery- Browse listings,
    // compare prices, read real patient reviews and book your plastic surgery appointment online.

    // Old static search pattern

    // Compare 290 Breast Lift medical clinics worldwide.
    // View 431 photos, prices and 1228 reviews of all international clinics.
    // Get your free quote and book your appointment now.

    return `${treatment ? treatment : 'Treatments'}${location ? ` in ${location} - ` : ' - '}Browse listings, compare prices, read real patient reviews and book your plastic surgery appointment online.`;
  }

  findSmallestLocation(country: ILocationInfoItem, province: ILocationInfoItem, city: ILocationInfoItem): ILocationInfoItem {
    if (city) {
      return city;
    } else if (province) {
      return province;
    } else {
      return country;
    }
  }
}
