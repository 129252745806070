import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserActivities, ClinicContact } from '@lib/constants/user-activities';
import { IIpInfo } from '@lib/features/geolocation/ip.models';
import { IpService } from '@lib/features/geolocation/ip.service';
import { PageService } from '@lib/features/page/page.service';
import { RecaptchaActions } from '@lib/features/recaptcha/recaptcha.models';
import { RecaptchaService } from '@lib/features/recaptcha/recaptcha.service';
import { UserActivityService } from '@lib/features/user-activity/user-activity.service';
import { IClinic } from '@lib/modules/clinic/clinic.models';
import { ClinicService } from '@lib/modules/clinic/clinic.service';
import { BreadcrumbItem } from '@lib/shared/components/breadcrumb/breadcrumb.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { MessageService } from 'primeng/api';
import { delay } from 'rxjs/operators';


@UntilDestroy()
@Component({
    selector: 'app-contact-core',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
    clinicContactForm!: FormGroup;
    isSubmitted = false;
    isSubmittedSuccessfully = false;

    @Input()
    clinic!: IClinic;

    isLoading: boolean = true;
    clinicIdParam!: string;


    SearchCountryField = SearchCountryField;
    selectedCountryISO: CountryISO = CountryISO.UnitedStates;
    preferredCountries: Array<string> = [
      CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.Canada, CountryISO.Australia, CountryISO.France
    ];
    PhoneNumberFormat = PhoneNumberFormat;
    separateDialCode = false;
    isLoadingSubmit: boolean = false;

    constructor(
      private fb: FormBuilder,
      private clinicService: ClinicService,
      private pageService: PageService,
      private route: ActivatedRoute,
      private recaptchaService: RecaptchaService,
      private userActivityService: UserActivityService,
      private messageService: MessageService,
      private ipService: IpService,

    ) {
      userActivityService.IPInfo.subscribe( res => {
        if (res) {
          this.userActivityService.logUserActivity(UserActivities.ClinicContact.Name, UserActivities.ClinicContact.Landed);
        }
      });

    }

    ngOnInit() {
      this.isLoading = true;
      this.route.params.pipe(untilDestroyed(this)).subscribe(p => {
        this.clinicIdParam = p['id'] ? p['id'] : this.clinic.slug;
        if (this.clinicIdParam) {
          this.clinicService.get(this.clinicIdParam).pipe(untilDestroyed(this)).subscribe(
            (res: IClinic) => {
              this.isLoading = false;
              if (res) {
                this.clinic = res;
                this.clinicContactForm = this.fb.group({
                  clinic_id: [res.id, Validators.required],
                  name: ['', Validators.required],
                  email: ['', [Validators.required, Validators.email]],
                  phone: [''],
                  message: ['', [Validators.required, Validators.minLength(1)]],
                  request_type: ['1', Validators.required],
                  destination: [this.clinic.location.country.name],
                  affiliate_id: [''],
                  gcl_id: [''],
                  'g-recaptcha-response': ['']
                });

                this.pageService.updateCurrentPage();
                this.pageService.setRobotsNoIndex();
                this.pageService.setTitle(`${this.clinic.name}`);
                this.pageService.setDescription('');

                const locationBreadcrumb: BreadcrumbItem[] = [];
                if (this.clinic.location.country) {
                  locationBreadcrumb.push(new BreadcrumbItem(
                    this.clinic.location.country.name,
                    `/dentists/treatments/${this.clinic.location.country.slug}`));
                }

                if (this.clinic.location.province) {
                  locationBreadcrumb.push(new BreadcrumbItem(
                    this.clinic.location.province.name,
                    `/dentists/treatments/${this.clinic.location.country.slug}/${this.clinic.location.province.slug}`));
                }

                if (this.clinic.location.city) {
                  locationBreadcrumb.push(new BreadcrumbItem(
                    this.clinic.location.city.name,
                    `/dentists/treatments/${this.clinic.location.country.slug}/${this.clinic.location.province.slug}/${this.clinic.location.city.slug}`));
                }
                const otherLog =  {
                  action: UserActivities.ClinicTrackAction.LandedOnContact,
                  clinic_id: this.clinic.info && this.clinic.info.new_clinic_id ? this.clinic.info.new_clinic_id : this.clinic.id
                };
                this.userActivityService.
                      logUserActivity(UserActivities.ClinicContact.Name, UserActivities.ClinicContact.Landed, null, otherLog);

                      
                this.pageService.setBreadcrumb([
                  ...locationBreadcrumb,
                  new BreadcrumbItem(this.clinic.name, `/dentist/${this.clinic.slug}`),
                  new BreadcrumbItem($localize`:@@clinicContactBreadcrumbs:`, `/dentist/${this.clinic.slug}/contact`)
                ]);
              }
            },
            (err: any ) => {
             // this.pageService.response404(err);
              this.isLoading = false;

            })
        } else {
         // this.pageService.response404();
        }
      });
      this.ipService.ipGeoInfo$.pipe(untilDestroyed(this)).subscribe((data: IIpInfo | null) => {
        if (data && data !== null) {
          this.selectedCountryISO = data?.countryCode ? data?.countryCode.toLocaleLowerCase() as any : this.selectedCountryISO;
        } else {
          this.selectedCountryISO = CountryISO.UnitedStates;
        }
      });
      const [ chatFloat ] = document.getElementsByClassName('zsiq_floatmain') as any;
      if(chatFloat) {
        chatFloat.classList.add('zsiq_floatmain-hidden');
      }
    }


    onSubmit() {
      this.isLoadingSubmit = true;
      if (this.clinicContactForm.invalid) {
        this.messageService.add({ key: 'bcContact', severity: 'error', summary: 'Error', detail: `Some fields are required.`, closable: false });
        this.isLoadingSubmit = false;
        this.clinicContactForm.markAllAsTouched();
      } else {
      this.isSubmitted = true;
        this.recaptchaService.generateToken(RecaptchaActions.ClinicContact).subscribe((token: any) => {
          this.clinicContactForm.patchValue({ 'g-recaptcha-response': token });
          if (this.clinicContactForm.value.phone) {
            this.clinicContactForm.patchValue({ 'phone': this.clinicContactForm.value.phone.e164Number });
          }
          this.clinicService
            .contactClinic(this.clinicContactForm.value as ClinicContact)
            .subscribe(
              res => {
                this.userActivityService.logUserActivity(UserActivities.ClinicContact.Name, UserActivities.ClinicContact.SubmittedContact);
                // this.isSubmittedSuccessfully = true;
                this.clinicContactForm.reset();
                const detail = $localize`:@@contactAlertSuccess:`;
                this.messageService.add({ key: 'bcContact', severity: 'success', summary: 'Success', detail: detail });
                this.clinicContactForm = this.fb.group({
                  clinic_id: [this.clinic.id, Validators.required],
                  name: ['', Validators.required],
                  email: ['', [Validators.required, Validators.email]],
                  phone: [''],
                  message: ['', [Validators.required, Validators.minLength(1)]],
                  request_type: ['1', Validators.required],
                  destination: [this.clinic.location.country.name],
                  affiliate_id: [''],
                  gcl_id: [''],
                  'g-recaptcha-response': ['']
                });
                const otherLog =  {
                  action: UserActivities.ClinicTrackAction.SubmittedContact,
                  clinic_id:  this.clinic.id
                };
                this.userActivityService.
                      logUserActivity(UserActivities.ClinicContact.Name, UserActivities.ClinicContact.SubmittedContact, null, otherLog);

                this.isLoadingSubmit = false;
              },
              err => {
                this.isLoadingSubmit = false;
                this.userActivityService.logUserActivity(UserActivities.ClinicContact.Name, UserActivities.ClinicContact.ErrorSubmitting);
                alert($localize`:@@clinicContactFormError:`);
              }
            );
        });
      }
    }

    get phone() { return this.clinicContactForm.get('phone') as FormControl; }

    ngOnDestroy() {
      if (!this.isSubmittedSuccessfully) {
        this.userActivityService.logUserActivity(UserActivities.ClinicContact.Name, UserActivities.ClinicContact.NotSubmittedContact);
      }
    }
}
