import {Directive, HostListener, Input, Optional, Self} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[customCoreInput]'
})
export class CustomCoreFormControlDirective {
  @Input() inputElement: HTMLElement | any;

  constructor(@Optional() @Self() public ngControl: NgControl) { }

  @HostListener('keyup')
  keyup() {
    if (Object.entries(this.ngControl.errors || {}).length > 0 && this.ngControl.dirty && this.ngControl.touched) {
      this.inputElement?.classList?.add('border-danger');
    } else {
      this.inputElement?.classList?.remove('border-danger');
    }
  }

}
