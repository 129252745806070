import { Component, OnInit, ViewChild, AfterViewInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { TabMenu } from 'primeng/tabmenu';
import { MenuItem } from 'primeng/api';
import { isPlatformBrowser } from '@angular/common';

interface PopularLocations {
  city: string;
  country: string;
  continent: string;
  url: string;
}

@Component({
  selector: 'app-popular-location',
  templateUrl: './popular-location.component.html',
  styleUrls: ['./popular-location.component.scss']
})
export class PopularLocationComponent implements OnInit, AfterViewInit {

  @Input()
  popularLocations: PopularLocations[] = [];

  @Input()
  items: MenuItem[] = [
    { label: $localize`:@@locationsInLatinAmerica:Locations in Latin America`, id: 'Latin America' },
    { label: $localize`:@@locationsInEurope:Locations in Europe`, id: 'Europe' },
    { label: $localize`:@@locationsInAsia:Locations in Asia`, id: 'Asia' }
  ];

  locations: PopularLocations[] = [];
  activeItem!: MenuItem;
  isBrowser: boolean;


  constructor(
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (this.items && this.items.length > 0) {
      const [ activeItem ] = this.items;
      this.activeItem = activeItem;
      this.locations = this.popularLocations.filter((locations: PopularLocations) => this.activeItem.id === locations.continent);
    }
  }

  ngAfterViewInit(): void {
  }

  onChangeTab(event: any): void {
    const { activeItem } = event;
    this.activeItem = activeItem;
    if (activeItem) {
      this.locations = this.popularLocations.filter((locations: PopularLocations) => activeItem.id === locations.continent);
    }
  }
}
