<form (submit)="this.submit()" [formGroup]="this.form">
  <section class="partner-signup step-partner-wrapper">
    <div class="container-main">
      <div class="row desktop-only title-partner-fixed">
        <div class="col-md-6">
          <h1 class="step-title" i18n="@@partnerSignUpStep3TabName">Legal</h1>
        </div>
        <div class="col-md-6"></div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <h1 class="step-title mobile-only" i18n="@@partnerSignUpStep3TabName">Legal</h1>
        </div>

        <div class="col-md-6">
          <div class="row legal">
            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@legalQuestion1">
                  Has any doctor in your clinic been charged with malpractice?
                </label>
                <section class="form-radio">
                  <p-radioButton formControlName="malpractice" name="malpractice" id="malpractice-1"
                    value="1"></p-radioButton>
                  <label i18n="@@formLabelYes" for="malpractice-1">Yes</label>
                  <p-radioButton formControlName="malpractice" name="malpractice" id="malpractice-0"
                    value="0"></p-radioButton>
                  <label for="malpractice-0" i18n="@@formLabelNo">No</label>
                </section>
              </div>
            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@legalQuestion2">
                  Has anyone in your clinic been convicted of a crime?
                </label>
                <section class="form-radio">
                  <p-radioButton formControlName="convicted" name="convicted" id="convicted-1"
                    value="1"></p-radioButton>
                  <label i18n="@@formLabelYes" for="convicted-1">Yes</label>
                  <p-radioButton formControlName="convicted" name="convicted" id="convicted-0"
                    value="0"></p-radioButton>
                  <label i18n="@@formLabelNo" for="convicted-0">No</label>
                </section>
              </div>
            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@legalQuestion3">
                  Has any doctor on your staff had their license revoked?
                </label>
                <section class="form-radio">
                  <p-radioButton formControlName="revoked" name="revoked" id="revoked-1" value="1"></p-radioButton>
                  <label i18n="@@formLabelYes" for="revoked-1">Yes</label>
                  <p-radioButton formControlName="revoked" name="revoked" id="revoked-0" value="0"></p-radioButton>
                  <label i18n="@@formLabelNo" for="revoked-0">No</label>
                </section>
              </div>
            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@legalQuestion4">
                  Have any complaints been filed against your medical clinic with your regional medical association?
                </label>
                <section class="form-radio">
                  <p-radioButton formControlName="complaints" name="complaints" id="complaints-1"
                    value="1"></p-radioButton>
                  <label i18n="@@formLabelYes" for="complaints-1">No</label>
                  <p-radioButton formControlName="complaints" name="complaints" id="complaints-0"
                    value="0"></p-radioButton>
                  <label i18n="@@formLabelNo" for="complaints-0">Yes</label>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="clinic-partner-footer">
    <div class="container-main">
      <div class="row">
        <div class="col-6">
          <button class="custom-text-btn" (click)="onStepBack()" type="button" i18n="@@formButtonBack">Back</button>
        </div>
        <div class="col-6 text-right">
          <button class="min-151 text-center-button" [disabled]="!this.form.valid" pButton i18n="@@formButtonNext"
            name="submit_step3" type="submit">Next</button>
        </div>
      </div>
    </div>
  </div>
</form>