import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FORM_CONSTANTS } from '@lib/shared/components/form/constants';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordService } from '@lib/features/account/password.service';
import { ApiErrorResponse } from '@lib/models/api-response.model';
import { Subscription } from 'rxjs';
import { ForgotPasswordResponse } from '@lib/models/password.model';
import { AccountService } from '@lib/features/account/account.service';
import { AccountAction } from '@lib/models/account.models';
import { UserActivities, UserActivityArg } from '@lib/constants/user-activities';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  @Input()
  isInModalMode: boolean = false;
  
  @Output() userActivity: EventEmitter<UserActivityArg> = new EventEmitter<UserActivityArg>();


  email!: string;
  isTokenSent = false;
  isEmailExist = false;
  forgotPasswordForm!: FormGroup;
  loginError!: string;

  forgotPasswordSubscription!: Subscription;
  emailPattern = FORM_CONSTANTS.emailPattern;
  processing = false;
  modalAccountAction = AccountAction;
  private hasSubmitted = false;

  constructor(
    private passwordService: PasswordService,
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.emitUserActivity(
      {
        activePage: UserActivities.ForgotPassword.Name,
        activeActivity: UserActivities.ForgotPassword.Landed
      }
    );
    this.email = '';
    this.isTokenSent = false;
    this.isEmailExist = false;

    // Init form
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('',
        [Validators.required, Validators.pattern(this.emailPattern)])
    });
  }

  onSubmit() {
    this.hasSubmitted = true;
    this.email = this.forgotPasswordForm.value.email;
    this.processing = true;
    this.forgotPasswordSubscription = this.passwordService
      .forgotPassword(this.email)
      .subscribe(
        (res: ForgotPasswordResponse) => {
          if (res.success) {
            this.emitUserActivity(
              {
                activePage: UserActivities.ForgotPassword.Name,
                activeActivity: UserActivities.ForgotPassword.SubmittedForgotPassword
              }
            );
            if (res.data.token !== '') {
              this.isTokenSent = true;
            }
          }
        },
        (err: ApiErrorResponse | any) => {
          this.processing = false;
          this.emitUserActivity(
            {
              activePage: UserActivities.ForgotPassword.Name,
              activeActivity: UserActivities.ForgotPassword.NotSubmittedForgotPassword
            }
          );
          this.loginError = err && err.error ? err.error.error : 'Error processing data';
        },
        () => {
          this.processing = false;
        }
      );
  }

  toggleActionState(state: AccountAction) {
    this.accountService.toggleAccountAction(state);
  }

  emitUserActivity(userActivityArg: UserActivityArg) {
    this.userActivity.emit(userActivityArg);
  }

  ngOnDestroy() {
    if (!this.hasSubmitted) {
      this.emitUserActivity(
        {
          activePage: UserActivities.ForgotPassword.Name,
          activeActivity: UserActivities.ForgotPassword.NotSubmittedForgotPassword
        }
      );
    } else {
      this.hasSubmitted = false;
    }

    if (this.forgotPasswordSubscription) {
      this.forgotPasswordSubscription.unsubscribe();
    }
  }

  updateAccountState(type: any) {
    if (this.isInModalMode) {
      this.accountService.updateCurrentAccountStateModal(type);
    } else {
      this.accountService.updateCurrentAccountState(type);
    }
  }

}
