<form (submit)="submit()" [formGroup]="form">
  <section class="partner-signup step-partner-wrapper">
    <div class="container-main">
      <div class="row desktop-only title-partner-fixed">
        <div class="col-md-6">
          <h1 class="step-title" i18n="@@partnerSignUpStep2TabName">Clinic Information</h1>
        </div>
        <div class="col-md-6"></div>
      </div>
      <div class="row ">
        <div class="col-md-6">
          <h1 class="step-title mobile-only" i18n="@@partnerSignUpStep2TabName">Clinic Information</h1>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <div class="checkbox">
                  <input type="checkbox" class="m-0" id="same_as_contact" formControlName="same_as_contact"
                    (change)="fillFromContactToggle()" />
                  <label for="same_as_contact" i18n="@@formLabelUseSameInfoAsContact">Use same information as
                    contact</label>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@formLabelClinicName">Clinic Name</label>
                <input type="text" pInputText formControlName="name" name="name"
                  i18n-placeholder="@@formInputClinicNamePlaceholder" placeholder="Please type your clinic name">
                <div class="control__error" *ngIf="form.controls['name'].errors && form.controls['name'].touched">
                  <span *ngIf="form.controls['name'].errors.required">
                    <ng-container i18n="@@formLabelClinicName">Clinic Name</ng-container>&nbsp;
                    <ng-container i18n="@@formInputClinicNamePlaceholder">is required</ng-container>
                  </span>
                </div>
              </div>

            </div>

            <div class="col-xs-12 col-lg-12">

              <div class="general-form">
                <label class="required" i18n="@@formLabelAddress1">Address 1</label>
                <input type="text" pInputText formControlName="address1" name="address1"
                  i18n-placeholder="@@formInputAddress1ClinicPlaceholder"
                  placeholder="Please type your clinic's primary address">
                <div class="control__error"
                  *ngIf="form.controls['address1'].errors && form.controls['address1'].touched">
                  <span *ngIf="form.controls['address1'].errors.required">
                    <ng-container i18n="@@formLabelAddress1">Address 1</ng-container>&nbsp;
                    <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                  </span>
                  <span *ngIf="form.controls['address1'].errors.minlength" i18n="@@formInputValidationMinLength">
                    This value is too short. It should have {{ form.controls['address1'].errors.minlength.requiredLength
                    }} characters or more.
                  </span>
                </div>
              </div>

            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label i18n="@@formLabelAddress2">Address 2</label>
                <input type="text" pInputText formControlName="address2" name="address2"
                  i18n-placeholder="@@formInputAddress2ClinicPlaceholder"
                  placeholder="Please type your clinic's secondary address">
                <div class="control__error"
                  *ngIf="form.controls['address2'].errors && form.controls['address2'].touched">
                  <span *ngIf="form.controls['address2'].errors.minlength" i18n="@@formInputValidationMinLength">
                    This value is too short. It should have {{ form.controls['address2'].errors.minlength.requiredLength
                    }} characters or more.
                  </span>
                </div>
              </div>

            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@formLabelCity">City</label>
                <input type="text" pInputText formControlName="city" name="city"
                  i18n-placeholder="@@formInputCityPlaceholder" placeholder="Please type your clinic city">
                <div class="control__error" *ngIf="form.controls['city'].errors && form.controls['city'].touched">
                  <span *ngIf="form.controls['city'].errors.required">
                    <ng-container i18n="@@formLabelCity">City</ng-container>&nbsp;
                    <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@formLabelProvince">State / Province</label>
                <input type="text" pInputText formControlName="state_province" name="state_province"
                  i18n-placeholder="@@formLabelProvincePlaceholder"
                  placeholder="Please type your clinic province / state">
                <div class="control__error"
                  *ngIf="form.controls['state_province'].errors && form.controls['state_province'].touched">
                  <span *ngIf="form.controls['state_province'].errors.required">
                    <ng-container i18n="@@formLabelProvince">State / Province</ng-container>&nbsp;
                    <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@formLabelCountry">Country</label>
                <p-dropdown [autoDisplayFirst]="false" id="country_code" formControlName="country_code" class="wb-drop"
                  [options]="countriesList" optionLabel="name" optionValue="id" i18n="@@formInputCountryPlaceholder"
                  placeholder="Please select your country">
                </p-dropdown>
                <div class="control__error" *ngIf="form.controls['country_code'].errors
                && form.controls['country_code'].touched">
                  <span *ngIf="form.controls['country_code'].errors.required">
                    <ng-container i18n="@@formLabelCountry">Country</ng-container>&nbsp;
                    <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@formLabelPostalCode">Postal / Zip Code</label>
                <input type="text" pInputText formControlName="postal_code" name="postal_code"
                  i18n-placeholder="@@formInputPostalCodePlaceholder"
                  placeholder="Please type your clinic Postal / Zip Code">
                <div class="control__error"
                  *ngIf="form.controls['postal_code'].errors && form.controls['postal_code'].touched">
                  <span *ngIf="form.controls['postal_code'].errors.required">
                    <ng-container i18n="@@formLabelPostalCode">Postal Code / Zip Code</ng-container>&nbsp;
                    <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@formLabelWebsite">Website</label>
                <input type="text" pInputText formControlName="web_address" name="web_address"
                  placeholder="Please type your clinic website">
                <div class="control__error"
                  *ngIf="form.controls['web_address'].errors && form.controls['web_address'].touched">
                  <span *ngIf="form.controls['web_address'].errors.required">
                    <ng-container i18n="@@formLabelWebsite">Website</ng-container>&nbsp;
                    <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@formLabelEmail">Email</label>
                <input type="text" pInputText formControlName="email" name="email"
                  i18n-placeholder="@@formInputEmailPlaceholder" placeholder="Please type your clinic name">
                <div class="control__error" *ngIf="form.controls['email'].errors && form.controls['email'].touched">
                  <span *ngIf="form.controls['email'].errors.email" i18n="@@formInputValidationEmailFormat">
                    Please enter a valid email.
                  </span>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@formLabelPhoneNumber">Phone</label>
                <ngx-intl-tel-input [cssClass]="'custom__phone-lib p-inputtext'"
                  [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="false" [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[SearchCountryField.DialCode, SearchCountryField.Name]"
                  [selectFirstCountry]="false" [selectedCountryISO]="selectedCountryISO" [minlength]="9"
                  [maxLength]="19" [numberFormat]="PhoneNumberFormat" [phoneValidation]="true" [inputId]="'phone'"
                  [separateDialCode]="separateDialCode" name="phone" [formControlName]="'phone'">
                </ngx-intl-tel-input>

                <div class="control__error" *ngIf="form.controls['phone'].errors && form.controls['phone'].touched">
                  <span *ngIf="form.controls['phone'].errors.required">
                    <ng-container i18n="@@formLabelPhoneNumber">Phone Number</ng-container>&nbsp;
                    <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                  </span>
                  <span *ngIf="form.controls['phone'].errors?.validatePhoneNumber">
                    <ng-container i18n="@@formInputPhoneNumberFormat">Check Phone Number format</ng-container>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@formLabelYearEstablished">Year established</label>
                <input type="text" pInputText formControlName="year_established" name="year_established"
                  i18n-placeholder="@@formInputYearEstablishedDescription"
                  placeholder="Please type the year of establishment of your clinic">
                <div class="control__error"
                  *ngIf="form.controls['year_established'].errors && form.controls['year_established'].touched">
                  <span *ngIf="form.controls['year_established'].errors.required">
                    <ng-container i18n="@@formLabelYearEstablished">Year established</ng-container>&nbsp;
                    <ng-container i18n="@@formInputValidationRequired"> is required</ng-container>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-lg-12">

              <div class="general-form">
                <label class="required" i18n="@@formLabelAccreditation">What international accreditation does your
                  medical clinic qualify for?</label>

                <ul class="certificates-list"
                  [ngClass]="{'ng-invalid ng-touched': form.controls['certifications'].touched && form.controls['certifications'].errors}">
                  <li *ngFor="let item of certificatesList; let i = index">
                    <input type="checkbox" id="reasonForVisitItem-{{i}}" title="{{item.text}}"
                      [checked]="form.value.certifications.indexOf(item.id) !== -1"
                      (change)="toggleCertificate(item.id)">
                    <label for="reasonForVisitItem-{{i}}">{{item.name}}</label>
                  </li>
                </ul>
                <div class="control__error"
                  *ngIf="form.controls['certifications'].errors && form.controls['certifications'].touched">
                  <span *ngIf="form.controls['certifications'].errors.required" i18n="@@formInputAccreditationError">
                    One or more accreditations are required.
                  </span>
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>
    </div>
  </section>
  <div class="clinic-partner-footer">
    <div class="container-main">
      <div class="row">
        <div class="col-6">
          <button class="custom-text-btn" (click)="onStepBack()" type="button" i18n="@@formButtonBack">Back</button>
        </div>
        <div class="col-6 text-right">
          <button class="min-151 text-center-button" [disabled]="!this.form.valid" pButton i18n="@@formButtonNext"
            name="submit_step2" type="submit">Next</button>
        </div>
      </div>
    </div>
  </div>

</form>