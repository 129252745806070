import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CurrencyService } from '@lib/features/currency/currency.service';

@Component({
    selector: 'app-dynamic-table',
    templateUrl: './dynamic-table.component.html',
    styleUrls: ['./dynamic-table.component.scss'],
})
export class DynamicTableComponent {

    currentDataTable: any | any[] = [];

    @Input()
    allowSelect: boolean = false;

    @Input()
    allowHeaderSelect: boolean = true;

    @Input()
    currency: any = '';

    @Input()
    showAdditionalInfo: boolean = false;

    private _tableData: TableData | undefined;
    @Input()
    set tableData(tableData: TableData | undefined) {
        if (tableData) {
            this._tableData = tableData;
            const { data } = tableData;
            this.currentDataTable = data;
        }
    }

    get tableData() {
        return this._tableData;
    }

    @Input()
    isLoading: boolean | null | any = false;

    @Input()
    scrollHeight: string = 'flex';

    @Input()
    selectedTableRow: any[] = [];

    @Output()
    onSelectRowOrDataChange: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit() { }

    onRowToggle(rowData: any) {
       this.onSelectRowOrDataChange.emit({rowData, selectedTableRow: this.selectedTableRow });
    }

    onEditComplete(rowData: any) {
      this.onRowToggle(rowData);
      setTimeout(() => {
        this.onRowToggle(rowData);
      }, 100);
    }

    getTooltipData(data: any) {
        return `<div class="tip procedure-note">
            <div class="tip__box">
                <header class="tip__header" i18n="@@additionalNotes">Additional notes</header>
                <div class="tip__body">
                    <span> ${ data.additional_notes }></span>
                </div>
            </div>
        </div>`;
    }

}

export interface TableColumns {
    field: string;
    header: string;
    type?:
    | string
    | 'id'
    | 'text'
    | 'date';
    styleCss?: string;
    innerStyleCss?: string;
}

export interface TableData {
    data: any;
    cols: TableColumns[];
}


