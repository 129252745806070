<!-- <div class="drop-down-wrapper-full no-border" *ngIf="!notSticky">
    <div class="pusher-drop-down"></div>
</div> -->
<div id="dropDownBodyWrapper"></div>
<br/>
<div class="drop-down-wrapper-full" *ngIf="dropDownContent" [class.sticky-wrapper]="!notSticky">
    <div class="drop-down-content">
        <h1 *ngIf="notSticky"><span>.</span>Table of Contents</h1>
        <div class="row" *ngIf="!notSticky">
            <div class="col-10"  (click)="togglePanel()" style="cursor: pointer;">
                <h1><span>.</span>Table of Contents</h1>
            </div>
            <div class="col-2" *ngIf="!notSticky">
                <div class="btn btn-cs-dropdown" (click)="togglePanel()">
                   <i class="fas" [class.fa-angle-down]="!showPanel" [class.fa-angle-up]="showPanel"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="drop-down-panel" [class.notSticky]="notSticky" [class.isHidden]="!notSticky && !showPanel" [class.isShow]="!notSticky && showPanel">
        <div *ngIf="!notSticky" class="scroll-left-wrapper">
            <div class="p-scrollpanel-content">
                <div class="scroll-ltr">
                    <ng-container *ngFor="let d of dropDownContent; let i=index">
                    <div  class="list-item" (click)="onClick(d, i)" [class.active]="d.active">
                        <span [innerHTML]="d.title | safeHtml" >
                        </span>
                    </div>
                    </ng-container>
                </div>
            </div>
        </div>
      <ng-container *ngIf="isBrowser">
        <p-scrollPanel class="p-scroll-panel cms-content scroll-left-wrapper-main" *ngIf="notSticky"
                       [style]="{ width: '100%', 'max-height': '200px', height: 'auto' }">
          <div class="scroll-ltr">
            <ng-container *ngFor="let d of dropDownContent; let i=index">
              <div  class="list-item" (click)="onClick(d, i)" [class.active]="d.active">
                <span [innerHTML]="d.title | safeHtml" >
                </span>
              </div>
            </ng-container>
          </div>
        </p-scrollPanel>

      </ng-container>
    </div>
</div>
