<div class="favorite-wrapper" *ngIf="!iconOnly" (click)="$event.stopPropagation(); toggleFavorite()">
  <i class="far fa-heart">
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false"
      class="fill-color  " [class.fill-has-heart]="isFavorite && currentUser"
      style="display: block;  height: 19px; width: 20px; stroke: var(--f-mkcy-f); stroke-width: 2; overflow: visible;">
      <path
        d="m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z">
      </path>
    </svg>
  </i>
  <span class="label" *ngIf="hasLabel">
    {{ isFavorite && currentUser ? saved : save}}
  </span>
</div>

<div class="favorite-wrapper" *ngIf="iconOnly">
  <i class="far fa-heart">
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false"
      class="fill-color "  [class.fill-has-heart]="isFavorite && currentUser"
      style="display: block;  height: 19px; width: 20px; stroke: var(--f-mkcy-f); stroke-width: 2; overflow: visible;">
      <path
        d="m16 28c7-4.733 14-10 14-17 0-1.792-.683-3.583-2.05-4.95-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05l-2.051 2.051-2.05-2.051c-1.367-1.366-3.158-2.05-4.95-2.05-1.791 0-3.583.684-4.949 2.05-1.367 1.367-2.051 3.158-2.051 4.95 0 7 7 12.267 14 17z">
      </path>
    </svg>
  </i>
</div>

<ng-container *ngIf="!iconOnly">
<p-dialog [(visible)]="isModalVisible" [modal]="true" [showHeader]="false" class="custom-favorite-dialog"
  [styleClass]="'custom-favorite-dialog'" appendTo="body" [position]="'center'">
  <div class="close-action"><i class="ri-close-fill" (click)="isModalVisible=false; this.newItem = false;"></i></div>
  <div class="custom-favorite-dialog-wrapper">
    <div class="close-action-pusher"></div>
    <ng-container *ngIf="!newItem">
      <br />
      <p-progressBar mode="indeterminate"[style]="{ height: '3px' }" *ngIf="isLoadingUpdateFavorite"></p-progressBar>
      <div pButton class="add-btn"
           (click)="this.newItem = true"
        class="p-element wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary p-component">
        <i class="fa fa-plus pointer"></i> &nbsp; <span i18n="@@addNewFavoriteCategory" >Add new favorite category</span>
      </div>
      <br />
      <div class="wishlist-items container">
        <ng-container *ngIf="favorites">
          <h3 *ngIf="favorites && favorites.favorites  && favorites.favorites.length > 0 "
          i18n="@@chooseFromExistingLists"> Choose from existing lists:
          </h3>
          <ng-container *ngFor="let fav of favorites.favorites | keyvalue ; let i = index">
            <div class="row wishlist-item" (click)="updateItem(fav.value[0], fav.key)">
              <div class="col-4 thumbnail">
                <img [src]="fav.value[0]['clinic_img']" class="pointer" i18n i18n-alt="@@favoriteCategoryThumbnail" alt="Favorite Category Thumbnail">
              </div>
              <div class="col wishlist-name">
                <p>{{fav.key}}</p>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

    </ng-container>
    <div class="wishlist-items container" *ngIf="newItem">
      <form class="add-form" [formGroup]="form" (ngSubmit)="submit()" hiddenInput>
        <div class="general-form">
          <input pInputText
                 formControlName="name"
                 type="text"
                 placeholder="Enter Wishlist Name"
                 i18n i18n-placeholder="@@enterWishlistName">
        </div>
        <p-progressBar mode="indeterminate"[style]="{ height: '3px' }" *ngIf="isLoadingUpdateFavorite"></p-progressBar>
        <button pButton class="wb-100 submit-btn pointer" type="submit" i18n="@@createCategory">Create Category</button>
      </form>
    </div>
  </div>
</p-dialog>
</ng-container>
