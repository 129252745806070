import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { Subject } from 'rxjs';
import { map, delay, distinctUntilChanged } from 'rxjs/operators';
import { CurrencyService } from '@lib/features/currency/currency.service';

@Pipe({
  name: 'priceTag',
  pure: false
})
export class PriceTagPipe implements PipeTransform {
  private asyncPipe: any;

  private sourceCurrency: any;
  private destinationCurrency: any;

  private valueSubject = new Subject();
  private value$ = this.valueSubject
    .asObservable()
    .pipe(distinctUntilChanged())
    .pipe(delay(0))
    .pipe(
      map((data: any) => {


        // set currency
        if (data.params.sourceCurrency) this.sourceCurrency = data.params.sourceCurrency;
        if (data.params.destinationCurrency) this.destinationCurrency = data.params.destinationCurrency;
        if (this.destinationCurrency === this.currencyService.clinicCurrency) this.destinationCurrency = this.sourceCurrency;

        // set price tag
        if (isNaN(Number(data.value.from)) || Number(data.value.from) === 0) {
          return `${this.destinationCurrency} 0`;
        } else {
          const _from = this.exchange(Number(data.value.from));

          let _to = '';
          if (!isNaN(Number(data.value.to)) && Number(data.value.to) > 0) {
            if (data.value.to < data.value.from)
              throw Error('Price range TO cannot be more than FROM');
            _to = this.exchange(Number(data.value.to));
          }

          let priceTagCurrency = data.params.showSymbol ? this.destinationCurrency + ' ' : '';
          let priceTagFrom = _from;
          let priceTagTo = _to && (Number(data.value.from < Number(data.value.to))) ? `-${_to}` : '';
          return priceTagCurrency + priceTagFrom + priceTagTo;
        }
      })
    );

  private exchange = (amount: number): string => {
    return this.currencyService.exchange(amount, this.sourceCurrency, this.destinationCurrency)
      .toFixed(0)
      .replace(/./g, (c, i, a) => (i && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c));
  }

  constructor(private cdRef: ChangeDetectorRef, private currencyService: CurrencyService) {
    this.asyncPipe = new AsyncPipe(cdRef);

    this.sourceCurrency = this.currencyService.clinicCurrency;
    this.destinationCurrency = this.currencyService.clinicCurrency;

    this.currencyService.currentCurrency.subscribe(currency => {
      if (currency === this.currencyService.clinicCurrency) {
        this.destinationCurrency = this.sourceCurrency;
        return;
      }

      if (!this.sourceCurrency) {
        this.sourceCurrency = currency;
        this.destinationCurrency = currency;
      } else {
        this.sourceCurrency = this.destinationCurrency;
        this.destinationCurrency = currency;
      }
    });
  }

  transform(
    value: { from: number, to: number },
    params: {
      showSymbol: boolean;
      sourceCurrency: string;
      destinationCurrency?: string;
    }): any {

    this.valueSubject.next({ value, params });
    return this.asyncPipe.transform(this.value$);
  }
}
