import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from './spinner.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  count = 0;

  constructor(private spinnerService: SpinnerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // show loader on start of request
    this.spinnerService.show();
    this.count++;

    return next.handle(req).pipe(
      finalize(() => {
        this.count--;

        // hide loader upon request completion
        if (this.count === 0) {
          this.spinnerService.hide();
        }
      })
    );
  }
}
