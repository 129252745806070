import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDoctor } from '@lib/modules/search/search.models';

@Component({
  selector: 'app-book-image',
  templateUrl: './book-image.component.html',
  styleUrls: ['./book-image.component.scss']
})
export class BookImageComponent implements OnInit {

  @Input()
  userImage: any = '';

  @Input()
  docInfo: any | IDoctor;

  @Input()
  isBookExpanded: boolean = false;

  @Input()
  hasOtherInfo: boolean = false;

  isBookExpandedClass: any = '';

  currentYear = new Date().getFullYear();

  @Output()
  userInfo: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.isBookExpandedClass = '';
    if (this.isBookExpanded) {
      this.isBookExpandedClass = 'expanded';
    }
  }

  getToolTip() {
    return  `<div class="">
      <header class="tip__header" >${$localize`:@@badgeVerifiedDoctorTitle:Dental Departures Verified Dentist`}</header>
      <div class="tip__body">
        <span>
          <p>${$localize`:@@badgeVerifiedDoctorDescriptionNew:We conduct a 4 point review of our dentists* - site visit, quality survey, online reputation, dental license verification`}</p>
          <p>${$localize`:@@badgeVerifiedDoctorDescriptionNew2:* varies by country`}</p>
        </span>
      </div>
    </div>`;
  }

}
