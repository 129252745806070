<div class="promotions-wrapper" *ngIf="promotions && promotions.length > 0">
    <h3>{{title}}</h3>
    <div class="row mb-5 mt-3">
        <div class="col-md-12">
            <swiper class="swiper-containers" fxFlex="auto" [config]="config" [disabled]="disabled"
                (indexChange)="onIndexChange($event)" (swiperTransitionStart)="onSwiperEvent('transitionStart')"
                (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
                <ng-container *ngFor="let item of promotions">
                    <div class="swiper-slide swiper-options-header">
                        <div fxLayout="column">
                            <div class="card">
                                <a routerLink="/promotions/code/{{ item.code }}" rel="nofollow">
                                    <div class="img-container" style="background-image: url('{{ item.image }}')">
                                    </div>
                                    <div class="card-info">
                                        <h6 class="title"> {{ item.title }} </h6>
                                        <span class="expires"> {{ item.expires }}</span>
                                        <p class="description">
                                            {{ item.short_description }}
                                        </p>
                                        <p class="use-promo" i18n="@@promotionUseCode">
                                            Use code {{ item.code }} when booking your appointment
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </swiper>
            <br/>
            <a [routerLink]="'/promotions'" class="find-promotions-btn" *ngIf="hasPromotionButton" >
              <span  i18n="@@findMorePromotions"> Find More Promotions </span> <i class="ri-arrow-right-s-line"></i>
            </a>
        </div>
    </div>
</div>
