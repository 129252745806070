import { Directive, ElementRef, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AttachJsScriptsService } from '@lib/shared-core/helper/attach-js-scripts.service';

@Directive({
  selector: 'form, [hiddenInput]'
})
export class HiddenInputDirective implements OnInit {


  constructor(
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: any,
    private attachJsScriptsService: AttachJsScriptsService
  ) {
    attachJsScriptsService.loadFormScripts();
  }

  ngOnInit() {
    const hiddenInput = this.document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = 'zc_gad';
    hiddenInput.id = 'zc_gad';
    hiddenInput.value = '';

    this.elementRef.nativeElement.prepend(hiddenInput);
  }

}
