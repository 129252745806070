import { Injectable, Inject, isDevMode } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LogService } from '../features/log/log.service';
import { catchError } from 'rxjs/operators';
import {UserActivity} from '@lib/models/user-activity';
import {DeleteUserFavorite, PostUserFavorite, PutUserFavorite, UserFavoritesCategory} from '@lib/models/favorite.model';

@Injectable({
  providedIn: 'root'
})
export class MedidApiService {
  protected medidApiHost: string;
  protected medidApiHostV2: string;

  constructor(
    @Inject('env') private env: any,
    private http: HttpClient,
    private logService: LogService) {
    this.logService.log('Medid API.init()');

    if (!env) {
      this.logService.error('Missing env injecttoken');
    }

    this.medidApiHost = env.api.medid;
    this.medidApiHostV2 = env.api.medid_v2;
  }

  private GET(apiFunction: string): Observable<any> {
    const resource = `${this.medidApiHost}${apiFunction}`;
    this.logService.log(`MEDID GET ${resource}`);
    return this.http.get<any>(resource)
      .pipe(
        // retry(3),
        catchError(this.handleErrorGET)
      );
  }

  private POST(apiFunction: string, model: any): Observable<any> {
    const resource = `${this.medidApiHost}${apiFunction}`;
    this.logService.log(`MEDID POST ${resource} -> ${JSON.stringify(model)}`);
    return this.http.post<any>(`${resource}`, model)
      .pipe(catchError(this.handleErrorPOST));
  }

  private handleErrorGET(error: HttpErrorResponse) {

    // For some reason, this code doesn't work. Need to check it out.
    // this.logService.log(`API Error: ${error.status}`);

    if (isDevMode()) {
      console.warn(`MEDID API GET Error Response: ${error.status}. LogService doesn't work as well.`);
    }
    return throwError(error);
  }

  private handleErrorPOST(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      this.logService.error(`MEDID API POST Error: ${error.message}`);
    } else {
      this.logService.error(`MEDID API POST Error: ${error.status}, BODY: ${error}`);
    }

    return throwError(error);
  }

  public meGET(access_token: string): Observable<any> {
    return this.http.get<any>(this.medidApiHost + 'me', {
      headers: new HttpHeaders().set('Authorization', 'JWT ' + access_token)
    });
  }

  public userActivity(payload: UserActivity): Observable<any> {
    return this.http.post(`${this.medidApiHostV2}user-activity`, payload);
  }

  public GETFavorites(path: string): Observable<UserFavoritesCategory> {
    const resource = `${this.medidApiHostV2}${path}`;
    return this.http.get<any>(resource).pipe(// retry(3),
        catchError(this.handleErrorGET));
  }

  public POSTFavorites(path: string, model: PostUserFavorite): Observable<any> {
    const resource = `${this.medidApiHostV2}${path}`;
    return this.http.post<any>(`${resource}`, model)
      .pipe(catchError(this.handleErrorPOST));
  }

  public PUTFavorite(path: string, model: PutUserFavorite): Observable<any> {
    const resource = `${this.medidApiHostV2}${path}/${model.favorite_id}`;
    return this.http.put<any>(`${resource}`, model)
      .pipe(catchError(this.handleErrorPOST));
  }

  public DELETEFavorite(path: string, model: DeleteUserFavorite): Observable<any> {
    let resource = `${this.medidApiHostV2}${path}/${model.favorite_id}/${model.clinic_id}`;
    if (model.is_legacy_clinic && model.is_legacy_clinic === 1) {
      resource += `?is_legacy_clinic=${model.is_legacy_clinic}`;
    }
    return this.http.delete<any>(`${resource}`)
      .pipe(catchError(this.handleErrorPOST));
  }
}
