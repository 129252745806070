import { Component, Inject, OnInit } from '@angular/core';
import { ApiService } from '@lib/data/core-api.service';
import { I18nService } from '@lib/i18n/i18n.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-cta-button',
  templateUrl: './cta-button.component.html',
  styleUrls: ['./cta-button.component.css']
})
export class CtaButtonComponent implements OnInit {
  
  cta: any;
  constructor( @Inject('env') private env: any, private i18nService: I18nService,
  private apiService: ApiService,) { 

  }

  ngOnInit() {
    this.apiService.ctaGET().pipe(untilDestroyed(this)).subscribe((res)=> {
      if(res) {
       const { data } = res;
       const randomIndex = Math.floor(Math.random() * data.length) + 1;
       this.cta = data[randomIndex - 1];
      } else {
        this.cta = {
          'id': null,
          'cta': 'Save up to 70% on a new smile',
          "landingPage": ''
        };
      }
     
    });
  }


  getCTAList() {
   
  }

}
