import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { CurrencyService } from '@lib/features/currency/currency.service';
import { Hotel } from '@lib/modules/clinic/clinic.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take, takeLast } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';

@UntilDestroy()
@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.scss']
})
export class HotelsComponent implements OnInit{

  @Input()
  title: any = $localize`:@@hotels:Hotels`;

  @Input()
  hotels!: Hotel[];

  @Input()
  clinicCurrency = 'USD';

  config!: SwiperOptions;
  disabled: boolean = false;
  currentCurrency = 'USD';
  constructor(private currencyService: CurrencyService) {}

  ngOnInit(): void {
    this.config = this.swiperConfig();
    this.currencyService.currentCurrency.pipe(untilDestroyed(this)).subscribe((data)=> {
      if(data) {
        this.currentCurrency = data;
      }
    });
  }

  /**
   * 
   * @param amount pass the currency amount for hotel
   * @param sourceCurrency use the currency of hotel or not default clinicCurrency
   * @returns the converted data
   */
  exchange = (amount: number, sourceCurrency: any): string => {
    return this.currencyService.exchange(amount, sourceCurrency, this.currentCurrency)
      .toFixed(0)
      .replace(/./g, (c, i, a) => (i && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c));
  }
  

  swiperConfig(): any {
    return {
      a11y: { enabled: true },
      updateOnWindowResize: true,
      direction: 'horizontal',
      slidesPerView: 4,
      keyboard: false,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: false,
      breakpoints: {
        120: {
          slidesPerView: 1.1,
          spaceBetween: 20
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 40
        }
      }
    };
  }

  navigateUrl(link: any) {
    if (link) {
      window. open(link, '_blank');
    }
  }

}
