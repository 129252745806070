import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionDisplayComponent } from './accordion-display/accordion-display.component';
import { BookImageComponent } from './book-image/book-image.component';
import { CardDeparturesComponent } from './card-departures/card-departures.component';
import { CardPromptComponent } from './card-prompt/card-prompt.component';
import { CarouselComponent } from './carousel/carousel.component';
import { ContentQuoteComponent } from './content-quote/content-quote.component';
import { DropDownContentComponent } from './drop-down-content/drop-down-content.component';
import { DynamicTableComponent } from './dynamic-table/dynamic-table.component';
import { FeaturedOnComponent } from './featured-on/featured-on.component';
import { FooterV2Component } from './footer-v2/footer-v2.component';
import { HeaderV2Component } from './header-v2/header-v2.component';
import { HowWeRateComponent } from './how-we-rate/how-we-rate.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { OptionsHeaderComponent } from './options-header/options-header.component';
import { PopularLocationComponent } from './popular-location/popular-location.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { ShareProfileComponent } from './share-profile/share-profile.component';
import { WhyDentalComponent } from './why-dental/why-dental.component';
import { WhyDeparturesComponent } from './why-departures/why-departures.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedPrimeNgModule } from '../shared-prime-ng.module';
import { AutoCompleteSearchComponent } from './auto-complete-search/auto-complete-search.component';
import { CorePipesModule } from '../pipes/core-pipes.module';
import { PriceDiscounterComponent } from './price-discounter/price-discounter.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { ReviewsPopupComponent } from './reviews-popup/reviews-popup.component';
import { DoctorsComponent } from './doctors/doctors.component';
import { MapIframeComponent } from './map-iframe/map-iframe.component';
import { ContactComponent } from './contact/contact.component';
import { PricesComponent } from './prices/prices.component';
import { AboutClinicComponent } from './about-clinic/about-clinic.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FavoriteCoreComponent } from './favorite-core/favorite-core.component';
import { ClinicPreviewCardComponent } from './clinic-preview-card/clinic-preview-card.component';
import { TopViewClinicsComponent } from './top-view-clinics/top-view-clinics.component';
import { LatestReviewsCardComponent } from './latest-reviews-card/latest-reviews-card.component';
import { HowToBookComponent } from './how-to-book/how-to-book.component';
import { CardListWrapperComponent } from './card-list-wrapper/card-list-wrapper.component';
import { CardListItemComponent } from './card-list-wrapper/card-list-item/card-list-item.component';
import { HotelsComponent } from './hotels/hotels.component';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { CommentReviewComponent } from './comment-review/comment-review.component';
import { StarReviewInputComponent } from './star-review-input/star-review-input.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { CoreComponentsModule } from '@lib/shared-core/components/core-components.module';
import { SharedDirectivesModule } from '@lib/shared-directives/shared-directives.module';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

export const LayoutsComponent = [
  AutoCompleteSearchComponent,
  AccordionDisplayComponent,
  BookImageComponent,
  CardPromptComponent,
  CardDeparturesComponent,
  CarouselComponent,
  ContentQuoteComponent,
  DropDownContentComponent,
  DynamicTableComponent,
  FeaturedOnComponent,
  FooterV2Component,
  HeaderV2Component,
  HowWeRateComponent,
  ImageGalleryComponent,
  OptionsHeaderComponent,
  PopularLocationComponent,
  PreloaderComponent,
  ShareProfileComponent,
  WhyDentalComponent,
  WhyDeparturesComponent,
  PriceDiscounterComponent,
  PromotionsComponent,
  ReviewsPopupComponent,
  DoctorsComponent,
  MapIframeComponent,
  PricesComponent,
  ContactComponent,
  AboutClinicComponent,
  FavoriteCoreComponent,
  ClinicPreviewCardComponent,
  TopViewClinicsComponent,
  LatestReviewsCardComponent,
  HowToBookComponent,
  CardListWrapperComponent,
  CardListItemComponent,
  HotelsComponent,
  CommentReviewComponent,
  StarReviewInputComponent,
  FileUploaderComponent
];

/**
 * CoreLayoutsModule is a module that contains layouts/components that can be use
 * from it's other layout components or in core components. Some of the components
 * here are basically just a plain layout without the involvement of business logic.
 */
@NgModule({
  declarations: [
    ...LayoutsComponent,
  ],
  imports: [
    SharedPrimeNgModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CorePipesModule,
    NgxIntlTelInputModule,
    SwiperModule,
    SharedDirectivesModule
  ],
  exports: [
    ...LayoutsComponent,
  ]
})
export class CoreLayoutsModule { }
