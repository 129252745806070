import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageService } from '../features/language/language.service';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  constructor(@Inject('env') private env: any, private languageService: LanguageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req && req.url ? req.url : '';
    if (url.includes(this.env.api.core)
      || url.includes(this.env.api.keendex)
      || url.includes(this.env.api.staglCore)
      || url.includes(this.env.api.staglKeendex)
      || url.includes(this.env.api.medid)
      || req.headers.get('Html-Sitemap') === 'True'
    ) {
      const reqWithLocale = req.clone({
        headers: req.headers
          .set('X-Locale', this.languageService.getCurrentLocale().locale)
      });
      return next.handle(reqWithLocale);
    } else {
      return next.handle(req);
    }
  }
}
