import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HiddenInputDirective } from '@lib/shared-directives/hidden-input.directive';
import { CustomSeoMenubarPatchDirective } from '@lib/shared-core/directives/custom-menubar.directive';



@NgModule({
  declarations: [
    HiddenInputDirective,
    CustomSeoMenubarPatchDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HiddenInputDirective,
    CustomSeoMenubarPatchDirective
  ]
})
export class SharedDirectivesModule { }
