<ng-container *ngIf="tableData">
    <p-table [columns]="tableData.cols" [value]="currentDataTable" [loading]="isLoading" [responsiveLayout]="'scroll'"
        [loadingIcon]="'pi pi-spin pi-spinner'" scrollHeight="{{ scrollHeight }}" (onRowSelect)="onRowToggle($event)"
        (onRowUnselect)="onRowToggle($event)" [(selection)]="selectedTableRow">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width: 4rem" *ngIf="allowSelect">
                    <p-tableHeaderCheckbox *ngIf="allowHeaderSelect"></p-tableHeaderCheckbox>
                </th>
                <ng-container *ngFor="let col of tableData.cols">
                    <th [style]="col.styleCss">{{ col.header }}</th>
                </ng-container>
            </tr>
        </ng-template>

        <ng-template pTemplate="loadingBody" let-rowData let-columns="columns">
            <tr style="height: 46px">
                <td *ngFor="let col of columns; let even = even">
                    <p-skeleton></p-skeleton>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngIf="allowSelect">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
                <ng-container *ngFor="let col of columns;  let i = index">
                    <ng-container [ngSwitch]="col.type">
                        <td [style]="col.innerStyleCss" *ngSwitchCase="'date'">
                            {{ (rowData[col.field] | date : 'dd/MM/yyyy') }}
                        </td>
                        <td [style]="col.innerStyleCss" *ngSwitchCase="'priceName'">
                            {{ rowData.name }}{{ rowData.brand ? ' - ' + rowData.brand : '' }}

                            <span class="padding-left-0-5" *ngIf="rowData.notes.additional_notes && rowData.notes.additional_notes !== 'None'">
                                <i class="ri-question-fill" [pTooltip]='getTooltipData(rowData.notes)' [escape]="false"></i>
                            </span>
                        </td>
                        <td [style]="col.innerStyleCss" *ngSwitchCase="'duration'">
                            {{ rowData.duration }}
                        </td>
                        <td [style]="col.innerStyleCss" *ngSwitchCase="'price'">
                            <app-price-discounter [item]="rowData" [currency]="currency"></app-price-discounter>
                        </td>
                        <ng-container *ngSwitchCase="'number-input'">
                            <td [style]="col.innerStyleCss" [pEditableColumn]="rowData[col.field]"
                                pEditableColumnField="{{col.field}}">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-inputNumber
                                          type="number" class="wb-100" [min]="0"
                                          (onInput)="onEditComplete(rowData)"
                                          [(ngModel)]="rowData[col.field]"
                                          [id]="col.field+i"
                                          [showButtons]="true">
                                        </p-inputNumber>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <p-inputNumber
                                          type="number" class="wb-100" [min]="0" [showButtons]="true"
                                          (onInput)="onEditComplete(rowData)"
                                          [id]="col.field+i+1"
                                          [(ngModel)]="rowData[col.field]"></p-inputNumber>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                        </ng-container>
                        <td [style]="col.innerStyleCss" *ngSwitchDefault>{{ rowData[col.field] }}</td>
                    </ng-container>
                </ng-container>
            </tr>
        </ng-template>
    </p-table>
</ng-container>
