
<section class="general-partner-clinic">
  <ng-container [ngSwitch]="partnershipFormState">
    <ng-container *ngSwitchCase="partnershipFormEnum.WHY_PARTNER">
      <app-why-partner
        [countriesCount]="countriesCount"
        [clinicsCount]="clinicsCount"
        (partnerFormState)="changeFormState(partnershipFormEnum.SIGNUP_FORM)"
        [siteName]="siteName">
      </app-why-partner>
    </ng-container>
    <ng-container *ngSwitchCase="partnershipFormEnum.SIGNUP_FORM">
      <app-signup-form
        [countriesList]="countriesList"
        [certificatesList]="certificatesList"
        (partnerFormState)="changeFormState($event)"
        [siteName]="siteName">
      </app-signup-form>
    </ng-container>

    <ng-container *ngSwitchCase="partnershipFormEnum.PARTNER_THANK_YOU">
      <app-partner-thank-you [siteName]="siteName"></app-partner-thank-you>
    </ng-container>
  </ng-container>
</section>

