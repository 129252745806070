import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-iframe',
  templateUrl: './map-iframe.component.html',
  styleUrls: ['./map-iframe.component.scss']
})
export class MapIframeComponent implements OnInit {

  @Input() latlong!: string;
  @Input() address!: string;
  @Input() name!: string;
  @Input() title: any = $localize`:@@map:Map`;

  mapSrc = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyCXCy5s3zJpMIO6BqgaE62iTxLxK420taM&q=';

  constructor() { }

  ngOnInit() {
    this.mapSrc += (this.latlong)
      ? this.latlong.replace(' ', '')
      : encodeURIComponent(this.name + '+' + this.address);
  }

}
