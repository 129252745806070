<form (submit)="submit()" [formGroup]="form">
  <section class="partner-signup step-partner-wrapper">
    <div class="container-main">
      <div class="row desktop-only title-partner-fixed">
        <div class="col-md-6">
          <h1 class="step-title" i18n="@@partnerSignUpStep1TabName">Contact Information</h1>
        </div>
        <div class="col-md-6"></div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h1 class="step-title  mobile-only" i18n="@@partnerSignUpStep1TabName">Contact Information</h1>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@formLabelFirstName">First Name</label>
                <input type="text" pInputText class="" name="contact_first_name"
                  placeholder="Please type your first name" formControlName="contact_first_name"
                  i18n-placeholder="@@formInputFirstNamePlaceholder">
                <div class="control__error"
                  *ngIf="form.controls['contact_first_name'].touched && form.controls['contact_first_name'].errors">
                  <span *ngIf="form.controls['contact_first_name'].errors.required">
                    <ng-container i18n="@@formLabelFirstName">First Name</ng-container>&nbsp;
                    <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                  </span>
                </div>
              </div>

            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@formLabelLastName">Last Name</label>
                <input type="text" pInputText placeholder="Please type your last name" name="contact_last_name"
                  formControlName="contact_last_name" i18n-placeholder="@@formInputLastNamePlaceholder">
                <div class="control__error"
                  *ngIf="form.controls['contact_last_name'].touched && form.controls['contact_last_name'].errors">
                  <span *ngIf="form.controls['contact_last_name'].errors.required">
                    <ng-container i18n="@@formLabelLastName">Last Name</ng-container>&nbsp;
                    <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@formLabelTitle">Title</label>
                <input type="text" pInputText class="" name="contact_title" placeholder="Please type your title"
                  formControlName="contact_title" i18n-placeholder="@@formInputTitlePlaceholder">
                <div class="control__error"
                  *ngIf="form.controls['contact_title'].touched && form.controls['contact_title'].errors">
                  <span *ngIf="form.controls['contact_title'].errors.required">
                    <ng-container i18n="@@formLabelTitle">Title</ng-container>&nbsp;
                    <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                  </span>
                </div>
              </div>

            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@formLabelAddress1">Address 1</label>
                <input type="text" pInputText formControlName="contact_address1" name="contact_address1"
                  i18n-placeholder="@@formInputAddress1Placeholder" placeholder="Please type your primary address">
                <div class="control__error"
                  *ngIf="form.controls['contact_address1'].touched && form.controls['contact_address1'].errors">
                  <span *ngIf="form.controls['contact_address1'].errors.required">
                    <ng-container i18n="@@formLabelAddress1">Address 1</ng-container>&nbsp;
                    <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                  </span>
                  <span *ngIf="form.controls['contact_address1'].errors.minlength"
                    i18n="@@formInputValidationMinLength">
                    This value is too short. It should have {{
                    form.controls['contact_address1'].errors.minlength.requiredLength }} characters or more.
                  </span>
                </div>
              </div>

            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="" i18n="@@formLabelAddress2">Address 2</label>
                <input type="text" pInputText formControlName="contact_address2" name="contact_address2"
                  i18n-placeholder="@@formInputAddress2Placeholder" placeholder="Please type your secondary address">
                <div class="control__error"
                  *ngIf="form.controls['contact_address2'].touched && form.controls['contact_address2'].errors">
                  <span *ngIf="form.controls['contact_address2'].errors.minlength"
                    i18n="@@formInputValidationMinLength">
                    This value is too short. It should have {{
                    form.controls['contact_address2'].errors.minlength.requiredLength }} characters or more.
                  </span>
                </div>
              </div>

            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@formLabelCity">City</label>
                <input type="text" pInputText formControlName="contact_city" name="contact_city"
                  i18n-placeholder="@@formInputCityPlaceholder" placeholder="Please type your city">
                <div class="control__error"
                  *ngIf="form.controls['contact_city'].touched && form.controls['contact_city'].errors">
                  <span *ngIf="form.controls['contact_city'].errors.required">
                    <ng-container i18n="@@formLabelCity">City</ng-container>&nbsp;
                    <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                  </span>
                </div>
              </div>

            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@formLabelProvince">State / Province</label>
                <input type="text" pInputText formControlName="contact_state" name="contact_state"
                  i18n-placeholder="@@formLabelProvincePlaceholder" placeholder="Please type your State / Province">
                <div class="control__error"
                  *ngIf="form.controls['contact_state'].touched && form.controls['contact_state'].errors">
                  <span *ngIf="form.controls['contact_state'].errors.required">
                    <ng-container i18n="@@formLabelProvince">State / Province</ng-container>&nbsp;
                    <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                  </span>
                </div>
              </div>

            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" for="contact-country" i18n="@@formLabelCountry">Country</label>

                <p-dropdown [autoDisplayFirst]="false" id="contact-country" formControlName="contact_country"
                  class="wb-drop" [options]="countriesList" optionLabel="name" optionValue="id"
                  i18n="@@formInputCountryPlaceholder" placeholder="Please select your country">
                </p-dropdown>
                <div class="control__error"
                  *ngIf="form.controls['contact_country'].touched && form.controls['contact_country'].errors">
                  <span *ngIf="form.controls['contact_country'].errors.required">
                    <ng-container i18n="@@formLabelCountry">Country</ng-container>&nbsp;
                    <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                  </span>
                </div>
              </div>

            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@formLabelPostalCode">Postal Code / Zip Code</label>
                <input type="text" pInputText formControlName="contact_zip" name="contact_zip"
                  i18n-placeholder="@@formInputPostalCodePlaceholder"
                  placeholder="Please type your Postal Code / Zip Code">
                <div class="control__error"
                  *ngIf="form.controls['contact_zip'].touched && form.controls['contact_zip'].errors">
                  <span *ngIf="form.controls['contact_zip'].errors.required">
                    <ng-container i18n="@@formLabelPostalCode">Postal Code / Zip Code</ng-container>&nbsp;
                    <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                  </span>
                </div>
              </div>

            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@formLabelEmail">Email</label>
                <input type="text" pInputText formControlName="contact_email" name="contact_email"
                  i18n-placeholder="@@formInputEmailPlaceholder" placeholder="Please type your email">
                <div class="control__error"
                  *ngIf="form.controls['contact_email'].touched && form.controls['contact_email'].errors">
                  <span *ngIf="form.controls['contact_email'].errors.required">
                    <ng-container i18n="@@formLabelEmail">Email</ng-container>&nbsp;
                    <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                  </span>
                  <span *ngIf="form.controls['contact_email'].errors.email" i18n="@@formInputValidationEmailFormat">
                    The value should be a valid email.
                  </span>
                </div>
              </div>

            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required" i18n="@@formLabelPhoneNumber">Phone Number</label>

                <ngx-intl-tel-input [cssClass]="'custom__phone-lib p-inputtext'"
                  [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="false" [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[SearchCountryField.DialCode, SearchCountryField.Name]"
                  [selectFirstCountry]="false" [selectedCountryISO]="selectedCountryISO" [minlength]="9"
                  [maxLength]="19" [numberFormat]="PhoneNumberFormat" [phoneValidation]="true" [inputId]="'phone'"
                  [separateDialCode]="separateDialCode" name="contact_phone" [formControlName]="'contact_phone'">
                </ngx-intl-tel-input>

                <div class="control__error"
                  *ngIf="form.controls['contact_phone'].touched && form.controls['contact_phone'].errors">
                  <span *ngIf="form.controls['contact_phone'].errors.required">
                    <ng-container i18n="@@formLabelPhoneNumber">Phone Number</ng-container>&nbsp;
                    <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                  </span>
                  <span *ngIf="form.controls['contact_phone'].errors?.validatePhoneNumber">
                    <ng-container i18n="@@formInputPhoneNumberFormat">Check Phone Number format</ng-container>
                  </span>
                  <span *ngIf="form.controls['contact_phone'].errors.minlength" i18n="@@formInputValidationMinLength">
                    This value is too short. It should have {{
                    form.controls['contact_phone'].errors.minlength.requiredLength }} characters or more.
                  </span>
                  <span *ngIf="form.controls['contact_phone'].errors.maxlength" i18n="@@formInputValidationMaxLength">
                    This value is too long. It should be less than {{
                    form.controls['contact_phone'].errors.maxlength.requiredLength }} characters.
                  </span>
                </div>
              </div>

            </div>

          </div>
        </div>


      </div>
    </div>
  </section>
  <div class="clinic-partner-footer">
    <div class="container-main">
      <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6 text-right">
          <button class="min-151 text-center-button wb-100-m" pButton i18n="@@formButtonNext" name="submit_step1"
            type="submit">Next</button>
        </div>
      </div>
    </div>
  </div>
</form>