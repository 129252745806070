import { DecimalPipe, isPlatformBrowser } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, Inject, NgModule, PLATFORM_ID, ErrorHandler } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RecaptchaService } from '../../../core/src/lib/features/recaptcha/recaptcha.service';
import { httpInterceptorProviders } from '../../../core/src/lib/interceptors';
import { CurrencySwitcherComponent } from '../../../core/src/lib/shared/components/currency-switcher/currency-switcher.component';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { NewsletterSignupComponent } from './layouts/footer/newsletter-signup/newsletter-signup.component';
import { PromotionItemComponent } from './layouts/footer/promotion-item/promotion-item.component';
import { HeaderComponent } from './layouts/header/header.component';
import { InternalErrorComponent } from './layouts/internal-error/internal-error.component';
import { PageNotFoundComponent } from './layouts/page-not-found/page-not-found.component';
import { HomeModule } from './modules/home/home.module';
import { SharedModule } from './shared/shared.module';

declare global {
  interface Window { dataLayer: any[]; }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    CurrencySwitcherComponent,
    PromotionItemComponent,
    NewsletterSignupComponent,
    InternalErrorComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'medical' }),
    BrowserTransferStateModule,
    environment.production ? BrowserAnimationsModule : NoopAnimationsModule,
    HttpClientModule,
    SharedModule,
    HomeModule,
    AppRoutingModule
  ],
  exports: [],
  providers: [
    httpInterceptorProviders,
    DecimalPipe, {
      provide: APP_INITIALIZER,
      useFactory: (recaptcha: RecaptchaService) => {
        return (): Promise<any> => {
          return recaptcha.init()!;
        };
      },
      multi: true,
      deps: [RecaptchaService]
    },
    { provide: 'env', useValue: environment },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
