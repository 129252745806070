import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';
import {SetNewPasswordResponse} from '@lib/models/password.model';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  private apiHost: string;

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    @Inject('env') private env: any
  ) {
    this.apiHost = env.api.medid_v2;
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post(this.apiHost + 'forgot-password', { email });
  }

  setNewPassword(token: string, newPassword: string): Observable<SetNewPasswordResponse> {
    return this.http.post<SetNewPasswordResponse>(this.apiHost + 'set-new-password', {
      token,
      token_type: this.tokenService.TOKEN_TYPE_SET_PASSWORD,
      password: newPassword
    });
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    return this.http.post(this.apiHost + 'change-password', {
      old_password: oldPassword,
      new_password: newPassword
    });
  }
}
