import { Component, Input, OnInit } from '@angular/core';
import { AuthAppleService } from '@lib/auth/auth.apple.service';
import { AuthService } from '@lib/auth/auth.service';
import { AccountService } from '@lib/features/account/account.service';
import { UserActivityService } from '@lib/features/user-activity/user-activity.service';
import { AccountAction, AccountTypeActions } from '@lib/models/account.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-accounts-core',
  templateUrl: './accounts-core.component.html',
  styleUrls: ['./accounts-core.component.scss']
})
export class AccountsCoreComponent implements OnInit {

  @Input()
  currentAccountState: AccountTypeActions = '';

  @Input()
  isInModalMode: boolean = false;

  accountTypeActions!: AccountTypeActions;
  accountActions = AccountAction;
  isModalVisible: boolean = false;
  canSignInWithApple!: boolean;

  constructor(
    private accountService: AccountService,
    private authAppleService: AuthAppleService,
    private userActivityService: UserActivityService,
  ) { }

  ngOnInit(): void {
    if (this.isInModalMode) {
      this.accountService.currentAccountStateModal$.pipe(untilDestroyed(this)).subscribe((data) => {
        const [ chatFloat ] = document.getElementsByClassName('zsiq_floatmain') as any;
        if (data) {
          this.isModalVisible = true;
          if (data != this.accountActions.ERROR) {
            this.currentAccountState = data;
          }
          if(chatFloat) chatFloat.classList.add('zsiq_floatmain-hidden');
        } else {
          this.isModalVisible = false;
          this.currentAccountState = '';
          if(chatFloat) chatFloat.classList.remove('zsiq_floatmain-hidden');
        }
      });
    } else {
      this.currentAccountState = this.accountActions.LOGIN;
      this.accountService.currentAccountState$.pipe(untilDestroyed(this)).subscribe((data) => {
        if (data) {
          if (data != this.accountActions.ERROR) {
            this.currentAccountState = data;
          }
        } else {
          this.currentAccountState = '';
        }
      });
    }
  }

  close() {
    const [ chatFloat ] = document.getElementsByClassName('zsiq_floatmain') as any;
    if(chatFloat)  chatFloat.classList.remove('zsiq_floatmain-hidden');
    this.isModalVisible = false;
  }


  signInWithApple($event: boolean = true) {
    this.canSignInWithApple = $event;
  }

  onClickAppleSignIn() {
    this.authAppleService.setSignInEvent(this.currentAccountState);
  }

  logUserActivity($event: { activePage: string, activeActivity: string }) {
    this.userActivityService.logUserActivity($event.activePage, $event.activeActivity);
  }

}
