import { Component, Input, OnInit } from '@angular/core';
import { IClinic } from '@lib/modules/clinic/clinic.models';

@Component({
    selector: 'app-clinic-preview-card',
    templateUrl: './clinic-preview-card.component.html',
    styleUrls: ['./clinic-preview-card.component.scss']
})
export class ClinicPreviewCardComponent implements OnInit {

    hasMissingData: boolean = false;

    _clinic!: IClinic | any;
    @Input()
    set clinic(clinic: IClinic | any) {
        this._clinic = clinic;
        if (clinic) {
            const { location } = this.clinic;
            const { photos } = this.clinic;
            const { photo } = this.clinic;
            const { address } = this.clinic;
            if (photos) {
                const { clinic_photos } = photos;
                if (clinic_photos && clinic_photos.length > 0) {
                    this.imageUrl = clinic_photos[0].image;
                }
            }
            if (photo) {
                this.imageUrl = clinic.photo;
            }

            if (location) {
                this.location = location.city.name + ', ' + location.country.name;
            }

            if (!location && address) {
                this.location = address;
            }
        }
    };

    get clinic() {
        return this._clinic;
    }


    imageUrl: any = '';
    location: any = '';

    constructor() { }

    ngOnInit(): void {
    }

}
