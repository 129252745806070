<div class="item" *ngIf="item">
	<div class="item-photo">
		<img
			src="{{
				item.photo
					? item.photo
					: 'https://img.dentaldepartures.com/no_clinic.png'
			}}"
			alt="{{ item.title }}"
			title="{{ item.title }}"
		/>
	</div>
	<div class="item-description">
		<ng-container *ngIf="item.link !== ''; else title">
			<a routerLink="{{item.link}}">
				<h2>{{item.title}}</h2>
			</a>
		</ng-container>
		<ng-template #title>
			<h2>{{item.title}}</h2>
		</ng-template>
		<ul>
			<ng-container  *ngFor="let list of item?.list">
				<li *ngIf="list.label != ''">
					<a routerLink="{{list.link}}">{{list.label}}</a>
				</li>
			</ng-container>
		</ul>
</div>
