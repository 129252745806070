import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ILanguage } from '../../common/common.models';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private preferredLanguageKey = 'md-language';

  private currentLanguageSubject: BehaviorSubject<string>;
  private currentLocaleSubject: BehaviorSubject<ILanguage>;

  currentLanguage: Observable<string>;
  currentLocale: Observable<ILanguage>;

  languages: Array<ILanguage> = [
    {
      code: 'en',
      isoAlpha2Code: 'us',
      locale: 'en-US',
      path: '',
      value: 'English',
      tempCode: 'eng'
    },
    {
      code: 'tu',
      isoAlpha2Code: 'tur',
      locale: 'tu-TU',
      path: '',
      value: 'Turkey',
      tempCode: 'tur'
    },
    {
      code: 'th',
      isoAlpha2Code: 'th',
      locale: 'th-TH',
      path: 'th',
      value: 'ภาษาไทย',
      tempCode: 'tha'
    },
    {
      code: 'de',
      isoAlpha2Code: 'de',
      locale: 'de-DE',
      path: 'de',
      value: 'Deutsch'
    },
    {
      code: 'fr',
      isoAlpha2Code: 'fr',
      locale: 'fr-FR',
      path: 'fr',
      value: 'Français'
    },
    {
      code: 'es',
      isoAlpha2Code: 'es',
      locale: 'es-ES',
      path: 'es',
      value: 'Español',
      tempCode: 'spa'
    }
  ];

  defaultLanguage = this.languages[0];

  constructor(private location: Location, private router: Router) {
    const preferredLanguage = this.getPreferredLanguage();

    this.currentLocaleSubject = preferredLanguage
      ? new BehaviorSubject<ILanguage>(preferredLanguage)
      : new BehaviorSubject<ILanguage>(this.defaultLanguage);

    this.currentLocale = this.currentLocaleSubject.asObservable();
    this.currentLocaleSubject.next(preferredLanguage);

    this.currentLanguageSubject = preferredLanguage.code
      ? new BehaviorSubject<string>(preferredLanguage.code)
      : new BehaviorSubject<string>(this.defaultLanguage.code);

      this.currentLanguage = this.currentLanguageSubject.asObservable();
    this.currentLanguageSubject.next(preferredLanguage.code);
  }

  changeLanguage(newLanguage: string): void {
    const currentLocale = this.getCurrentLocale();

    if (newLanguage === currentLocale.code || newLanguage === currentLocale.locale) return;

    const localePath = newLanguage === 'en' || newLanguage === '' ? '' : '/' + newLanguage;
    const targetUrl = `${window.location.origin}${localePath}${this.router.url}`;

    window.location.href = targetUrl;

    // this.pageService.redirectToExternal(targetUrl);
  }

  private getLanguageFromBaseHref(): ILanguage {
    const origin = this.location.prepareExternalUrl('');
    const baseHref = origin;
    return (baseHref === '/')
      ? this.defaultLanguage!
      : this.languages.find(row => row.path === baseHref.replace(/\//g, ''))!;
  }

  setPreferredLanguage = (code: string) => localStorage.setItem(this.preferredLanguageKey, code);

  getAvailableLanguages = (): Array<ILanguage> => this.languages;

  sortLanguages(languages: Array<ILanguage>, topLanguage?: ILanguage): Array<ILanguage> {
    languages.sort((a, b) => {
      const valueA = a.value.toUpperCase();
      const valueB = b.value.toUpperCase();
      return (valueA < valueB) ? -1 : (valueA > valueB) ? 1 : 0;
    });

    if (topLanguage) {
      const matchedCurrentLanguage = languages.splice(languages.findIndex(lang => lang.code === topLanguage.code), 1);
      languages.unshift(matchedCurrentLanguage[0]);
    }

    return languages;
  }

  getCurrentLocale = (): ILanguage => this.currentLocaleSubject.value;

  private getPreferredLanguage(code?: string): ILanguage {
    if (!code || code === '')
      code = localStorage.getItem(this.preferredLanguageKey)!;

    const preferredLanguage = !code ? this.getLanguageFromBaseHref() : this.languages.find(language => language.code === code);
    return preferredLanguage || this.defaultLanguage;
  }
}
