import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import { ApiService } from '../../data/core-api.service';
import { IIpApiIpInfo, IIpInfo, IIpifyIpInfo } from './ip.models';

@Injectable({
  providedIn: 'root'
})
export class IpService {

  private ipGeoInfoSubject = new BehaviorSubject<IIpInfo | null>(null);
  public ipGeoInfo$ = this.ipGeoInfoSubject.asObservable();

  constructor(private http: HttpClient, jsonp: HttpClient, private apiService: ApiService) {
  }

  getIp = (): Observable<any> => {
    return this.apiService.geolocationGET();
  }

  updateGeoTargetIP(res: IIpInfo | any) {
    this.ipGeoInfoSubject.next(res);
  }

  getFromIpApi = (): Observable<IIpApiIpInfo> => {
    return this.http.get<IIpApiIpInfo>('https://ipapi.co/json/')
      .pipe(
        map(res => {
          return res as IIpApiIpInfo;
        }),
        catchError(error => {
          throw 'error in JSON. Details: ' + JSON.stringify(error);
        })
      );
  }

  getFromIpify = (): Observable<string> => {
    return this.http.get<IIpifyIpInfo>('https://api64.ipify.org/?format=json')
      .pipe(map((ip: IIpifyIpInfo) => {
        console.log('test');
        return ip.ip;
      }));
  }
}
