import {Component, ContentChild, OnInit} from '@angular/core';
import { CustomCoreFormControlDirective } from '@lib/shared-core/directives/custom-core-form-control.directive';

@Component({
  selector: 'app-custom-form-field',
  templateUrl: './custom-form-field.component.html',
  styleUrls: ['./custom-form-field.component.scss']
})
export class CustomFormFieldComponent implements OnInit {
  @ContentChild(CustomCoreFormControlDirective, {static: true})
  customCoreFormControlDirective!: CustomCoreFormControlDirective;

  CUSTOM_ERROR_MESSAGES: {[propName: string]: string} = {
    required: 'This field is required',
    minlength: 'The entered value should be longer than ',
    maxlength: 'The entered value should be shorter than ',
    pattern: 'The entered value is not a valid email pattern',
    whiteSpace: 'The entered value must have a character',
    compare: 'The entered value does not match',
    validatePhoneNumber: 'Check phone format'
  };

  constructor() { }

  ngOnInit(): void {
    if (!this.customCoreFormControlDirective) {
      throw new Error('CustomFormControlDirective is required!');
    }
  }

  get customErrorMessage(): { key: string; option: any }  | null {
    const control = this.customCoreFormControlDirective?.ngControl?.control;
    const errors = Object.entries(
      control?.errors || {}
    );
    if (!errors.length) {
      return null;
    }

    if (errors.length && control?.dirty && control.touched) {
      const [key, value] = errors[0] as any;
      return {
        key: this.CUSTOM_ERROR_MESSAGES[key],
        option: key.includes('length') ? value.requiredLength : ''
      };
    }

    return null;
  }

}
