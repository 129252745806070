import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID, Optional, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { I18nService } from '../../i18n/i18n.service';
import { BreadcrumbItem } from '../../shared/components/breadcrumb/breadcrumb.models';
import { BreadcrumbService } from '../../shared/components/breadcrumb/breadcrumb.service';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  constructor(
    @Inject('env') private env: any,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(DOCUMENT) private dom: any,
    @Optional() @Inject(RESPONSE) protected response: any,
    @Optional() @Inject(REQUEST) protected request: any,
    private router: Router,
    private title: Title,
    private meta: Meta,
    private breadcrumb: BreadcrumbService,
    private i18nService: I18nService) {
    this.updateCurrentPage();
  }

  updateCurrentPage() {

    // Set locale
    this.dom.documentElement.lang = this.locale;
    this.meta.updateTag({ httpEquiv: 'content-language', content: this.locale });

    const noTranslationPages = [
      '/info/',
      '/articles',
      '/article/',
      '/insurance',
      '/insurance/',
      '/about',
      '/about/faqs',
      '/affiliates'
    ];

    let hasNoTranslation = false;
    noTranslationPages.forEach(page => {
      if (this.dom.URL.indexOf(page) > -1) {
        hasNoTranslation = true;
      }
    });

    if (hasNoTranslation) {
      this.removeAlternateLinkForAllLocales(this.env.site_id);
      this.setAlternateLink(this.dom.URL, this.locale);
    } else {
      this.setAlternateLinkForAllLocales(this.dom.URL, this.env.site_id, this.locale);
    }

    // Canonicals and other SEO links
    // Title & description
    this.title.setTitle($localize`:@@homeGenericMetaTitle:Medical Tourism Experts - Best Price Guaranteed`);
    this.meta.updateTag({
      name: 'description',
      content: $localize`:@@homeGenericMetaDescription:Save 70% here on medical tourism. Compare treatment prices with over 1339 global medical clinics with 7791 reviews and 14905 photos. Get your Free Quote and Book Now.`
    });

    this.setCanonicalLink(this.dom.location.origin + this.dom.location.pathname);
    this.setAmpLink('');
    this.setNextLink('');
    this.setPrevLink('');
    this.setBreadcrumb([]);
  }

  getCurrentURL = () => this.dom.URL;

  private sanitizeTitle = (title: string): string => {
    return title === ''
      ? this.env.schema.name
      : ((title.trim().length + (' - ' + this.env.schema.name).length) > 60)
        ? title.trim()
        : `${title.trim()} - ${this.env.schema.name}`;
  }
  private sanitizeDescription = (description: string): string => (
    description === '' ?
    description = this.env.schema.description :
    description.trim().length > 162
    )
    ? `${description.trim().slice(0, 162)}...` : description?.trim()

  setTitle = (title: string): void => this.title.setTitle(this.sanitizeTitle(title));
  setDescription = (description: string): void => { this.meta.updateTag({ name: 'description', content: description ? this.sanitizeDescription(description) : '' }); }

  setOG(title: string, description: string, image: string) {
    this.meta.updateTag({ property: 'og:title', content: this.sanitizeTitle(title) });
    this.meta.updateTag({ property: 'og:description', content: this.sanitizeDescription(description) });
    this.meta.updateTag({ property: 'og:image', content: image ? image : this.env.schema.logo });
    this.meta.updateTag({ name: 'thumbnail', content: image ? image : this.env.schema.logo });
    this.meta.updateTag({ property: 'og:url', content: this.getCurrentURL() });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:site_name', content: this.env.schema.url });
  }

  setRobotsNoIndex = () => { this.meta.updateTag({ name: 'robots', content: 'noindex' }); };
  setRobotsNoIndexAndNoFollow = () => { this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' }); };
  setRobotsNoIndexAndFollow = () => { this.meta.updateTag({ name: 'robots', content: 'noindex, follow' }); };

  removeAlternateLinkForAllLocales(siteId: number) {
    const locales = siteId === 1
      ? ['en', 'th', 'es', 'de', 'fr']
      : siteId === 4
        ? ['en']
        : [];

    locales.forEach(lang => {
      const existingAlternateHreflang = this.dom.head.querySelector('link[rel=alternate][hreflang=' + lang + ']');
      if (existingAlternateHreflang) {
        existingAlternateHreflang.remove();
      }
    });
  }

  setAlternateLinkForAllLocales(href: string, siteId: number, currentLocale: string) {
    const locales = siteId === 1
      ? ['en', 'th', 'es', 'de', 'fr']
      : siteId === 4
        ? ['en']
        : [];

    locales.forEach(lang => {
      if (href !== '') {
        const firstSlash = href.indexOf('/', 10);
        const localHref = (currentLocale === 'en-US' || currentLocale === 'en')
          ? (lang === 'en')
            ? href.toLowerCase()
            : `${href.slice(0, firstSlash)}/${lang}${href.slice(firstSlash)}`.toLowerCase()
          : (lang === 'en')
            ? href.replace(/[\/]{1}[a-z\/]{2}[\/]{1}/gi, '/').toLowerCase()
            : href.replace(/[\/]{1}[a-z\/]{2}[\/]{1}/gi, `/${lang}/`).toLowerCase();

        if (localHref !== '') {
          const existingAlternateHreflang = this.dom.head.querySelector('link[rel=alternate][hreflang=' + lang + ']');
          if (existingAlternateHreflang) {
            existingAlternateHreflang.setAttribute('href', localHref);
            existingAlternateHreflang.setAttribute('hreflang', lang);
          } else {
            const link: HTMLLinkElement = this.dom.createElement('link');
            link.setAttribute('rel', 'alternate');
            link.setAttribute('href', localHref);
            link.setAttribute('hreflang', lang);
            this.dom.head.appendChild(link);
          }
        }
      }
    });
  }

  setAlternateLink(href: string, hreflang?: string) {
    const alternate = this.dom.head.querySelector('link[rel=alternate]');
    if (alternate) {
      alternate.setAttribute('href', href.toLowerCase());
      alternate.setAttribute('hreflang', hreflang!);
    } else {
      if (href !== '') {
        this.setLink('alternate', href.toLowerCase(), hreflang);
      }
    }
  }

  setCanonicalLink(href: string) {
    const canonical = this.dom.head.querySelector('link[rel=canonical]');
    if (canonical) {
      if (href !== '') {
        canonical.setAttribute('href', href.toLowerCase());
      } else {
        canonical.parentNode!.removeChild(canonical);
      }
    } else {
      if (href !== '') {
        this.setLink('canonical', href.toLowerCase());
      }
    }
  }

  setAmpLink(href: string) {
    const canonical = this.dom.head.querySelector('link[rel=amphtml]');
    if (canonical) {
      canonical.setAttribute('href', href.toLowerCase());
      if (href === '') {
        canonical.parentNode!.removeChild(canonical);
      }
    } else {
      if (href !== '') {
        this.setLink('amphtml', href.toLowerCase());
      }
    }
  }

  setNextLink = (href: string) => { this.setNavigationLink('next', href); }
  setPrevLink = (href: string) => { this.setNavigationLink('prev', href); }

  private setNavigationLink(rel: string, href: string) {
    if (rel !== 'prev' && rel !== 'next') {
      throw new Error('Invalid direction for next/prev link.');
    }

    const link = this.dom.head.querySelector(`link[rel=${rel}]`);
    if (link) {
      link.setAttribute('href', href);
      if (href === '') {
        link.parentNode!.removeChild(link);
      }
    } else {
      if (href !== '') {
        this.setLink(rel, href);
      }
    }
  }

  setLink(rel: string, href: string, hreflang?: string) {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', rel);
    link.setAttribute('href', href);
    if (hreflang) {
      link.setAttribute('hreflang', hreflang);
    }
    this.dom.head.appendChild(link);
  }

  setScript(scriptSrc: string, async: boolean = true) {
    const script: HTMLScriptElement = this.dom.createElement('script');
    script.src = scriptSrc;
    script.async = async;
    this.dom.head.appendChild(script);
  }

  setBreadcrumb = (breadcrumb: BreadcrumbItem[]) => this.breadcrumb.setPageBreadcrumb(breadcrumb);

  setSchemaHeader(schema: any, id?: string) {
    const script: HTMLScriptElement = this.dom.createElement('script');
    script.setAttribute('type', 'application/ld+json');
    script.type = 'application/ld+json';
    if (id !== '') {
      script.id = id!;
    }
    script.textContent = `${JSON.stringify(schema)}`;
    this.dom.head.appendChild(script);
  }

  setSchemaBody(renderer: Renderer2, schema: any) {
    const script = renderer.createElement('script');
    script.type = 'application/ld+json';
    script.text = `${JSON.stringify(schema)}`;
    renderer.appendChild(this.dom.body, script);
  }

  appendScriptBlockAtBodyFooter(renderer: Renderer2, scriptContent: string) {
    const script = renderer.createElement('script');
    script.type = 'application/ld+json';
    script.text = scriptContent;
    renderer.appendChild(this.dom.body, script);
  }

  response301 = (url: string): void => this.responseRedirect(url, 301);
  response302 = (url: string): void => this.responseRedirect(url, 302);

  responseRedirect(url: string, responseCode: number = 301): void {
    if (responseCode !== 301 && responseCode !== 302) {
      throw new Error(`Invalid HTTP Status Code: ${responseCode}`);
    } else {

      if (!url || url === '') {
        throw new Error(`Cannot redirect to empty URL`);
      }

      url = url.startsWith('/') ? url : '/' + url;
      if (isPlatformBrowser(this.platformId)) {
        this.router.navigateByUrl(url, { skipLocationChange: false, replaceUrl: true });
      } else {
        this.response.redirect(responseCode, url);
      }
    }
  }

  responseRedirectForLocalSitePremiumContent(urlPaths: string[]): void {
    urlPaths = urlPaths.filter(c => c);
    if (isPlatformBrowser(this.platformId)) {
      this.router.navigate(urlPaths);
    } else {
      let localSite = this.i18nService.getCurrentLocaleButNotGlobal();
      if (localSite !== '') {
        localSite = '/' + localSite;
      }
      this.response.redirect(301, localSite + '/' + urlPaths.join('/'));
    }
  }

  redirectToExternal(url: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.dom.location.href = url;
    }
  }

  response404(httpError?: any): void {
    if (httpError !== null && this.is404HttpErrorResponse(httpError)) {
      if (isPlatformBrowser(this.platformId)) {
        this.router.navigateByUrl('/404', { skipLocationChange: true, replaceUrl: true });
      } else {
        this.response.status(404);
      }
    } else if (httpError === null) {
      if (isPlatformBrowser(this.platformId)) {
        this.router.navigateByUrl('/404', { skipLocationChange: true, replaceUrl: true });
      } else {
        this.response.status(404);
      }
    } else {
      if (isPlatformBrowser(this.platformId)) {
        this.router.navigateByUrl('/500', { skipLocationChange: true, replaceUrl: true });
      } else {
        this.response.status(500);
      }
    }
  }

  is404HttpErrorResponse = (error: any): boolean => (error instanceof HttpErrorResponse && (error.status === 404 || error.status === 0));
}
