<div class="top-view-wrapper">
  <div class="row">
    <div class="col-md-12">
      <h3 class="title-only" *ngIf="title && topClinics && topClinics.length > 0">{{title}} {{ location ? ' in ' +
        location : '' }}</h3>
    </div>
    <div class="desktop-clinics-only">
      <ng-container *ngFor="let clinic of topClinics">
        <div class="col-xs-15 col-sm-15 col-md-15 col-lg-3" *ngIf="clinic">
          <app-card-departures [clinic]="clinic" [staticUrl]="staticUrl" [favorites]="favorites"
            [currentUser]="currentUser"></app-card-departures>
          <br />
        </div>
      </ng-container>
    </div>
    <div class="mobile-clinics-only">
      <swiper class="swiper-containers container" fxFlex="auto" [config]="config" [disabled]="disabled"
        (indexChange)="onIndexChange($event)" (swiperTransitionStart)="onSwiperEvent('transitionStart')"
        (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
        <ng-container *ngFor="let clinic of topClinics">
          <div class="swiper-slide swiper-options-header">
            <div fxLayout="column">
              <app-card-departures [clinic]="clinic" [staticUrl]="staticUrl" [favorites]="favorites"
                [currentUser]="currentUser"></app-card-departures>
              <br />
            </div>
          </div>
        </ng-container>
      </swiper>
    </div>
  </div>
  <div class="row"  *ngIf="title && topClinics && topClinics.length > 0">
    <div class="col-md-4"></div>
    <div class="col-md-4 text-center">
      <a routerLink="{{ this.staticSearch }}">
        <div pButton class="p-button-outlined p-button-label-zero p-button wb-100">
          <ng-container i18n="@@moreClinics">More Clinics</ng-container>
        </div>
      </a>
    </div>
    <div class="col-md-4"></div>
  </div>
</div>