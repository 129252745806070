import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@lib/data/core-api.service';
import { PageService } from '@lib/features/page/page.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
@UntilDestroy()
@Component({
  selector: 'app-rebook-redirect',
  template: ''
})
export class RebookRedirectComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, 
    private pageService: PageService, 
    private apiService: ApiService) {}

  ngOnInit() {
    const queryParams = { ...this.route.snapshot.queryParams }; 
    const redirectUrl = '/book/clinic/';

    const { token , clinic, quote } = queryParams;

    if(token && clinic && quote) {
      const commonVerificationBooking$ = combineLatest([
        this.apiService.tokenDecryptPOST({ data: token }),
        this.apiService.clinicGET(clinic)
      ]);
  
      commonVerificationBooking$.pipe(untilDestroyed(this)).subscribe((data: any | any[]) => {
        const [ tokenInfo, clinic ] = data;
        if(!tokenInfo) {
          this.pageService.response404(); 
        } else {
          queryParams['email'] =  tokenInfo.res;
          this.router.navigate([redirectUrl + clinic.slug], { queryParams });
        }
      });
    } else {
      this.pageService.response404();
    }
  
  }

}
