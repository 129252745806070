import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-signup-form-step3',
  templateUrl: './signup-form-step3.component.html',
  styleUrls: ['../../../components/partner-signup-core/partner-signup-core.component.scss', './signup-form-step3.component.scss']
})
export class SignupFormStep3Component implements OnInit {

  @Output() stepBack: EventEmitter<number> = new EventEmitter();
  @Output() stepSubmitted: EventEmitter<any> = new EventEmitter();

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      malpractice: ['0', Validators.required],
      malpractice_explain: ['malpractice'],
      convicted: ['0', Validators.required],
      convicted_explain: ['crime'],
      revoked: ['0', Validators.required],
      revoked_explain: ['revoked'],
      complaints: ['0', Validators.required],
      complaints_explain: ['revoked']
    });
  }

  ngOnInit() {}

  onStepBack()  {
    this.stepBack.emit(3);
  }

  submit() {
    this.stepSubmitted.emit({ step_id: 3, form: this.form.value });
  }
}
