import { NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-accordion-display',
  templateUrl: './accordion-display.component.html',
  styleUrls: ['./accordion-display.component.scss']
})
export class AccordionDisplayComponent implements OnInit {


  activeIndex: any[] = [];

  @Input()
  title: string = 'Got Some Questions?';

  @Input()
  classWrapper: string = 'd-accordion-wrapper';

  @Input()
  multiple: boolean = false;

  @Input()
  allowContent: boolean = false;

  @Input()
  allowExpandHide: boolean = false;

  @Input()
  accordionDataLists: AccordionData[] | any[] = [
    {
      title: 'How much do dental implants cost in Mexico?',
      value: 'A single titanium starts at approximately $700 in Mexico; prices for a full arch restoration on 4 implants start at around $8,000',
      isExpanded: false
    },
    {
      title: 'Do dentists in Mexico speak English?',
      value: 'Sample Lorem Ipsum',
      isExpanded: false
    },
    {
      title: 'At vero eos et accusamus speak English?',
      value: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium',
      isExpanded: false
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  expandAll() {
    this.accordionDataLists = this.accordionDataLists.map((data) => {
      data.isExpanded = true;
      return data;
    });
  }

  hideAll() {
    this.accordionDataLists = this.accordionDataLists.map((data) => {
      data.isExpanded = false;
      return data;
    });
  }

}


export interface AccordionData {
  title: any;
  value?: any;
  template?: any | TemplateRef<any>;
  isExpanded?: boolean;
}