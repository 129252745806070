import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICurrency } from '../../common/common.models';
import { CommonService } from '../../common/common.service';
import { LogService } from '../log/log.service';
import { CURRENCY_CODES } from '@lib/constants/currency-codes';

export interface IPriceRange {
  listed: {
    from: number;
    to: number;
  };
  discounted: {
    from: number;
    to: number;
  };
  diff?: number;
}

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  /**
    * Added currency codes here to be used
    * on other components
    * */
  public currenciesTh = [
    { code: 'AUD', name: 'ดอลลาร์ออสเตรเลีย' },
    { code: 'AED', name: 'เดอร์แฮมสหรัฐอาหรับเอมิเรตส์' },
    { code: 'CAD', name: 'ดอลลาร์แคนาดา' },
    { code: 'CNY', name: 'หยวน' },
    { code: 'COP', name: 'เปโซโคลอมเบีย' },
    { code: 'CRC', name: 'โกลอนคอสตาริกา' },
    { code: 'DKK', name: 'โครนเดนมาร์ก' },
    { code: 'EUR', name: 'ยูโร' },
    { code: 'HKD', name: 'ดอลลาร์ฮ่องกง' },
    { code: 'HRK', name: 'คูนาโครเอเชีย' },
    { code: 'IDR', name: 'รูเปียห์' },
    { code: 'INR', name: 'รูปีอินเดีย' },
    { code: 'KRW', name: 'วอนเกาหลีใต้' },
    { code: 'MYR', name: 'ริงกิตมาเลเซีย' },
    { code: 'MXN', name: 'เปโซเม็กซิโก' },
    { code: 'NZD', name: 'ดอลลาร์นิวซีแลนด์' },
    { code: 'NOK', name: 'โครนนอร์เวย์' },
    { code: 'PHP', name: 'เปโซฟิลิปปินส์' },
    { code: 'GBP', name: 'ปอนด์สเตอร์ลิง' },
    { code: 'PLN', name: 'ซโวตี' },
    { code: 'SGD', name: 'ดอลลาร์สิงคโปร์' },
    { code: 'THB', name: 'บาท' },
    { code: 'TRY', name: 'ลีราตุรกี' },
    { code: 'USD', name: 'ดอลลาร์สหรัฐ' },
    { code: 'VND', name: 'ดอง เวียดนาม' }
  ];

  public currenciesDe = [
    { code: 'AUD', name: 'AUSTRALISCHE DOLLAR' },
    { code: 'AED', name: 'V.A.E. Dirham' },
    { code: 'CAD', name: 'KANADISCHE DOLLAR' },
    { code: 'CNY', name: 'CHINESISCHER YUAN' },
    { code: 'COP', name: 'KOLUMBISCHER PESO' },
    { code: 'CRC', name: 'COSTA RICA COLON' },
    { code: 'DKK', name: 'DÄNISCHE KRONEN' },
    { code: 'EUR', name: 'EURO' },
    { code: 'HKD', name: 'HONG KONG DOLLAR' },
    { code: 'HRK', name: 'KROATISCHE KUNA' },
    { code: 'IDR', name: 'INDONESISCHER RUPIAH' },
    { code: 'INR', name: 'INDISCHE RUPIE' },
    { code: 'KRW', name: 'SÜDKOREANICHER WON' },
    { code: 'MYR', name: 'MALAYSISCHER RINGIT' },
    { code: 'MXN', name: 'MEXIKANICHE PESO' },
    { code: 'NZD', name: 'NEUSEELAND DOLLAR' },
    { code: 'NOK', name: 'NORWEGISCHE KRONEN' },
    { code: 'PHP', name: 'PHILIPPINISCHER PESO' },
    { code: 'GBP', name: 'PFUND STERLING' },
    { code: 'PLN', name: 'ZLOTY' },
    { code: 'SGD', name: 'SINGAPUR-DOLLAR' },
    { code: 'THB', name: 'BAHT' },
    { code: 'TRY', name: 'TÜRKISCHE LIRA' },
    { code: 'USD', name: 'U.S DOLLAR' },
    { code: 'VND', name: 'VIRTNAMESISCHER GONG' }
  ];

  public currenciesEs = [
    { code: 'AUD', name: 'DÓLAR AUSTRALIANO' },
    { code: 'AED', name: 'Dirham De EAU' },
    { code: 'CAD', name: 'DÓLAR CANADIENSE' },
    { code: 'CNY', name: 'YUAN CHINO' },
    { code: 'COP', name: 'PESO COLOMBIANO' },
    { code: 'CRC', name: 'COLÓN DE COSTA RICA' },
    { code: 'DKK', name: 'CORONAS DANESAS' },
    { code: 'EUR', name: 'EURO' },
    { code: 'HKD', name: 'DÓLAR DE HONG KONG' },
    { code: 'HRK', name: 'KUNA CROATA' },
    { code: 'IDR', name: 'RUPIA INDONESIA' },
    { code: 'INR', name: 'RUPIA INDIA' },
    { code: 'KRW', name: 'WON COREANO' },
    { code: 'MYR', name: 'RINGGIT MALAYO' },
    { code: 'MXN', name: 'PESO MEXICANO' },
    { code: 'NZD', name: 'DÓLAR DE NUEVA ZELANDA' },
    { code: 'NOK', name: 'CORONAS NORUEGAS' },
    { code: 'PHP', name: 'PESO FILIPINO' },
    { code: 'GBP', name: 'LIBRA ESTERLINA' },
    { code: 'PLN', name: 'ZŁOTY POLACO' },
    { code: 'SGD', name: 'DÓLAR DE SINGAPUR' },
    { code: 'THB', name: 'Baht tailandés' },
    { code: 'TRY', name: 'LIRA TURCA' },
    { code: 'USD', name: 'DÓLAR AMERICANO' },
    { code: 'VND', name: 'DONG VIETNAMITA' }
  ];

  public currenciesFr = [
    { code: 'AUD', name: 'Dollar Australien' },
    { code: 'AED', name: 'Dirham des ÉAU' },
    { code: 'CAD', name: 'Dollar Canadien' },
    { code: 'CNY', name: 'Yuan' },
    { code: 'COP', name: 'Peso Colombien' },
    { code: 'CRC', name: 'Colon de Costa Rica' },
    { code: 'DKK', name: 'Couronne danoise' },
    { code: 'EUR', name: 'EURO' },
    { code: 'HKD', name: 'Dollar De Hong Kong' },
    { code: 'HRK', name: 'Kuna Coate' },
    { code: 'IDR', name: 'Roupie Indonésienne' },
    { code: 'INR', name: 'Roupie Indienne' },
    { code: 'KRW', name: 'Won' },
    { code: 'MYR', name: 'Ringgit de Malaisie' },
    { code: 'MXN', name: 'Peso Mexican' },
    { code: 'NZD', name: 'Dollar Néo-zélandais' },
    { code: 'NOK', name: 'Couronne norvégienne' },
    { code: 'PHP', name: 'Peso Philippin' },
    { code: 'GBP', name: 'Livre sterling' },
    { code: 'PLN', name: 'Zloty' },
    { code: 'SGD', name: 'Dollar de Singapour' },
    { code: 'THB', name: 'Baht' },
    { code: 'TRY', name: 'Livre Turque' },
    { code: 'USD', name: 'Dollar' },
    { code: 'VND', name: 'Dong Vietnamien' }
  ];

  public EuroCountries = ['AD', 'AT', 'BE', 'CY', 'EE', 'FI', 'FR', 'DE', 'GR', 'IE', 'IT', 'XK',
    'LV', 'LT', 'LU', 'MT', 'MC', 'ME', 'NL', 'AN', 'PT', 'SM', 'SK', 'SI', 'ES', 'VA'];
  public AUDCountries = ['AU', 'KI', 'NR', 'TV'];
  public USDCountries = ['EC', 'SV', 'MH', 'FM', 'PW', 'US', 'ZW'];

  private currencies: ICurrency[] = [];

  public clinicCurrency = '£€$';
  private preferredCurrencyKey = 'md-currency';

  private currentCurrencySubject: BehaviorSubject<string>;
  public currentCurrency: Observable<string>;

  constructor(private commonService: CommonService, private logService: LogService) {
    this.currentCurrencySubject = localStorage.getItem(this.preferredCurrencyKey)
      ? new BehaviorSubject<string>(localStorage.getItem(this.preferredCurrencyKey)!)
      : new BehaviorSubject<string>(this.clinicCurrency);

    this.currentCurrency = this.currentCurrencySubject.asObservable();

    this.commonService.common$.subscribe((c: any) => this.currencies = c.currencies);
  }

  count = (): number => this.currencies.length;

  changeCurrency(currency: string) {
    localStorage.setItem(this.preferredCurrencyKey, currency);
    this.currentCurrencySubject.next(currency);
  }

  getAvailableCurrencies(): ICurrency[] {
    return this.currencies;
  }

  getAllExchangeRates() {
    this.logService.log('get all exchange rates');
  }

  exchange(amount: number, sourceCurrency: string, targetCurrency: string): number {
    if (!amount || amount === 0) {
      return 0;
    }

    const source = this.currencies.find(c => c.code?.toLowerCase().trim() === sourceCurrency?.toLowerCase().trim());
    const target = this.currencies.find(c => c.code?.toLowerCase().trim() === targetCurrency?.toLowerCase().trim());

    return (source && target) ? (amount / source.to_usd) * target.to_usd : 0;
  }

  sumUpRange(procedures: { quantity: number; prices: IPriceRange }[]): IPriceRange {
    const sum = {
      listed: { from: 0, to: 0 },
      discounted: { from: 0, to: 0 },
      diff: 0
    };

    procedures.forEach(procedure => {
      if (!procedure.quantity) {
        procedure.quantity = 1;
      }
      sum.listed.from +=
        procedure.prices.listed.from * procedure.quantity ||
        procedure.prices.discounted.from * procedure.quantity;
      sum.discounted.from +=
        procedure.prices.discounted.from * procedure.quantity ||
        procedure.prices.listed.from * procedure.quantity;

      sum.listed.to += procedure.prices.listed.to
        ? procedure.prices.listed.to * procedure.quantity
        : procedure.prices.listed.from * procedure.quantity ||
        procedure.prices.discounted.to * procedure.quantity;

      sum.discounted.to += procedure.prices.discounted.to
        ? procedure.prices.discounted.to * procedure.quantity
        : procedure.prices.discounted.from * procedure.quantity ||
        procedure.prices.listed.to * procedure.quantity;
    });

    sum.diff = Math.floor(((sum.listed.from - sum.discounted.from) * 100) / sum.listed.from);

    return sum;
  }

  findSymbol(currencyCode: string) {
    let symbol = '';
    const currencyLists: any =  Object.entries(CURRENCY_CODES);
    for (const [key, value] of currencyLists) {
      if(key == currencyCode) {
        symbol = value.symbol;
      }
    }
    return symbol;
  }



  /**
   *
   * @param countryCode pass country code to update currency
   */
  updateDefaultCurrency(countryCode: string) : void {
    let currency;
    switch (countryCode) {
      case 'AE': currency = 'AED'; break;
      case 'CA': currency = 'CAD'; break;
      case 'CN': currency = 'CNY'; break;
      case 'CO': currency = 'COP'; break;
      case 'CR': currency = 'CRC'; break;
      case 'DK': currency = 'DKK'; break;
      case 'HK': currency = 'HKD'; break;
      case 'HR': currency = 'HRK'; break;
      case 'ID': currency = 'IDR'; break;
      case 'IN': currency = 'INR'; break;
      case 'KR': currency = 'KRW'; break;
      case 'MY': currency = 'MYR'; break;
      case 'MX': currency = 'MXN'; break;
      case 'NZ': currency = 'NZD'; break;
      case 'NO': currency = 'NOK'; break;
      case 'PH': currency = 'PHP'; break;
      case 'GB': currency = 'GBP'; break;
      case 'PL': currency = 'PLN'; break;
      case 'SG': currency = 'SGD'; break;
      case 'TH': currency = 'THB'; break;
      case 'TR': currency = 'TRY'; break;
      case 'VN': currency = 'VND'; break;
      default:
        if(this.AUDCountries.includes(countryCode)) {
          currency = 'AUD'; break;
        }
        if(this.EuroCountries.includes(countryCode)) {
          currency = 'EUR'; break;
        }
        if(this.USDCountries.includes(countryCode)) {
          currency = 'USD'; break;
        }
        currency = 'USD'; break;
    }

    this.changeCurrency(currency);

  }

}
