<div class="why-departures-wrapper">
  <h2 class="title-only text-center">{{title}}</h2>
  <p class="why__subtitle mt-3 mb-5">
    {{subtle}}
  </p>
  <swiper class="swiper-containers" fxFlex="auto" [config]="config" [disabled]="disabled">
    <ng-container *ngFor="let why of whyInfo">
      <div class="swiper-slide swiper-options-header">
        <div fxLayout="column">
          <div class="general-card ">
            <ng-container *ngIf="why.link">
              <a [routerLink]="why.link">
                <div class="why__icon">
                  <i class="{{why.icon}}"></i>
                </div>
                <h3>{{why.title}}</h3>
                <p>{{why.info}}</p>
              </a>
            </ng-container>
            <ng-container *ngIf="!why.link">
              <div class="why__icon">
                <i class="{{why.icon}}"></i>
              </div>
              <h3>{{why.title}}</h3>
              <p>{{why.info}}</p>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </swiper>
</div>