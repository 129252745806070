import { Injectable } from '@angular/core';
import {UserLogin} from '../models/user.model';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppsIntegrationService {
  private _directAuth$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  encode(data: UserLogin): string {
    return btoa(JSON.stringify(data));
  }

  decode(data: string): UserLogin {
    return JSON.parse(atob(data));
  }

  get isDirectAuth(): BehaviorSubject<boolean> {
    return this._directAuth$;
  }

  setDirectAuth(state: boolean) {
    this._directAuth$.next(state);
  }
}
