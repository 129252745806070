import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { of, Observable, BehaviorSubject } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { AppleUserInfo } from './auth.model';
import { SocialUser, UserSignIn } from '@lib/models/user.model';
import { AccountTypeActions } from '@lib/models/account.models';
declare var AppleID: any;

@Injectable({
    providedIn: 'root'
})
export class AuthAppleService {

    /** 
     * Click event observables for Auth Apple 
     * since buttons are separated on the component
     */
    private appleSignInEvent = new BehaviorSubject<AccountTypeActions>(null);
    $appleSignInEvent = this.appleSignInEvent.asObservable();

    constructor(@Inject('env') public env: any) { }
    /**
     * Sign in using Apple ID
     */
    async appleSignIn(): Promise<AppleUserInfo | null> {
        const { apple_credentials } = this.env;
        try {
            AppleID.auth.init({
                clientId: apple_credentials.clientId,
                scope: apple_credentials.scope,
                redirectURI: apple_credentials.redirectURI,
                state: apple_credentials.state,
                usePopup: apple_credentials.usePopup,
            });
            const res: any = await AppleID.auth.signIn();
            if (res) {
                const { authorization } = res;
                const { code } = authorization;
                const appleUserInfo: AppleUserInfo = jwt_decode(authorization.id_token);
                appleUserInfo.code = code;
                appleUserInfo.idToken = authorization.id_token;
                return new Promise<AppleUserInfo>((resolve) => {
                    setTimeout(() => {
                        resolve(appleUserInfo);
                    }, 500);
                });

            } else {
                return new Promise<null>((resolve) => {
                    setTimeout(() => {
                        resolve(null);
                    }, 500);
                });
            }
        } catch (error: any) {
            console.log(error);
            return new Promise<null>((resolve) => {
                setTimeout(() => {
                    resolve(null);
                }, 500);
            });
        }
    }

    /**
     * 
     * @param appleUser pass appleUserInfo to Social user to be saved on db
     * Apple limits accessing on first name and last name
     * @returns Social User
     */
    mapAppleUserToSocialUser(appleUser: AppleUserInfo): SocialUser | null {
        if (appleUser) {
            const socialUser: SocialUser = {
                provider: 'APPLE',
                id: appleUser.sub,
                email: appleUser.email,
                name: appleUser.name,
                photoUrl: '',
                firstName: '',
                lastName: '',
                authToken: appleUser.idToken,
                idToken: appleUser.idToken,
                authorizationCode: appleUser.code
            };
            return socialUser;
        }
        return null;
    }

    /**
     * 
     * @param eventClickLocation  pass type of click event locaiton
     * Update event click apple for different UI
     */
    setSignInEvent(eventClickLocation: AccountTypeActions): void {
        this.appleSignInEvent.next(eventClickLocation);
    }

}