<div class="row justify-content-center justify-content-md-center justify-content-lg-center justify-content-xl-center my-5">
    <h3 i18n="@@popularLocations">Popular Locations</h3>
</div>

<div class="text-center mb-5 popular-location">
  <p-tabMenu class="tab-popular" #menuTab (click)="onChangeTab(menuTab)" [model]="items" [activeItem]="activeItem" [scrollable]="true" ></p-tabMenu>
</div>
<div class="container container-pl">
  <div  *ngIf="locations" class="row">
    <a *ngFor="let res of locations;index as i" class="col-6 col-sm-4 col-lg-4 mb-4 city"
       [href]="res.url">
      <div>
        <p>{{ res.city }}</p>
        <p>{{res.country }}</p>
      </div>
    </a>
  </div>
</div>
<div class="general-margin"></div>
