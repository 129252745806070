import { AfterContentChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem as MenuItemPrime } from 'primeng/api/menuitem';
import { SwiperOptions } from 'swiper';
import { SearchService } from '@lib/modules/search/search.service';

@Component({
  selector: 'app-options-header',
  templateUrl: './options-header.component.html',
  styleUrls: ['./options-header.component.scss']
})
export class OptionsHeaderComponent implements OnInit {

  private _menuPrimeItems: MenuItemPrime[] | any[] = [];
  @Input()
  set menuPrimeItems(menuPrimeItems: MenuItemPrime[] | any[]) {
    if (menuPrimeItems) {
      const menuListsPrimeItems = [...[], ...this.iconMatcher(JSON.parse(JSON.stringify(menuPrimeItems)))];
      this._menuPrimeItems = menuListsPrimeItems.filter((data) => data.icon);
      this._menuPrimeItems = [...[], ...this._menuPrimeItems, ...this.otherMenuNewHeaders()];
      this.config = this.swiperConfig();
    } else {
      this._menuPrimeItems = [];
    }
  }
  get menuPrimeItems(): MenuItemPrime[] | any[] {
    return this._menuPrimeItems;
  }

  @Input()
  platform: 'dental' | 'medical' | any = '';

  @Output()
  onClickEvent: EventEmitter<any> = new EventEmitter<any>();

  responsiveOptions: any[] = [];
  config!: SwiperOptions;
  disabled: boolean = false;

  constructor(
    private searchService: SearchService
  ) {
  }

  onClick(item: any) {
    this.onClickEvent.emit(item);
    this.config.direction = 'horizontal';
  }

  ngOnInit(): void {
    this.config = this.swiperConfig();

  };


  swiperConfig(): any {
    return {
      a11y: { enabled: true },
      updateOnWindowResize: true,
      direction: 'horizontal',
      slidesPerView: 10,
      keyboard: false,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: false,
      breakpoints: {
        120: {
          slidesPerView: 3.5,
          spaceBetween: 2
        },
        420: {
          slidesPerView: 4,
          spaceBetween: 2
        },
        640: {
          slidesPerView: 6,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 8,
          spaceBetween: 20
        },
        1670: {
          slidesPerView: 10,
          spaceBetween: 20
        }
      }
    };
  }


  otherMenuNewHeaders() {
    if (this.platform === 'medical') {
      return [
        {
          icon: 'fa-medical-body',
          items: '',
          routerLink: '/clinics/mommy-makeover',
          visible: true,
          label: 'Mommy Makeover'
        },
        {
          items: '',
          routerLink: '/clinics/cosmetic-plastic-surgery-face',
          icon: 'fa-medical-face',
          label: 'Face'
        },
        {
          icon: 'fa-medical-breast',
          items: '',
          routerLink: '/clinics/cosmetic-plastic-surgery-breast',
          visible: true,
          label: 'Breast'
        },
        {
          icon: 'fa-medical-liposuction',
          items: '',
          routerLink: '/clinics/liposuction',
          visible: true,
          label: 'Liposuction'
        },
        {
          icon: 'fa-medical-orthopedics',
          items: '',
          routerLink: '/clinics/orthopaedics',
          visible: true,
          label: 'Orthopedics'
        },
        {
          icon: 'fa-medical-nose-job',
          items: '',
          routerLink: '/clinics/cosmetic-plastic-surgery-nose',
          visible: true,
          label: 'Nose'
        },
        {
          icon: 'fa-medical-weight-loss',
          items: '',
          routerLink: '/clinics/weight-loss-surgery',
          visible: true,
          label: 'Weight Loss Surgery'
        },
        {
          icon: 'fa-medical-eyes',
          items: '',
          routerLink: '/clinics/cosmetic-plastic-surgery-eyes',
          visible: true,
          label: 'Eyes'
        },
        {
          icon: 'fa-ivf',
          items: '',
          routerLink: '/clinics/in-vitro-fertilization',
          visible: true,
          label: ' IVF'
        },
        {
          icon: 'fa-medical-butt',
          items: '',
          routerLink: '/clinics/cosmetic-plastic-surgery-butt',
          visible: true,
          label: 'Butt'
        },
        {
          icon: 'fa-medical-sex-reassignment',
          items: '',
          routerLink: '/clinics/gender-reassignment',
          visible: true,
          label: 'Sex Reassignment'
        },
        {
          icon: 'fa-medical-rejuvenation',
          items: '',
          routerLink: '/clinics/cosmetic-non-surgical-skin-rejuvenation',
          visible: true,
          label: 'Rejuvenation'
        },
        {
          icon: 'fa-medical-men',
          items: '',
          routerLink: '/clinics/men',
          visible: true,
          label: 'Men'
        },
        {
          icon: 'fa-hair-transplant',
          items: '',
          routerLink: '/clinics/hair-transplants',
          visible: true,
          label: 'Hair Transplants'
        },
        {
          icon: 'fa-medical-anti-aging',
          items: '',
          routerLink: '/clinics/cosmetic-non-surgical-skin-injectables-dermal-fillers',
          visible: true,
          label: 'Anti-Aging'
        },
        {
          icon: 'fa-medical-heart',
          items: '',
          routerLink: '/clinics/cardiology-heart',
          visible: true,
          label: 'Cardiology'
        },
        {
          icon: 'fa-medical-health-wellness',
          items: '',
          routerLink: '/clinics/health-wellness-medical-check-up-packages',
          visible: true,
          label: 'Health & Wellness'
        },
      ];
    } else {
      return [
        {
          icon: 'fa-snap-on-dentures',
          items: '',
          routerLink: '/dentists/snap-on-denture-supported-over-4-implants',
          visible: true,
          label: 'Snap-on Dentures'
        },
        {
          icon: 'fa-sinus',
          items: '',
          routerLink: '/dentists/sinus-lifting',
          visible: true,
          label: 'Sinus Lifting'
        },
        {
          icon: 'fa-z-implants',
          items: '',
          routerLink: '/dentists/zygomatic-implants',
          visible: true,
          label: 'Zygomatic Implants'
        },
        {
          icon: 'fa-clear-braces',
          items: '',
          routerLink: '/dentists/clear-braces',
          visible: true,
          label: 'Clear braces'
        },
        {
          name: `Root Canal`,
          slug: 'root-canal',
          visible: true,
          items: '',
          routerLink: '/dentists/root-canal',
          icon: 'fa-root-canal',
          label: 'Root Canal'
        },
        {
          name: `Whitening`,
          slug: 'whitening',
          items: '',
          routerLink: '/dentists/whitening',
          icon: 'fa-teeth-cleaning',
          label: 'Whitening'
        },
        {
          icon: 'fa-dental-fillings',
          items: '',
          routerLink: 'info/fillings',
          visible: true,
          label: 'Fillings'
        },
        {
          icon: 'fa-cleaning',
          items: '',
          routerLink: 'teeth-cleaning',
          visible: true,
          label: 'Teeth Cleaning'
        },
        {
          icon: 'fa-deep-cleaning',
          items: '',
          routerLink: '/dentists/deep-cleaning-scaling-root-planing-per-quadrant',
          visible: true,
          label: 'Deep Cleaning'
        },
        {
          icon: 'fa-tooth-extract',
          items: '',
          routerLink: '/dentists/tooth-extraction',
          visible: true,
          label: 'Extractions'
        },
        {
          icon: 'fa-bone-graft',
          items: '',
          routerLink: '/dentists/bone-graft-large',
          visible: true,
          label: 'Bone Graft'
        },
        {
          icon: 'fa-ct-scan',
          items: '',
          routerLink: '/dentists/ct-scan-3d-x-ray',
          visible: true,
          label: 'CT Scan'
        },
        {
          icon: 'fa-x-rays',
          items: '',
          routerLink: '/dentists/x-rays',
          visible: true,
          label: 'X-Ray'
        },
        {
          icon: 'fa-teeth-gas',
          items: '',
          routerLink: '/dentists/gas-sedation-nitrous-oxide',
          visible: true,
          label: 'Sedation (Nitrous)'
        },
      ];
    }
  }
  iconMatcher(items: MenuItemPrime[] | any[]) {
    let menuPrimeItemsCloned: any[] = [];
    if (this.platform == 'dental') {
      menuPrimeItemsCloned = items.map((data, index) => {
        if (data.routerLink.toLowerCase().includes('/all-on-4'.toLowerCase())) {
          data.icon = 'fa-all-for-number';
        }
        if (data.routerLink.toLowerCase().includes('/all-on-6'.toLowerCase())) {
          data.icon = 'fa-all-for-six';
        }
        else if (data.routerLink.toLowerCase().includes('/crowns'.toLowerCase())) {
          data.icon = 'fa-dental-crowns';
        }
        else if (data.routerLink.toLowerCase().includes('/veneers'.toLowerCase())) {
          data.icon = 'fa-veneers';
        }
        else if (data.routerLink.toLowerCase().includes('/dental-implants'.toLowerCase())) {
          data.icon = 'fa-dental-implants';
        }
        else if (data.routerLink.toLowerCase().includes('/dentures'.toLowerCase())) {
          data.icon = 'fa-dentures';
        }
        else if (data.routerLink.toLowerCase().includes('/fillings'.toLowerCase())) {
          data.icon = '';
        }
        else if (data.routerLink.toLowerCase().includes('/whitening'.toLowerCase())) {
          data.icon = 'fa-teeth-cleaning';
        }
        return data;
      })
      return menuPrimeItemsCloned;
    } else {
      menuPrimeItemsCloned = items.map((data, index) => {
        if (data.routerLink.toLowerCase().includes('/all-on-4'.toLowerCase())) {
          data.icon = 'fa-all-for-number';
        }
        if (data.routerLink.toLowerCase().includes('/all-on-6'.toLowerCase())) {
          data.icon = 'fa-all-for-six';
        }
        return data;
      });
      return menuPrimeItemsCloned;
    }
    return items;
  }

  public toggleKeyboardControl(): void {
    this.config.keyboard = !this.config.keyboard;
  }

  public toggleMouseWheelControl(): void {
    this.config.mousewheel = !this.config.mousewheel;
  }

  public onIndexChange(index: number): void { }
  public onSwiperEvent(event: string): void { }

  resetSortAndFilter() {
    this.searchService.updateSort(null);
    this.searchService.updateFilter(null);
  }

}
