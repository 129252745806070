import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

import { tap } from 'rxjs/operators';

const STATE_KEY_PREFIX = 'http_requests:';

@Injectable()
export class TransferHttpResponseInterceptor implements HttpInterceptor {

  // constructor(private transferState: TransferState, @Inject(PLATFORM_ID) private platformId: string) {   }
  constructor(private transferState: TransferState, private injector: Injector) {   }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Nothing to do with non-GET requests
    if (req.method !== 'GET') {
      return next.handle(req);
    }

    const platformId = this.injector.get(PLATFORM_ID);

    const key = makeStateKey<HttpResponse<object>>(STATE_KEY_PREFIX + req.url);

    if (isPlatformBrowser(platformId)) {
      const cachedResponse = this.transferState.get(key, null);
      if (cachedResponse) {
        this.transferState.remove(key);
        return of(new HttpResponse({
          body: cachedResponse.body,
          status: 200,
          statusText: 'OK (from server)'
        }));
      }
      return next.handle(req);
    }

    if (isPlatformServer(platformId)) {
      return next.handle(req).pipe(tap(event => {
        if (event instanceof HttpResponse && event.status === 200) {
          const response = {
            body: event.body
          };
          this.transferState.set(key, response);
        }
      }));
    }

    return next.handle(req);
  }
}
