<h1 class="title-only" *ngIf="title">{{title}}</h1>
<div class="row" *ngIf="allowExpandHide">
    <div class="col-md-8 text-right expandable-buttons">
        <button pButton class="p-button-xs  p-button-outlined p-button-label-zero p-button p-button-secondary " (click)="expandAll()">Expand All <i class="ri-arrow-down-s-fill"></i></button> &nbsp; 
        <button pButton class="p-button-xs p-button-outlined p-button-label-zero p-button p-button-secondary " (click)="hideAll()">Hide All <i class="ri-arrow-down-s-fill"></i></button>
        <br/>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-8">
        <br/>
        <p-accordion [activeIndex]="multiple ? null : 0" class="{{classWrapper}}" [multiple]="multiple">
            <ng-container *ngIf="!allowContent">
                <ng-container *ngFor="let accordionData of accordionDataLists">
                    <p-accordionTab header="{{accordionData.title}}" [selected]="accordionData.isExpanded">
                        <p *ngIf="!accordionData.template">
                            {{ accordionData.value }}
                        </p>
                        <ng-container *ngIf="accordionData.template">
                            <ng-container [ngTemplateOutlet]="accordionData.template"></ng-container>
                        </ng-container>
                    </p-accordionTab>
                </ng-container>
            </ng-container>
        </p-accordion>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-12">
        <br/></div>
</div>
