<div *ngIf="isLoading | async" class="flower-spinner">
  <div class="flower-spinner__container">
    <div class="flower-spinner__dots-container">
      <div class="flower-spinner__bigger-dot">
        <div class="flower-spinner__smaller-dot"></div>
      </div>
    </div>
  </div>
  <div (click)="$event.stopPropagation()" class="flower-spinner__overlay"></div>
</div>
