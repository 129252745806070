<br/>
<div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6" *ngIf="autoUpload">
        <p-fileUpload class="custom-upload-design" [class.hide-choose]="
        !(uploadedFiles && uploadedFiles.length <= 0)
        " [class.loading]="isUploading" name="uploadedFiles[]" #pUpload [showUploadButton]="false"
            [showCancelButton]="false" #pFileUpload [chooseLabel]="'Add Photos'" [multiple]="true"
            [chooseIcon]="'ri-image-fill'" accept=".jpeg, .png" (onSelect)="onSelect($event)" [mode]="'advanced'"
            [maxFileSize]="10000000">
            <ng-template pTemplate="content">
                <ng-container *ngIf="hasUploadedSucess && !isUploading">
                    <div class="upload-info container text-center upload-prompt ">
                        <p><i class="ri-check-line"></i></p>
                        <div>
                            Succesfully uploaded
                        </div>
                        <br />
                    </div>
                </ng-container>
                <ng-container *ngIf="hasUploadedFailed && !isUploading">
                    <div class="upload-info container text-center upload-prompt ">
                        <p><i class="ri-close-line"></i></p>
                        <div>
                            Upload Failed
                        </div>
                        <br />
                    </div>
                </ng-container>
                <div class="upload-info container text-center" *ngIf="uploadedFiles.length && isUploading">
                    <p>Uploading photos</p>
                    <br />
                    <br />
                    <div>
                        <p-progressBar class="progress-bar" mode="indeterminate" [showValue]="false"
                            [style]="{ height: '3px' }">
                        </p-progressBar>
                    </div>
                    <br />
                </div>

                <div class="upload-info-hasfile container text-center" *ngIf="!hasUploadedSucess && !hasUploadedFailed && uploadedFiles.length && !isUploading">
                    <i class="pi pi-file"></i>
                    <p>Chosen file</p>
                    <p class="plr-50">
                        File name: {{ uploadedFiles[0].name }}
                    </p>
                    <div class="btn" (click)="removeFiles()">
                        <i class="icon icon-remove"></i> Cancel file
                    </div>
                </div>

                <div class="file-info" *ngIf="!uploadedFiles.length && !isUploading">
                    <p style="margin-top: 0px;" class="file-label">JPEG or PNG only ( Max Size: 10MB )</p>
                </div>
            </ng-template>
        </p-fileUpload>
    </div>
    <div class="col-md-6" *ngIf="!autoUpload">
        <p-fileUpload class="custom-upload-design" [class.hide-choose]="
        !(uploadedFiles && uploadedFiles.length <= 0)
        " [class.loading]="isUploading" name="uploadedFiles[]" #pUpload [showUploadButton]="false"
            [showCancelButton]="false" #pFileUpload [chooseLabel]="'Add Photos'" [multiple]="true"
            [chooseIcon]="'ri-image-fill'" accept=".jpeg, .png, .jpg" (onSelect)="onSelect($event)" [mode]="'advanced'"
            [maxFileSize]="10000001">
            <ng-template pTemplate="content">
                <ng-container *ngIf="hasUploadedSucess && !isUploading">
                    <div class="upload-info container text-center upload-prompt ">
                        <p><i class="ri-check-line"></i></p>
                        <div>
                            Succesfully uploaded
                        </div>
                        <br />
                    </div>
                </ng-container>
                <ng-container *ngIf="hasUploadedFailed && !isUploading">
                    <div class="upload-info container text-center upload-prompt ">
                        <p><i class="ri-close-line"></i></p>
                        <div>
                            Upload Failed
                        </div>
                        <br />
                    </div>
                </ng-container>
                <div class="upload-info container text-center" *ngIf="uploadedFiles.length && isUploading">
                    <p>Uploading photos</p>
                    <br />
                    <br />
                    <div>
                        <p-progressBar class="progress-bar" mode="indeterminate" [showValue]="false"
                            [style]="{ height: '3px' }">
                        </p-progressBar>
                    </div>
                    <br />
                </div>
                <div class="upload-info-hasfile container text-center" *ngIf="!hasUploadedSucess && !hasUploadedFailed && uploadedFiles.length && !isUploading">

                    <p>Image/s: </p>
                    <br/>
                    <div class="row">
                        <ng-container *ngFor="let uploadFile of uploadedFiles; let i = index;">
                             <div class="col-6">
                                <div class="upload-image-wrapper">
                                    <div class="removeFile" (click)="removeFile(i)">
                                        <i class="ri-delete-bin-line"></i>
                                    </div>
                                    <p-image [src]="previewBlobImage(uploadFile)" alt="Image"  [preview]="true"></p-image>
                                </div>
                                <br/>
                            </div>
                        </ng-container>
                    </div>

                    <div class="btn" (click)="removeFiles()">
                        <i class="icon icon-remove"></i> Clear Images
                    </div>
                </div>
                <div class="file-info" *ngIf="!uploadedFiles.length && !isUploading">
                    <p style="margin-top: 0px;" class="file-label">JPEG or PNG only ( Max Size: 10MB )</p>
                </div>
            </ng-template>
        </p-fileUpload>
    </div>
    <div class="col-md-3"></div>

</div>
<br/>
<p-toast position="bottom-center" key="br"></p-toast>
