import { Injectable, Inject } from '@angular/core';
import { ApiService } from '../../data/core-api.service';
import { INewsletterSubscription, INewsletterUnsubscription } from './newsletter.models';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {
  constructor(
    @Inject('env') private env: any,
    private apiService: ApiService) { }

  subscribe = (model: INewsletterSubscription, recaptchaToken?: string) => {
    if (recaptchaToken) {
      model['g-recaptcha-response'] = recaptchaToken;
    }

    return this.apiService.newsletterSubscribePOST(model);
  };

  unsubscribe = (modal: INewsletterUnsubscription) => { };

  private subscribeMailChimp = () => { };
  private unsubscribeMailChimp = () => { };
}
