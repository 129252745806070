<div class="options-header" [class.medical]="this.platform === 'medical'">
    <div class="container container-main">
        <swiper class="swiper-containers" fxFlex="auto" [config]="config" [disabled]="disabled"
            (indexChange)="onIndexChange($event)" (swiperTransitionStart)="onSwiperEvent('transitionStart')"
            (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
            <ng-container *ngFor="let item of menuPrimeItems">
                <div class="swiper-slide swiper-options-header max-w-157" [class.resize-card-wlg]="item.icon == 'fa-medical-body'
                        || item.icon == 'fa-medical-weight-loss'
                        || item.icon == 'fa-medical-rejuvenation'
                        || item.icon == 'fa-medical-health-wellness'
                        || item.icon == 'fa-medical-sex-reassignment'
                    " [class.resize-card-wm]="item.icon === 'fa-deep-cleaning' || item.label == 'Zygomatic Implants'
                    || item.icon === 'fa-clear-braces' || item.label === 'Teeth gas sedation' || item.label == ' Teeth bone graft'
                    || item.label == 'Teeth bone graft' || item.icon == 'fa-snap-on-dentures' " [class.resize-card-ws]="item.icon == 'fa-dental-implants'
                    || item.label == 'Tooth Teeth Cleaning'">
                    <div fxLayout="column">
                      <a class="pointer" (click)="resetSortAndFilter()" [routerLink]=" item?.routerLink">
                            <div class="card text-center sub-header-icons" >
                                <div class="icon">
                                    <i class="fa-cust {{item.icon}}"></i>
                                </div>
                                <span>
                                    {{ item.label }}
                                </span>

                            </div>
                        </a>
                    </div>

                </div>
            </ng-container>
            <div class="swiper-slide swiper-options-header">
                <div class="column"></div>
            </div>
            <div class="swiper-slide swiper-options-header">
                <div class="column"></div>
            </div>
            <div class="swiper-slide swiper-options-header mobile-only">
                <div class="column"></div>
            </div>
        </swiper>
    </div>
</div>
