<ng-container *ngIf="isSkeletonOnly">
  <div class="clinic-details" style="pointer-events: none;">
    <div class="image-wrapper">
      <div class="image-handler"> <p-skeleton [height]="'100%'"></p-skeleton>
      </div>
    </div>
    <div class="info-wrapper">
      <div class="row">
        <div class="col-9">
          <div class="location"> <p-skeleton></p-skeleton></div>
        </div>
        <div class="col-3 text-right">
          <div class="rating"> <p-skeleton></p-skeleton>
          </div>
        </div>
        <div class="col-9">
          <div class="name"> <p-skeleton></p-skeleton> </div>
        </div>
        <div class="col-3"></div>
        <div class="col-12">
          <div class="price">
            <p-skeleton></p-skeleton>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!isSkeletonOnly">
  <div *ngIf='clinicSearchResult' [class.sponsored-wrapper]="clinicSearchResult.type === 'SPONSORED'">
    <div class="clinic-details" *ngIf="clinicSearchResult && isClinicSearchResult">
      <ng-container *ngIf="doctor && !isVisibleDoctorModal">
        <div class="book"
          (click)=" $event.stopPropagation(); this.isVisibleDoctorModal = true; this.activiateSlider();">
          <app-book-image [userImage]="(staticUrl + '' + (doctor.img ))">
          </app-book-image>
        </div>
      </ng-container>
      <div class="clinic-info__tags">
        <span *ngIf="clinicSearchResult.type ==='PREMIUM' "
          pTooltip='<b><h3 class="tip__header_tooltip" i18n="@@badgePremiumTitle">{{platform}} Departures Premium Clinic</h3></b> <br/> <span class="card-wrapper-tooltip" i18n="@@badgePremiumDescription"><p>This clinic offers excellent service, quality and offers the best available prices based on patient reviews &amp; feedback</p></span>'
          tooltipStyleClass="grid-style-tooltip" [escape]="false" [tooltipPosition]="'top'"
          [appendTo]="clinicContainer">PREMIUM</span>

        <span *ngIf="clinicSearchResult.type ==='SPONSORED' "
          pTooltip='<b><h3 class="tip__header_tooltip" i18n="@@badgeSponsoredTitle">{{badgeSponsoredTitle}}</h3></b> <br/> <span class="card-wrapper-tooltip"><p i18n="@@badgeSponsoredDescription">{{badgeSponsoredDescription}}</p></span>'
          tooltipStyleClass="grid-style-tooltip" [escape]="false" [tooltipPosition]="'top'"
          [appendTo]="clinicContainer">SPONSORED</span>

        <span *ngIf="clinicSearchResult.type !=='PREMIUM'" class="transparent-premium"></span>
        <span *ngIf="clinicSearchResult.type !=='SPONSORED'" class="transparent-premium"></span>

        <ng-container *ngIf="!offFavorites">
          <app-favorite-core [isFavorite]="isFavorite" [currentUser]="currentUser"
            [favoriteClinicCore]="{ favorites, clinicData }"></app-favorite-core>
        </ng-container>
        <ng-container *ngIf="offFavorites">
          <app-favorite-core [iconOnly]="true" [isFavorite]="isFavorite"
            [currentUser]="currentUser"></app-favorite-core>
        </ng-container>
        <span
          *ngIf="clinicSearchResult.award && clinicSearchResult.award.gpc && clinicSearchResult.award.gpc.length > 0"
          class="trophy" pTooltip="<div  i18n='@@gpcWinner'> Global Patients' Choice Winner </div>"
          [appendTo]="choiceAwardContainer" tooltipStyleClass="grid-style-tooltip" [escape]="false"
          [tooltipPosition]="'left'">
          <div #choiceAwardContainer class="choice-tooltip-container"></div> <i class="ri-trophy-fill"></i>
        </span>
      </div>
      <a (click)="onClickSponsoredAd(); $event.stopPropagation()"
        [routerLink]="['/' + ( this.slugUrl ? this.slugUrl + this.clinicSlugUrl : 'clinic/' + this.clinicSlugUrl )]">
        <div #clinicContainer></div>
        <div class="image-wrapper">
          <div class="image-handler" *ngIf="!(imagesUrls && imagesUrls?.length > 0) && (clinicSearchResult?.photos
          && (clinicSearchResult?.photos?.length > 0))"
            [style.background-image]="'url(' + staticUrl + '' + clinicSearchResult?.photos[0] || '' + ')'">
          </div>
          <div class="image-slider" *ngIf='(imagesUrls && imagesUrls?.length > 0)'>
            <app-carousel [dataCarousel]="{type: 'photo-only', data: imagesUrls, customWidth: '490px&format=webp', clinicName: clinicSearchResult.name} "
              [showNavigators]="true" (click)="$event.preventDefault(); $event.stopPropagation();"
              (onSwipeOrNext)="updateImageUrls($event)"
              (onClickEvent)="onClickNavigate($event);" [showIndicators]="true"
              [customWrapper]="'card card-search-wrapper'">
            </app-carousel>
          </div>
        </div>
        <div class="info-wrapper">
          <div class="row">
            <div class="col-9">
              <div class="location">{{location}}</div>
            </div>
            <div class="col-3 text-right">
              <div class="rating">
                <i class="fa fa-star"></i> <span class="rating-average">{{clinicSearchResult.reviews.stars | number:
                  '1.2-2'}}</span>
              </div>
            </div>
            <div class="col-9">
              <div class="name"><span pTooltip="{{clinicSearchResult.name}}"
                  tooltipPosition="top">{{clinicSearchResult.name}}</span>
              </div>
            </div>
            <div class="col-3"></div>
            <div class="col-12">
              <div class="price" *ngIf="clinicSearchResult.procedure">
                <span *ngIf="(clinicSearchResult?.selectedProcedure?.from > 0)">
                  <ng-container *ngIf="clinicSearchResult.selectedProcedure">
                    <ng-container *ngIf="clinicSearchResult?.selectedProcedure?.from > 0">
                      {{ clinicSearchResult?.selectedProcedure?.from | number: '1.0-0'}}
                    </ng-container>
                  </ng-container>
                  {{ currentCurrency }}
                  <ng-container *ngIf="clinicSearchResult.procedure && clinicSearchResult?.selectedProcedure?.from > 0">-
                    &nbsp; {{ clinicSearchResult.procedure.name }} </ng-container>
                </span>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="clinic-other-details col" *ngIf="clinicSearchResult.type === 'SPONSORED'">
      <div class="row">
        <div class="col-md-8">
          <div class="title-header">
            <div class="name"><span pTooltip="{{clinicSearchResult.name}}"
                tooltipPosition="top">{{clinicSearchResult.name}}</span>
            </div>
            <div class="sub-details">
              <i class="fa fa-star"></i> <span class="rating-average">{{clinicSearchResult.reviews.stars | number:
                '1.2-2'}}</span>
              <span class="rating-reviews">{{ clinicSearchResult.reviews.totalCount }} <span i18n="@@reviews"> reviews
                </span></span>
              <span class="address">
                {{location}}
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="other-buttons">
            <span class="share"
              (click)="isMobileNative ? navigatorShare(clinicSearchResult) : openModal(clinicModalViews.share)">
              <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.2695 8V12.5C12.2695 12.7652 12.1642 13.0196 11.9766 13.2071C11.7891 13.3946 11.5347 13.5 11.2695 13.5H2.26953C2.00431 13.5 1.74996 13.3946 1.56242 13.2071C1.37489 13.0196 1.26953 12.7652 1.26953 12.5V8M6.76953 0.5V12M1.76953 5.5L6.41953 0.85C6.513 0.758387 6.63865 0.707071 6.76953 0.707071C6.90041 0.707071 7.02607 0.758387 7.11953 0.85L11.7695 5.5"
                  stroke="#222222" />
              </svg>
              Share
            </span>
            <span class="favorite">
              <app-favorite-core [iconOnly]="false" [hasLabel]="true" [isFavorite]="isFavorite"
                [currentUser]="currentUser"></app-favorite-core>
            </span>
          </div>
        </div>
        <div class="col-8">
          <div *ngIf="clinicSearchResult.i18n && clinicSearchResult.i18n.length > 0" [innerHTML]="clinicSearchResult.i18n[0].introduction" class="description cms-content"></div>
        </div>
        <div class="col">
          <div class="action-wrapper">
            <button pButton class="wb-100 p-button-label-zero p-button" i18n="@@bookAppointment"
              (click)="bookClinic(clinicSearchResult)">Book appointment</button>
            <br />
            <button pButton i18n="@@contactClinic"
              class="wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary"
              (click)="openModal(clinicModalViews.contactClinic);">Contact clinic</button>
            <br />
          </div>
        </div>
      </div>

    </div>
  </div>

  <ng-container *ngIf="clinic && !isClinicSearchResult">
    <div class="clinic-details">
      <div class="clinic-info__tags">
        <span *ngIf="clinic.type ==='PREMIUM' "
          pTooltip='<b><h3 class="tip__header_tooltip" i18n="@@badgePremiumTitle">{{platform}} Departures Premium Clinic</h3></b> <br/><span class="card-wrapper-tooltip" i18n="@@badgePremiumDescription"><p>This clinic offers excellent service, quality and offers the best available prices based on patient reviews &amp; feedback</p></span>'
          tooltipStyleClass="grid-style-tooltip" [escape]="false" [tooltipPosition]="'top'"
          [appendTo]="clinicContainer">PREMIUM</span>

        <span *ngIf="clinic.type ==='SPONSORED' "
          pTooltip='<b><h3 class="tip__header_tooltip" i18n="@@badgeSponsporedTitle">{{badgeSponsoredTitle}}</h3></b> <br/><span class="card-wrapper-tooltip"><p i18n="@@badgeSponsoredDescription">{{badgeSponsoredDescription}}</p></span>'
          tooltipStyleClass="grid-style-tooltip" [escape]="false" [tooltipPosition]="'top'"
          [appendTo]="clinicContainer">SPONSORED</span>

        <span *ngIf="clinic.type !=='PREMIUM' " class="transparent-premium"></span>
        <span *ngIf="clinic.type !=='SPONSORED' " class="transparent-premium"></span>
        <app-favorite-core [isFavorite]="isFavorite" [currentUser]="currentUser"
          [favoriteClinicCore]="{ favorites, clinic }"></app-favorite-core>
        <span *ngIf="clinic.award && clinic.award.gpc.length > 0" class="trophy"
          pTooltip="<div  i18n='@@gpcWinner'> Global Patients' Choice Winner </div>" [appendTo]="choiceAwardContainer"
          tooltipStyleClass="grid-style-tooltip" [escape]="false" [tooltipPosition]="'left'">
          <div #choiceAwardContainer class="choice-tooltip-container"></div> <i class="ri-trophy-fill"></i>
        </span>
      </div>
      <a (click)="onClickSponsoredAd(); $event.stopPropagation()"
        [routerLink]="['/' + ( this.slugUrl ? this.slugUrl + this.clinicSlugUrl : 'clinic/' + this.clinicSlugUrl )]">
        <div #clinicContainer></div>
        <div class="image-wrapper" *ngIf="clinic.photos">
          <div class="image-handler" *ngIf='!(imagesUrls && imagesUrls.length > 0)'
            [style.background-image]="'url(' + staticUrl + '' + clinic.photos[0] + ')'">
          </div>
          <div class="image-slider" *ngIf='(imagesUrls && imagesUrls.length > 0)'>
            <app-carousel [dataCarousel]="{type: 'photo-only', data: imagesUrls, customWidth: '490px'} " [showNavigators]="true"
              (click)="$event.preventDefault(); $event.stopPropagation();" (onClickEvent)="onClickNavigate($event);"
              (onSwipeOrNext)="updateImageUrls($event)"
              [showIndicators]="true" [customWrapper]="'card card-search-wrapper'">
            </app-carousel>
          </div>
          <ng-container *ngIf="doctor && !isVisibleDoctorModal">
            <div class="book" (click)=" $event.stopPropagation(); this.isVisibleDoctorModal = true;">
              <app-book-image [userImage]="(staticUrl + '' + (doctor.img ))">
              </app-book-image>
            </div>
          </ng-container>
        </div>

        <div class="info-wrapper">
          <div class="row">
            <div class="col-9">
              <div class="location">{{location}}</div>
            </div>
            <div class="col-3 text-right" *ngIf="clinic.reviews">
              <div class="rating">
                <i class="fa fa-star"></i> <span class="rating-average">{{clinic.reviews.stars | number:
                  '1.2-2'}}</span>
              </div>
            </div>
            <div class="col-9">
              <div class="name"><span pTooltip="{{clinic.name}}" tooltipPosition="top">{{clinic.name}}</span>
              </div>
            </div>
            <div class="col-3"></div>
            <div class="col-12">
              <div class="price" *ngIf="clinic.procedure && (priceValue > 0)">
                <span *ngIf="priceValue && priceValue > 0">
                  {{ priceValue | number:
                  '1.0-0'}} {{ currentCurrency}} </span> &nbsp; {{ clinic.procedure.name ? ' - ' + clinic.procedure.name
                : '' }}
              </div>
            </div>
          </div>
        </div>
      </a>

    </div>
  </ng-container>
</ng-container>


<p-dialog [(visible)]="isVisibleDoctorModal && doctor && !isSkeletonOnly" [modal]="true" [showHeader]="false"
  class="custom-doctor-dialog" [styleClass]="'custom-doctor-dialog'" [position]="'bottom'" [style]="{ width: '100%' }">
  <div class="doctor-wrapper doctor-slide-container">
    <div (click)="isVisibleDoctorModal = false">
      <i class="ri ri-close-line"></i>
    </div>

    <ng-container *ngIf="(doctors && doctors.length)">
      <swiper class="swiper-containers" fxFlex="auto" [config]="config">
        <ng-container *ngFor="let doctor of doctors">
          <div class="swiper-slide swiper-options-header">
            <div fxLayout="column">
              <div class="book-holder" *ngIf="isVisibleDoctorModal">
                <app-book-image [docInfo]="doctor" [isBookExpanded]="true"
                  [userImage]="(staticUrl + '' + ( doctor.img ))"></app-book-image>
              </div>
              <div class="other-info">
                <div>
                  <h5><i class="ri-briefcase-line"></i> &nbsp; <span i18n="@@languageSpoken"> Language Spoken </span>
                  </h5>
                  <ul *ngIf="doctor.languages_spoken  && doctor.languages_spoken.length > 0 ">
                    <ng-container *ngFor="let docLang of doctor.languages_spoken">
                      <li> {{docLang}} </li>
                    </ng-container>
                  </ul>
                </div>
                <div>
                  <h5><i class="ri-briefcase-5-fill"></i> &nbsp; <span i18n="@@associations">Associations</span></h5>
                  <ul *ngIf="doctor && doctor.association && doctor.association.length > 0 ">
                    <ng-container *ngFor="let association of doctor.association">
                      <li> {{ association.name }} </li>
                    </ng-container>
                  </ul>
                </div>
                <div>
                  <h5><i class="ri-graduation-cap-line"></i> &nbsp; <span i18n="@@education">Education</span></h5>
                  <ul *ngIf="doctor && doctor.education && doctor.education.length > 0 ">
                    <ng-container *ngFor="let docEd of doctor.education">
                      <li> {{docEd.name}} </li>
                    </ng-container>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </ng-container>
      </swiper>
    </ng-container>

    <ng-container *ngIf="!(doctors && doctors.length)">
      <div class="book-holder" *ngIf="isVisibleDoctorModal">
        <app-book-image [docInfo]="doctor" [isBookExpanded]="true"
          [userImage]="(staticUrl + '' + ( doctor.img ))"></app-book-image>
      </div>
      <div class="other-info">
        <div>
          <h5><i class="ri-briefcase-line"></i> &nbsp; <span i18n="@@languageSpoken"> Language Spoken </span></h5>
          <ul *ngIf="doctor && doctorsLanguages && doctorsLanguages.length > 0 ">
            <ng-container *ngFor="let docLang of doctorsLanguages">
              <li> {{docLang}} </li>
            </ng-container>
          </ul>
        </div>
        <div>
          <h5><i class="ri-briefcase-5-fill"></i> &nbsp; <span i18n="@@associations">Associations</span></h5>
          <ul *ngIf="doctor && doctor.association && doctor.association.length > 0 ">
            <ng-container *ngFor="let association of doctor.association">
              <li> {{ association.name }} </li>
            </ng-container>
          </ul>
        </div>
        <div>
          <h5><i class="ri-graduation-cap-line"></i> &nbsp; <span i18n="@@education">Education</span></h5>
          <ul *ngIf="doctor && doctor.education && doctor.education.length > 0 ">
            <ng-container *ngFor="let docEd of doctor.education">
              <li> {{docEd.name}} </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>
</p-dialog>


<p-dialog [(visible)]="clinicSearchResult && isModalVisible" [modal]="true" [showHeader]="false"
  class="custom-card-dialog" (onHide)="onToggleVisible(true)" (onShow)="onToggleVisible(false)" [position]="'center'">
  <div class="close-action"><i class="ri-close-fill" (click)="isModalVisible=false;"></i></div>
  <div class="custom-clinic-dialog-wrapper">
    <div class="close-action-pusher"></div>
    <div [ngSwitch]="currentModalContent">
      <ng-container *ngSwitchCase="clinicModalViews.contactClinic">
        <app-contact-core [clinic]="clinicSearchResult"></app-contact-core>
      </ng-container>
      <ng-container *ngSwitchCase="clinicModalViews.share">
        <app-share-profile [clinic]="clinic"></app-share-profile>
      </ng-container>
    </div>
  </div>
</p-dialog>
