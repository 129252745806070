import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FOOTER_DISPLAY_STATE,
  HEADER_DISPLAY_STATE,
  PageLayoutService
} from '@lib/features/page-layout/page-layout.service';

@Component({
  selector: 'app-partner-thank-you',
  templateUrl: './partner-thank-you.component.html',
  styleUrls: ['./partner-thank-you.component.scss']
})
export class PartnerThankYouComponent implements OnInit, OnDestroy {
  @Input()
  siteName!: string;

  constructor(
    private route: Router,
    private pageLayoutService: PageLayoutService
  ) { }

  ngOnInit(): void {
    this.pageLayoutService.setToggleFooter(FOOTER_DISPLAY_STATE.SHOW_ONLY_SMALL_FOOTER);
  }

  goto(route?: string) {
    this.route.navigateByUrl(route ?? '/');
  }

  ngOnDestroy() {
    this.pageLayoutService.setToggleFooter(FOOTER_DISPLAY_STATE.HIDE_FOOTER);
  }
}
