<div class="login-wrapper">
    <h1 i18n="@@login"> Log in</h1>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" hiddenInput>
        <div class="form-group-wrapper">
            <div class="general-form">
                <app-custom-form-field>
                    <input pInputText #input customCoreInput [inputElement]="input" type="email"
                        class="input-field mb-0" i18n i18n-placeholder="@@formLabelEmail" placeholder="Email address" name="email" id="email"
                        formControlName="email" required>
                </app-custom-form-field>
            </div>
            <div class="general-form">
                <app-custom-form-field>
                    <div #inputPW class="wb-100 password-icon" id="show_hide_password">
                        <input pInputText customCoreInput [inputElement]="inputPW"
                            [type]="showPassword ? 'text': 'password'" placeholder="Password" class="password-input"
                            id="password" formControlName="password" i18n i18n-placeholder="@@password" required>
                        <div class="input-group-addon show-password" (click)="toggleShowPassword()">
                            <i id="password-icon" class="fa" [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                                aria-hidden="true"></i>
                        </div>
                    </div>
                </app-custom-form-field>
            </div>
        </div>

        <div class="form-check">
            <input type="checkbox" class="form-check-input" id="check1" name="option1" value="something" checked>
            <label class="form-check-label" for="check1" i18n="@@keepMeSignedIn"> &nbsp;  Keep me signed in</label>
        </div>
        <p class="mt-2 registration-terms-text" i18n="@@signInTermsAgreement">
            <span> By signing in, I agree to Dental Departures</span>
            <a routerLink="/terms" target="_blank">Terms & Conditions</a>,
            <a routerLink="/privacy" target="_blank">Privacy Policy</a>
        </p>
        <br />
        <div class="alert-text-danger" *ngIf="loginError">{{loginError}}</div>

        <p-progressBar mode="indeterminate" *ngIf="processing" [style]="{ height: '3px' }"></p-progressBar>
        <button pButton type="submit" [disabled]="processing" i18n="@@login" class='wb-100'
            [disabled]="!loginForm?.valid || processing">Log in
        </button>
        <br />

        <div class="have-an-account-container">
            <div class="fpassword" i18n="@@forgotYourPassword" (click)="updateAccountState(modalAccountAction.FORGOT_PASSWORD)">
                Forgot your password?
            </div>

        </div>
        <br />
        <div class="new-link">
            <div class="row">
              <div class="col" i18n="@@dontHaveAnAccount">
                Don't have an account?
              </div>
              <div class="col" style="max-width: 110px;">
                  <div class="login-link" i18n="@@createOne" (click)="updateAccountState(modalAccountAction.REGISTER)">
                    Create one
                  </div>
              </div>
            </div>
          </div>
    </form>

</div>
