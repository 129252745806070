import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { fromEvent, Observable, merge } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BrowserEventsService {

  private readonly isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  watchBrowserEvents(): Observable<boolean> {
    if (!this.isBrowser) {
      return new Observable<boolean>(); // Return an empty observable for non-browser platforms
    }

    const popState$ = fromEvent(window, 'popstate').pipe(
      map(() => true) // Map the event to true
    );

    // Merge multiple events into a single observable
    return merge(popState$).pipe(
      startWith(true), // Emit true on subscription
      filter((eventResult) => eventResult) // Filter out other values
    );
  }
}
