import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@lib/auth/auth.service';
import { ClinicTrackAction, UserActivities } from '@lib/constants/user-activities';
import { CurrencyService, IPriceRange } from '@lib/features/currency/currency.service';
import { PageService } from '@lib/features/page/page.service';
import { RecaptchaActions } from '@lib/features/recaptcha/recaptcha.models';
import { RecaptchaService } from '@lib/features/recaptcha/recaptcha.service';
import { UserActivityService } from '@lib/features/user-activity/user-activity.service';
import { IClinicPrice, IClinicPriceItem, IClinicQuote } from '@lib/modules/clinic/clinic.models';
import { ClinicService } from '@lib/modules/clinic/clinic.service';
import { MessageService } from 'primeng/api';
import { TableColumns } from '../dynamic-table/dynamic-table.component';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss']
})
export class PricesComponent implements OnInit {

  form!: FormGroup;

  @Input()
  allowSelect: boolean = false;

  @Input()
  showAdditionalInfo: boolean = true;

  @Input()
  isInModal: boolean = false;

  @Input()
  pricesColumn: TableColumns[] = [];

  @Input()
  hasPrivacy: boolean = false;

  @Input()
  selectedProcedures: any[] = [];

  @Input()
  clinicPrices: IClinicPrice[] = [];

  @Input()
  clinicID: any;

  @Input()
  clinicNewID: any;

  @Input()
  currency: any = '';

  allowHeaderSelect: boolean = false;

  summary: IPriceRange = {
    listed: { from: 0, to: 0 },
    discounted: { from: 0, to: 0 },
    diff: 0
  };

  durationSummary!: number;
  isLoadingEmailSend: boolean = false;
  clinicQuoteVisitDuration = $localize`:@@clinicQuoteVisitDuration:Estimated visit duration`;



  constructor(
    private currencyService: CurrencyService,
    private fb: FormBuilder,
    private clinicService: ClinicService,
    private recaptchaService: RecaptchaService,
    private userActivityService: UserActivityService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    if (this.isInModal) {
      const otherLog =  {
        action: UserActivities.ClinicTrackAction.LandedOnQuote,
        clinic_id:  this.clinicNewID ? this.clinicNewID : ''
      };
      this.userActivityService.
            logUserActivity(UserActivities.ClinicQuote.Name, UserActivities.ClinicQuote.Landed, null, otherLog);
      this.summary = {
        listed: { from: 0, to: 0 },
        discounted: { from: 0, to: 0 },
        diff: 0
      };
      this.form = this.fb.group({
        email_address: ['', [Validators.email, Validators.required]],
        opt_in: [''],
        first_name: [''],
        last_name: [''],
        phone_number: [''],
        'g-recaptcha-response': ['']
      });
    }
  }


  onSelectRowOrDataChange(value: any) {
    if (value) {
      const { selectedTableRow, rowData }: {selectedTableRow: Array<PROCEDURE>, rowData: PROCEDURE} = value;
      const  { originalEvent, data } = rowData;

      if (originalEvent) { // confirm if checkbox was checked
        if (selectedTableRow.length > 0) {
          selectedTableRow.find( p => {
            if (data) {
              if (data.id === p.id) {
                if (p.quantity < 1 ) {
                  p.quantity ++;
                }
              } else {
                data.quantity = 0;
              }

            }
          });
        } else {
          if (data) {
            data.quantity = 0;
          }
        }
      }

      this.selectedProcedures = selectedTableRow;
      const remapProcedures = [...this.selectedProcedures
        .filter((el) => el.quantity > 0).map((row) => ({
          quantity: row.quantity!,
          prices: row.price
        }))];
      this.summary = this.currencyService.sumUpRange(remapProcedures);
      if (this.selectedProcedures && this.selectedProcedures.length > 0 && this.summary.discounted.from) {
        this.durationSummary = this.selectedProcedures[0].duration;
      } else {
        this.durationSummary = undefined as any;
      }
    }
  }


  onSubmit() {
    if (!this.form.valid) { }
    if (this.selectedProcedures.length === 0) { return; }
    const requestData: IClinicQuote = Object.assign(
      {},
      this.form.value,
      {
        clinic_id: this.clinicID,
        duration: this.durationSummary,
        savings: `${this.summary.diff}% savings`,
        procedures: this.selectedProcedures.map(row => ({
          iso_code: row.notes.notes ? row.id : 'standard-' + row.id,
          quantity: row.quantity
        }))
      }
    );

    const allProcedures = this.selectedProcedures.map(procedure => procedure.name).join(', ');
    this.isLoadingEmailSend = true;
    this.recaptchaService.generateToken(RecaptchaActions.ClinicQuote)
      .subscribe(async (token: any) => {
        (requestData as any)['g-recaptcha-response'] = token;
        const res = await this.clinicService.requestQuote(requestData).toPromise();
        this.isLoadingEmailSend = false;
        if (res) {
          this.userActivityService.
            logUserActivity(UserActivities.ClinicQuote.Name,
              `${UserActivities.ClinicQuote.SelectedQuotes} - ${allProcedures}`);
          this.userActivityService.
            logUserActivity(UserActivities.ClinicQuote.Name, UserActivities.ClinicQuote.SubmittedQuote);
          const otherLog =  {
            action: UserActivities.ClinicTrackAction.SubmittedQuote,
            clinic_id:  this.clinicNewID ? this.clinicNewID : ''
          };
          this.userActivityService.
                logUserActivity(UserActivities.ClinicQuote.Name, UserActivities.ClinicQuote.SubmittedQuote, null, otherLog);
          this.form.reset();
          this.messageService.add({ key: 'bcprice', severity: 'success', summary: 'Success', detail: 'Quote successfully sent!' });
        } else {
          this.userActivityService.
            logUserActivity(UserActivities.ClinicQuote.Name, UserActivities.ClinicQuote.ErrorSubmitting);
          alert($localize`:@@clinicContactFormError:`);
        }
      });
  }

}

export interface PROCEDURE
{
  id: number;
  slug: string;
  name: string;
  brand: any;
  is_standard: boolean;
  has_detail: boolean;
  price: {
    listed: {
      from: number;
      to: number;
    };
    discounted: {
      from: number;
      to: number;
    }
  };
  duration: string;
  notes: any;
  quantity: number;
  data?: PROCEDURE;
  originalEvent?: any;
  type?: any;
}
