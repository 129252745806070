import { Injectable, Inject } from '@angular/core';
import { IArticleSchema, IClinicSchema, IStaticSearchSchema } from './schema.model';

@Injectable({
  providedIn: 'root'
})
export class SchemaService {

  constructor(@Inject('env') private env: any) { }

  // https://schema.org/NewsArticle
  getArticleSchema(article: IArticleSchema) {
    return {
      '@context': 'https://schema.org',
      '@type': 'NewsArticle',
      'mainEntityOfPage': {
        '@type': 'WebPage',
        '@id': `${this.env.schema.url}/${article.id}`
      },
      'headline': article.title,
      'image': article.images,
      'datePublished': article.datePublished,
      'dateModified': article.dateModified,
      'author': {
        '@type': 'Person',
        'name': article.authorName
      },
      'publisher': {
        '@type': 'Organization',
        'name': this.env.schema.name,
        'logo': {
          '@type': 'ImageObject',
          'url': this.env.schema.logo,
        }
      },
      'description': article.description
    };
  }

  // https://schema.org/MedicalClinic
  getClinicSchema(clinic: IClinicSchema) {
    const schema = {
      '@context': 'https://schema.org',
      '@type': 'MedicalClinic',
      '@id': `${clinic.url}/#clinic`,
      'name': clinic.name,
      'image': clinic.image,
      'url': clinic.url,
      'description': clinic.description,
      'priceRange': clinic.priceRange,
      'paymentAccepted': clinic.paymentAccepted ? clinic.paymentAccepted.join(', ') : '',
      'hasMap': clinic.address.latitude && clinic.address.longitude
        ? `https://maps.google.com/maps?ll=${clinic.address.latitude},${clinic.address.longitude}&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=@${clinic.address.latitude},${clinic.address.longitude}`
        : '',
      'mainEntityOfPage': {
        '@type': 'ItemPage',
        '@id': `${clinic.url}/#itempage`,
        'url': `${clinic.url}/#itempage`,
        'inLanguage': clinic.language,
        'name': clinic.name,
        'description': clinic.description,
        'isPartOf': {
          '@type': 'WebSite',
          '@id': `${this.env.schema.url}/#website`,
          'url': this.env.schema.url,
          'name': this.env.schema.name,
          'publisher': {
            '@type': 'Corporation',
            '@id': `${this.env.schema.url}/#corporation`,
            'name': this.env.schema.name,
            'url': this.env.schema.url,
            'sameAs': this.env.schema.sameAs,
            'contactPoint': this.getContactPoint(),
            'logo': {
              '@type': 'ImageObject',
              '@id': `${this.env.schema.url}/#logo`,
              'url': this.env.schema.logo,
              'caption': this.env.schema.name,
              'image': {
                '@type': 'ImageObject',
                '@id': `${this.env.schema.url}/#logo`
              }
            }
          }
        },
        'image': {
          '@type': 'ImageObject',
          '@id': `${this.env.schema.url}/#primaryimage`,
          'url': clinic.image
        },
        'primaryImageOfPage': {
          '@type': 'ImageObject',
          '@id': `${clinic.url}`
        },
        'breadcrumb': {
          '@type': 'BreadcrumbList',
          '@id': `${this.env.schema.url}/#breadcrumb`,
          'itemListElement': clinic.breadcrumbs.map(c => {
            return {
              '@type': 'ListItem',
              'position': c.position,
              'item': {
                '@type': 'WebPage',
                '@id': c.url,
                'url': c.url,
                'name': c.name
              }
            };
          })
        }
      },
      'hasOfferCatalog': {
        '@type': 'OfferCatalog',
        'treatments': `MedicalProcedure:${clinic.treatments.join(', ')}`
      },
      'amenityFeature': {
        '@type': 'LocationFeatureSpecification',
        'name': clinic.extras.join(', ')
      },
      'potentialAction': {
        '@type': 'ScheduleAction',
        'target': {
          '@type': 'EntryPoint',
          'urlTemplate': this.env.schema.url,
          'inLanguage': clinic.language,
          'actionPlatform': [
            'http://schema.org/DesktopWebPlatform',
            'http://schema.org/MobileWebPlatform',
            'http://schema.org/IOSPlatform',
            'http://schema.org/AndroidPlatform'
          ]
        },
        'result': {
          '@type': 'Reservation',
          'name': 'Make booking'
        }
      },
      'review': clinic.review!.reviews && clinic.review!.reviews.length > 0
        ? clinic.review!.reviews.map(c => {
          return {
            '@type': 'Review',
            'reviewBody': c.body,
            'datePublished': c.published,
            'author': {
              '@type': 'Person',
              'name': c.name
            },
            'reviewRating': {
              '@type': 'Rating',
              'ratingValue': c.rating
            }
          };
        })
        : [],
      'aggregateRating': {
        '@type': 'AggregateRating',
        'ratingValue': clinic.review!.rating,
        'ratingCount': clinic.review!.ratingCount
      },
      'address': {
        '@type': 'PostalAddress',
        'streetAddress': clinic.address.fullAddress,
        'addressRegion': clinic.address.province,
        'postalCode': clinic.address.postalCode,
        'addressCountry': {
          '@type': 'Country',
          'name': clinic.address.country
        }
      },
      'geo': {
        '@type': 'GeoCoordinates',
        'latitude': clinic.address.latitude,
        'longitude': clinic.address.longitude
      },
      'openingHoursSpecification': clinic.openHours ? clinic.openHours.map(c => {
        return {
          '@type': 'OpeningHoursSpecification',
          'dayOfWeek': `http://schema.org/${c.dayOfWeek}`,
          'opens': c.open,
          'closes': c.close
        };
      }) : [],
      'telephone': clinic.phone
    };

    // if (schema.review.length === 0) {
    //   delete schema.review;
    //   delete schema.aggregateRating;
    // }

    return schema;
  }

  // https://schema.org/ItemList
  getStaticSearchSchema(search: IStaticSearchSchema) {
    return {
      '@context': 'http://schema.org',
      '@type': 'ItemList',
      'mainEntityOfPage': {
        '@type': 'CollectionPage',
        '@id': `${this.env.schema.url}/${search.url}#collectionpage`,
        'url': `${this.env.schema.url}/${search.url}`,
        'inLanguage': 'en-US',
        'name': search.title,
        'description': search.description,
        'about': {
          '@type': 'Thing',
          'name': search.title
        },
        'contentLocation': {
          '@type': 'Place',
          'name': search.location,
        },
        'isPartOf': {
          '@type': 'WebSite',
          '@id': `${this.env.schema.url}/#website`,
          'url': this.env.schema.url,
          'name': this.env.schema.name,
          'publisher': {
            '@type': 'Corporation',
            '@id': `${this.env.schema.url}/#corporation`,
            'name': this.env.schema.name,
            'url': this.env.schema.url,
            'sameAs': this.env.schema.sameAs,
            'contactPoint': this.getContactPoint(),
            'logo': {
              '@type': 'ImageObject',
              '@id': `${this.env.schema.url}/#logo`,
              'url': this.env.schema.logo,
              'caption': this.env.schema.name,
              'image': {
                '@type': 'ImageObject',
                '@id': `${this.env.schema.url}/#logo`
              }
            }
          }
        },
        'image': {
          '@type': 'ImageObject',
          '@id': `${this.env.schema.url}/#primaryimage`,
          'url': `https://static.medicaldepartures.com/${search.photo}`
        },
        'primaryImageOfPage': {
          '@type': 'ImageObject',
          '@id': `${this.env.schema.url}/${search.url}`
        },
      },
      'numberOfItems': search.totalCount,
      'itemListElement': search.list.map(c => {
        return {
          '@type': 'ListItem',
          'position': c.position,
          'item': {
            '@id': `${this.env.schema.url}/${c.slug}`,
            'name': c.name,
            'url': `${this.env.schema.url}/${c.slug}`
          }
        };
      })
    };
  }

  getOrganizationSchema() {
    return {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      'url': this.env.schema.url,
      'logo': this.env.schema.logo,
      'name': this.env.schema.name,
      'email': this.env.schema.email,
      'brand': {
        '@type': 'Brand',
        'name': this.env.schema.name,
        'logo': this.env.schema.logo
      },
      'sameAs': this.env.schema.sameAs,
      contactPoint: this.getContactPoint()
    };
  }

  getWebsiteSchema() {
    return {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      'name': this.env.schema.name,
      'url': this.env.schema.url
    };
  }

  private getContactPoint() {
    return [{
      '@type': 'ContactPoint',
      'telephone': this.env.phones.us,
      'contactType': 'customer service',
      'areaServed': 'US',
      'email': this.env.schema.email,
      'availableLanguage': ['en', 'es']
    }, {
      '@type': 'ContactPoint',
      'telephone': this.env.phones.mx,
      'contactType': 'customer service',
      'areaServed': 'MX',
      'email': this.env.schema.email,
      'availableLanguage': ['en', 'es']
    }, {
      '@type': 'ContactPoint',
      'telephone': this.env.phones.gb,
      'contactType': 'customer service',
      'areaServed': 'UK',
      'email': this.env.schema.email,
      'availableLanguage': ['en']
    }, {
      '@type': 'ContactPoint',
      'telephone': this.env.phones.th,
      'contactType': 'customer service',
      'areaServed': 'TH',
      'email': this.env.schema.email,
      'availableLanguage': ['th', 'en']
    }, {
      '@type': 'ContactPoint',
      'telephone': this.env.phones.au,
      'contactType': 'customer service',
      'areaServed': 'AU',
      'email': this.env.schema.email,
      'availableLanguage': ['en']
    }, {
      '@type': 'ContactPoint',
      'telephone': this.env.phones.ca,
      'contactType': 'customer service',
      'areaServed': 'CA',
      'email': this.env.schema.email,
      'availableLanguage': ['en']
    }, {
      '@type': 'ContactPoint',
      'telephone': this.env.phones.nz,
      'contactType': 'customer service',
      'areaServed': 'NZ',
      'email': this.env.schema.email,
      'availableLanguage': ['en']
      }, {
        '@type': 'ContactPoint',
        'telephone': this.env.phones.fr,
        'contactType': 'customer service',
        'areaServed': 'FR',
        'email': this.env.schema.email,
        'availableLanguage': ['en', 'fr']
    }];
  }
}
