import { ViewportScroller } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '@lib/data/core-api.service';
import { RecaptchaActions } from '@lib/features/recaptcha/recaptcha.models';
import { RecaptchaService } from '@lib/features/recaptcha/recaptcha.service';
import { IClinic } from '@lib/modules/clinic/clinic.models';
import { ClinicService } from '@lib/modules/clinic/clinic.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageService } from 'primeng/api';
import { ReviewService } from 'projects/dental/src/app/modules/review/review.service';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-comment-review',
  templateUrl: './comment-review.component.html',
  styleUrls: ['./comment-review.component.scss']
})
export class CommentReviewComponent implements OnInit {

  @Input()
  clinic!: IClinic;

  isUploadLoading: boolean = false;
  isSubmitLoading: boolean = false;

  clinicReviewForm!: FormGroup;


  clinicId!: number;
  clinicName!: string;

  isSubmitted = false;

  isSubmittedSuccessfully = false;

  preUploadedPhotos: any;
  siteId = 1;

  constructor(
    public fb: FormBuilder,
    public reviewService: ReviewService,
    public clinicService: ClinicService,
    private recaptchaService: RecaptchaService,
    private viewportScroller: ViewportScroller,
    private apiService: ApiService,
    private messageService: MessageService,
    @Inject('env') private env: any,
  ) { }

  ngOnInit() {
    this.siteId = this.env.site_id;
    if (this.clinic) {
      this.clinicId = +this.clinic.id;
      this.clinicName = this.clinic.name;

      this.clinicReviewForm = this.fb.group({
        star_quality: ['', Validators.required],
        star_service: ['', Validators.required],
        star_cleanliness: ['', Validators.required],
        star_comfort: ['', Validators.required],
        star_communication: ['', Validators.required],
        star_value: ['', Validators.required],
        on_time: ['', Validators.required],
        minutes_late: ['', Validators.max(400)],
        appointment_date: [Date.now, Validators.required],
        comment: ['', [Validators.required, Validators.minLength(1)]],
        email: ['', [Validators.required, Validators.email]],
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        city: ['', Validators.required],
        country: ['', Validators.required],
        has_share_photos: [''],
        photos: [''],
        // photo_names: [''], // ??
        share_photos: [''], /// accept to share photos
        agree: ['', Validators.requiredTrue],
        clinic_id: [this.clinicId, Validators.required],
        'g-recaptcha-response': ['']
      });

    }
  }

  onSubmit() {
    this.isSubmitted = true;
    if(this.clinicReviewForm.get('has_share_photos')?.value === true) {
      this.clinicReviewForm.get('share_photos')?.setValidators(Validators.required);
    } else {
      this.clinicReviewForm.get('share_photos')?.setValidators(null);
    }
    this.clinicReviewForm.controls.share_photos.updateValueAndValidity();
    this.clinicReviewForm.markAllAsTouched();

    if(this.clinicReviewForm.get('has_share_photos')?.value === true &&  this.clinicReviewForm.get('share_photos')?.value === true) {
      if(!(this.preUploadedPhotos && this.preUploadedPhotos.length > 0)) {
        this.messageService.add({ key: 'bcReview', severity: 'error', summary: 'Error', detail: `No photos were selected`, closable: false });
        return ;
      }
    }
    
    if (this.clinicReviewForm.invalid) {
      this.messageService.add({ key: 'bcReview', severity: 'error', summary: 'Error', detail: `Some fields are required.`, closable: false });
      return;
    } else {
      this.isSubmitLoading = true;
      
      this.recaptchaService.generateToken(RecaptchaActions.ClinicReview).subscribe((token: string) => {
        this.clinicReviewForm.patchValue({ 'g-recaptcha-response': token });
        this.reviewService
          .submitClinicReview(this.clinicReviewForm.value)
          .pipe(untilDestroyed(this), switchMap(data => {
            const { id } = data;
            if (id && this.preUploadedPhotos && this.preUploadedPhotos.length > 0) {
              this.apiService
                .reviewImageUploadViaIDPOST(this.clinicId, this.preUploadedPhotos, id)
                .pipe(
                  untilDestroyed(this),
                  catchError(val => of(null)))
                .subscribe(res => {
                  if (res === null) {
                    alert($localize`:@@alertUploadError:`);
                    return;
                  } else  
                  return data;
                });
            }
            return of(data);
          })).subscribe(async (res: any) => {
            this.isSubmitLoading = false;
            this.isSubmittedSuccessfully = true;
          },
            err => {
              this.isSubmitLoading = false;

            }
          );
      });
    }
  }

  photosUploaded(fileNames: string) {
    this.clinicReviewForm.get('photos')?.patchValue(fileNames);
  }

  updateRating(input: string, score: number) {
    this.clinicReviewForm.get(input)?.patchValue(score);
  }

  resetMinutesLate() {
    this.clinicReviewForm.get('minutes_late')?.patchValue(0);
  }

  preUploaded(photos: any) {
    this.preUploadedPhotos = photos;
  }

}
