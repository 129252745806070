import { Component, Input, OnInit } from '@angular/core';
import { IClinicTop } from '@lib/modules/clinic/clinic.models';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-top-view-clinics',
  templateUrl: './top-view-clinics.component.html',
  styleUrls: ['./top-view-clinics.component.scss']
})
export class TopViewClinicsComponent implements OnInit {

  _topClinics!: IClinicTop[];
  @Input()
  set topClinics(topClinics: IClinicTop[]) {
    if (topClinics && topClinics.length > 0) {
      this._topClinics = topClinics.slice(0, this.limit);
    } else {
      this._topClinics = [];
    }
    this.config = this.swiperConfig();
  }
  get topClinics() {
    return this._topClinics;
  }

  @Input() location!: string;
  @Input() treatment!: string;
  @Input() staticSearch!: string;
  @Input() title: string | any = 'Top Clinics For Dental Implants In Mexico';
  @Input() limit: number = 10;
  @Input() favorites: any;
  @Input() currentUser: any;
  @Input() staticUrl!: string;
  @Input() hasMetaData: boolean = true;
  @Input() hasMoreClinicLink: boolean = true;


  config!: SwiperOptions;
  disabled: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  swiperConfig(): any {
    return {
      a11y: { enabled: true },
      updateOnWindowResize: true,
      direction: 'horizontal',
      slidesPerView: 4,
      keyboard: false,
      mousewheel: false,
      scrollbar: false,
      navigation: false,
      pagination: false,
      breakpoints: {
        120: {
          slidesPerView: 1.2,
          spaceBetween: 20
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 40
        }
      }
    };
  }
  public toggleKeyboardControl(): void {
    this.config.keyboard = !this.config.keyboard;
  }

  public toggleMouseWheelControl(): void {
    this.config.mousewheel = !this.config.mousewheel;
  }

  public onIndexChange(index: number): void { }
  public onSwiperEvent(event: string): void { }
}
