import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-signup-form-step4',
  templateUrl: './signup-form-step4.component.html',
  styleUrls: ['../../../components/partner-signup-core/partner-signup-core.component.scss',
    './signup-form-step4.component.scss']
})
export class SignupFormStep4Component implements OnInit {
  @Input() siteName!: string;
  @Input() isLoading: boolean = false;
  @Output() stepSubmitted: EventEmitter<any> = new EventEmitter();
  @Output() stepBack: EventEmitter<number> = new EventEmitter();

  form: FormGroup;
  submitted!: boolean;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      license: [''],
      registration: [''],
      guarantees: [''],
      english: ['1'],
      insurance: [''],
      sterilization: [''],
      'g-recaptcha-response': ['']
    });
  }

  ngOnInit() {}

  onStepBack() {
    this.stepBack.emit(4);
  }

  submit() {
    this.stepSubmitted.emit({ step_id: 4, form: this.form.value });
    this.submitted = true;
  }
}
