import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    // .then(ref => {
    //   // Ensure Angular destroys itself on hot reloads.
    //   // https://stackblitz.com/edit/angular-window-hostname?file=src%2Fmain.ts
    //   if (window['ngRef']) {
    //     window['ngRef'].destroy();
    //   }

    //   window['ngRef'] = ref;
    // })
    .catch(err => console.error(err));
});
