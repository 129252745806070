export enum ShowState {
  SHOW = 'SHOW',
  NO_SHOW = 'NO_SHOW'
}
export enum RegisterState {
  SUCCESS = 'SUCCESS',
  IDLE = 'IDLE'
}

export enum AccountAction {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  ERROR = 'ERROR'
}

export enum AcccountLoginType {
  GUEST = '(guest)',
  GOOGLE = '(google)',
  APPLE = '(apple)',
  EMAIL = '(email)'
}

export type AccountTypeActions = AccountAction.LOGIN | AccountAction.REGISTER | AccountAction.FORGOT_PASSWORD | null | string;
export type AccountLoginTypeModel = AcccountLoginType.GUEST |  AcccountLoginType.APPLE |  AcccountLoginType.GOOGLE |  AcccountLoginType.EMAIL | null | string;

