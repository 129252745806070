
export const MEDICAL_TREATMENTS: ReadonlyArray<any> = [
  {
    name: $localize`:@@antiAgingCheckUpPackage:Anti-Aging Check-Up Package`,
    slug: '',
    children: [
      {
        name: $localize`:@@dermalFillers:Dermal Fillers`,
        slug: 'dermal-fillers'
      },
      {
        name: $localize`:@@injectableNeurotoxin:Injectable Neurotoxin`,
        slug: 'injectable-neurotoxin'
      }
    ]
  },
  {
    name: $localize`:@@cardiologyHeart:Cardiology (Heart)`,
    slug: '',
    children: [
      {
        name: $localize`:@@angioplasty:Angioplasty`,
        slug: 'angioplasty'
      },
      {
        name: $localize`:@@cardiacScreening:Cardiac Screening`,
        slug: 'cardiac-screening'
      },
      {
        name: $localize`:@@echocardiogram:Echocardiogram`,
        slug: 'echocardiogram'
      }
    ]
  },
  {
    name: $localize`:@@cosmeticPlasticSurgeryBody:Cosmetic and Plastic Surgery: Body`,
    slug: '',
    children: [
      {
        name: $localize`:@@armLift:Arm Lift`,
        slug: 'arm-lift'
      },
      {
        name: $localize`:@@bodyLift:Body Lift`,
        slug: 'body-lift'
      },
      {
        name: $localize`:@@mommyMakeover:Mommy Makeover`,
        slug: 'mommy-makeover'
      },
      {
        name: $localize`:@@tummyTuck:Tummy Tuck`,
        slug: 'tummy-tuck'
      }
    ]
  },
  {
    name: $localize`:@@cosmeticPlasticSurgeryBreast:Cosmetic and Plastic Surgery: Breast`,
    slug: '',
    children: [
      {
        name: $localize`:@@breastAugmentation:Breast Augmentation`,
        slug: 'breast-augmentation'
      },
      {
        name: $localize`:@@breastImplants:Breast Implants`,
        slug: 'breast-implants'
      },
      {
        name: $localize`:@@breastLift:Breast Lift`,
        slug: 'breast-lift'
      },
      {
        name: $localize`:@@breastReduction:Breast Reduction`,
        slug: 'breast-reduction'
      },
      {
        name: $localize`:@@breastRevision:Breast Revision`,
        slug: 'breast-revision'
      }
    ]
  },
  {
    name: $localize`:@@cosmeticPlasticSurgeryButt:Cosmetic and Plastic Surgery: Butt`,
    slug: '',
    children: [
      {
        name: $localize`:@@brazilianButtLift:Brazilian Butt Lift (BBL)`,
        slug: 'brazilian-butt-lift-bbl'
      },
      {
        name: $localize`:@@buttImplants:Butt Implants`,
        slug: 'butt-implants'
      },
      {
        name: $localize`:@@buttLift:Butt Lift`,
        slug: 'butt-lift'
      }
    ]
  },
  {
    name: $localize`:@@cosmeticPlasticSurgeryEyes:Cosmetic and Plastic Surgery: Eyes`,
    slug: '',
    children: [
      {
        name: $localize`:@@cataracts:Cataracts`,
        slug: 'cataracts'
      },
      {
        name: $localize`:@@doubleEyelidSurgery:Double Eyelid Surgery`,
        slug: 'double-eyelid-surgery'
      },
      {
        name: $localize`:@@eyebagRemoval:Eyebag Removal`,
        slug: 'eyebag-removal'
      },
      {
        name: $localize`:@@eyebrowLift:Eyebrow Lift`,
        slug: 'eyebrow-lift'
      },
      {
        name: $localize`:@@lasik:LASIK`,
        slug: 'lasik'
      },
    ]
  },
  {
    name: $localize`:@@cosmeticPlasticSurgeryFace:Cosmetic and Plastic Surgery: Face`,
    slug: '',
    children: [
      {
        name: $localize`:@@facelift:Facelift`,
        slug: 'facelift'
      },
      {
        name: $localize`:@@foreheadLift:Forehead Lift`,
        slug: 'forehead-lift'
      },
      {
        name: $localize`:@@neckLift:Neck Lift`,
        slug: 'neck-lift'
      }
    ]
  },
  {
    name: $localize`:@@cosmeticNonSurgicalSkinRejuvenation:Cosmetic Non Surgical Skin - Rejuvenation`,
    slug: '',
    children: [
      {
        name: $localize`:@@laserSkinResurfacing:Laser Skin Resurfacing`,
        slug: 'laser-skin-resurfacing'
      }
    ]
  },
  {
    name: $localize`:@@fertility:Fertility`,
    slug: '',
    children: [
      {
        name: $localize`:@@assistedHatching:Assisted Hatching`,
        slug: 'assisted-hatching'
      },
      {
        name: $localize`:@@ivf:IVF`,
        slug: 'ivf'
      },
      {
        name: $localize`:@@spermBank:Sperm Bank`,
        slug: 'sperm-bank'
      }
    ]
  },
  {
    name: $localize`:@@genderReassignment:Gender Reassignment`,
    slug: '',
    children: [
      {
        name: $localize`:@@genderReassignmentThailand:Gender reassignment in Thailand`,
        slug: 'gender-reassignment/thailand'
      },
    ]
  },
  {
    name: $localize`:@@healthWellnessMedicalCheckUpPackages:Health, Wellness and Medical Check-Up Packages`,
    slug: '',
    children: [
      {
        name: $localize`:@@antiAgingCheckUpPackage:Anti-Aging Check-Up Package`,
        slug: 'anti-aging-check-up-package'
      },
      {
        name: $localize`:@@cardiacScreening:Cardiac Screening`,
        slug: 'cardiac-screening'
      },
      {
        name: $localize`:@@medicalCheckUpPackage:Medical Check-Up Package`,
        slug: 'medical-check-up-package'
      }
    ]
  },
  {
    name: $localize`:@@mdLiposuction:Liposuction`,
    slug: '',
    children: [
      {
        name: $localize`:@@mdFacialLiposuction:Facial Liposuction`,
        slug: 'facial-liposuction'
      },
      {
        name: $localize`:@@mdLiposuction:Liposuction`,
        slug: 'liposuction'
      }
    ]
  },
  {
    name: $localize`:@@men:Men`,
    slug: '',
    children: [
      {
        name: $localize`:@@gynaecomastia:Gynaecomastia`,
        slug: 'male-breast-reduction-gynaecomastia'
      },
      {
        name: $localize`:@@hairTransplant:Hair Transplant`,
        slug: 'hair-transplants'
      }
    ]
  },
  {
    name: $localize`:@@noseJob:Nose Job`,
    slug: '',
    children: [
      {
        name: $localize`:@@mdRhinoplasty:Rhinoplasty`,
        slug: 'rhinoplasty'
      }
    ]
  },
  {
    name: $localize`:@@orthopedics:Orthopedics`,
    slug: '',
    children: [
      {
        name: $localize`:@@hipReplacement:Hip Replacement`,
        slug: 'hip-replacement'
      },
      {
        name: $localize`:@@kneeReplacement:Knee Replacement`,
        slug: 'knee-replacement'
      }
    ]
  },
  {
    name: $localize`:@@weightLossSurgery:Weight Loss Surgery`,
    slug: '',
    children: [
      {
        name: $localize`:@@bariatricSurgery:Bariatric Surgery`,
        slug: 'bariatric-surgery'
      },
      {
        name: $localize`:@@gastricBand:Gastric Band`,
        slug: 'gastric-band'
      },
      {
        name: $localize`:@@gastricBypass:Gastric Bypass`,
        slug: 'gastric-bypass'
      },
      {
        name: $localize`:@@sleeveGastrectomy:Sleeve Gastrectomy`,
        slug: 'sleeve-gastrectomy'
      }
    ]
  }
];
