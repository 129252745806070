import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from '@lib/models/api-response.model';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  apiHost: string;

  constructor(
    private http: HttpClient,
    @Inject('env') private env: any,
  ) {
    this.apiHost = this.env.api.medid_v2;
  }

  confirmEmail(confirmationToken: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.apiHost + 'confirm-email', {
      confirmation_code: confirmationToken
    });
  }

  sendConfirmEmail(email: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.apiHost + 'send-confirmation-code', {
      email
    });
  }

  isExists(email: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.apiHost + 'email-exists', {
      email
    });
  }
}
