<div class="register-wrapper">
  <h1>Sign up</h1>
  <div class="registration" *ngIf="!isEmailRegistered">
    <!--    <h2 class="auth-header">Create an account</h2>-->
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" hiddenInput>
      <div class="form-group-wrapper">
        <div class="form-group">
          <app-custom-form-field>
            <input pInputText #input i18n i18n-placeholder="@@formLabelEmail" customCoreInput [inputElement]="input" type="email" class="wb-100"
              placeholder="Email address" id="email" aria-describedby="emailHelp" formControlName="email" required>
          </app-custom-form-field>
          <input type="hidden" id="socialEmail" formControlName="social_email">
        </div>
        <div class="form-group">
          <app-custom-form-field>
            <input pInputText #inputFN i18n i18n-placeholder="@@formLabelFirstName" customCoreInput [inputElement]="inputFN" type="text" class="wb-100"
              placeholder="First name" id="firstName" formControlName="first_name" required>
          </app-custom-form-field>
        </div>
        <div class="form-group">
          <app-custom-form-field>
            <input pInputText #inputLN i18n i18n-placeholder="@@formLabelLastName" customCoreInput [inputElement]="inputLN" type="text" class="wb-100"
              placeholder="Last name" id="lastName" formControlName="last_name" required>
          </app-custom-form-field>
        </div>
        <div class="form-group">
          <app-custom-form-field>
            <div #inputPW class="input-group password-icon" id="show_hide_password">
              <input pInputText customCoreInput i18n i18n-placeholder="@@password" [inputElement]="inputPW" [type]="showPassword ? 'text': 'password'"
                placeholder="Password" class="password-input wb-100" id="password" formControlName="password" required>
              <div class="input-group-addon show-password" (click)="toggleShowPassword()">
                <i id="password-icon" class="fa" [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                  aria-hidden="true"></i>
              </div>
            </div>
          </app-custom-form-field>
        </div>
      </div>
      <input type="hidden" formControlName="provider">
      <input type="hidden" formControlName="user_id">
      <input type="hidden" formControlName="avatar">
      <input type="hidden" formControlName="accepted_term_id">

      <!-- <div class="form-check">
        <input type="checkbox" class="form-check-input" id="check1" name="option1" value="something" checked>
        <label class="form-check-label" for="check1"> &nbsp; Keep me signed in</label>
      </div> -->
      <br/>

      <div class="alert-text-danger" *ngIf="signUpError">{{signUpError}}</div>
      <p-progressBar mode="indeterminate" *ngIf="processing" [style]="{ height: '3px' }"></p-progressBar>
      <button pButton class='wb-100' i18n="@@formButtonContinue"
              type="submit" [disabled]="!registerForm.valid || processing">Continue</button>
      <br />

      <!-- <div class="have-an-account-container">
        <div class="fpassword" (click)="updateAccountState(modalAccountAction.FORGOT_PASSWORD)">
          Forgot your password?
        </div>
        <div (click)="updateAccountState(modalAccountAction.LOGIN)">
          Already have an account? <span>Login here</span>
        </div>
      </div> -->
      <div class="new-link">
        <div class="row">
          <div class="col" i18n="@@alreadyHaveAnAccount">
            Already have an account?
          </div>
          <div class="col" style="max-width: 110px;">
              <div class="login-link" i18n="@@signIn" (click)="updateAccountState(modalAccountAction.LOGIN)">
                Sign in
              </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div *ngIf="isEmailRegistered" class="congratulations">
  <h1>Congratulations!</h1>
  <p *ngIf="isExternalRegistered">Your account is created.</p>
  <p *ngIf="!isExternalRegistered">Your account is created. One more step to complete your registration.</p>
  <ng-container *ngIf="!isExternalRegistered">
    <p>We've sent email to <strong>{{ registeredEmail }}</strong>.</p>
    <p>Please go to your inbox and follow the instruction to confirm your e-mail.</p>
  </ng-container>
  <hr *ngIf="isExternalRegistered">
  <br />
  <button pButton type="button" (click)="updateAccountState(modalAccountAction.LOGIN)"
    class="p-element wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary p-component">
    Back to login Page
  </button>
</div>
