<div *ngIf="latestReview" class="latest-review-wrapper">
    <div *ngIf="!isReviewArray || isFooter"
        class="row justify-content-start justify-content-md-center justify-content-lg-center justify-content-xl-center my-5">
        <h3 class="title-only" i18n="@@latestReview">Latest Reviews</h3>
    </div>
    <div class="row mb-5 mt-3">
        <div class="col">
            <swiper class="swiper-containers" fxFlex="auto" [config]="config" [disabled]="disabled"
                (indexChange)="onIndexChange($event)" (swiperTransitionStart)="onSwiperEvent('transitionStart')"
                (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
                <div class="swiper-slide swiper-options-header" *ngIf="!isReviewArray">
                    <div fxLayout="column">
                        <div class="card card-reviewer">
                            <p class="reviewer">{{latestReview.reviewer.name}}</p>
                            <p class="date">{{ latestReview.reviewed_at | date: 'dd yyyy'}}</p>
                            <br />
                            <p-scrollPanel [style]="{ width: '100%', height: '101px' }">
                                <p> {{ latestReview.review_message}}</p>
                            </p-scrollPanel>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="isReviewArray && isFooter">
                    <ng-container *ngFor="let lt of latestReview">
                        <div class="swiper-slide swiper-options-header">
                            <div fxLayout="column">
                                <div class="card card-reviewer small-h">
                                    <p class="reviewer">{{lt.reviewer.name}}</p>
                                    <p class="date">{{ lt.reviewed_at | date: 'dd yyyy'}}</p>
                                    <br />
                                    <p-scrollPanel [style]="{ width: '100%' }">
                                        <p> {{ lt.review_message}}</p>
                                    </p-scrollPanel>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="isReviewArray && !isFooter">
                    <ng-container *ngFor="let latestReviews of latestReview">
                        <div class="swiper-slide swiper-options-header">
                            <div fxLayout="column">
                                <div class="card card-reviewer">
                                    <p class="reviewer">
                                        {{latestReviews.reviewer.name}}
                                        <span class="start-wrapper">
                                            <app-star-review-input-core [ratingSelected]="latestReviews.averageScore">
                                            </app-star-review-input-core>
                                        </span>
                                    </p>
                                    <p class="date">{{ latestReviews.date | date:'MMMM y'}} </p>
                                    <p class="comment"> {{ latestReviews.comment }}</p>
                                    <p class="showMore" (click)="choosenReview(latestReviews)"><span
                                            i18n="@@showMore">Show more </span> <i class="ri-arrow-right-s-line"></i>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </swiper>
        </div>
    </div>
    <div class="review-more" *ngIf="isReviewArray && !isFooter" (click)="onClickShowMore.emit(true)">
        <span i18n="@@showAllReviews">Show all reviews</span> <i class="ri-arrow-right-s-line"></i>
    </div>
</div>