import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrencyService } from '@lib/features/currency/currency.service';
import { LanguageService } from '@lib/features/language/language.service';
import { IClinic } from '@lib/modules/clinic/clinic.models';
import { IClinicSearchResult, IDoctor } from '@lib/modules/search/search.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SwiperOptions } from 'swiper';
import { UserActivityService } from '@lib/features/user-activity/user-activity.service';
import { UserActivities } from '@lib/constants/user-activities';
import { WindowResizeHelper } from '@lib/shared-core/helper/window.resize.helper';
import { ClinicService } from '@lib/modules/clinic/clinic.service';
import { map, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-card-departures',
    templateUrl: './card-departures.component.html',
    styleUrls: ['./card-departures.component.scss']
})
export class CardDeparturesComponent implements OnInit {

    @Input() isSkeletonOnly: boolean = false;
    @Input() clinicIndex!: number;
    @Input() clinicTotal!: number;
    @Input() rankingLocation!: { id: number; type: string; slug: string; name: string; };
    @Input() isClinicSearchResult: boolean = false;
    @Input() clinic: IClinic | undefined | ClinicCard | any;
    @Input() clinicSearchResult: IClinicSearchResult | undefined | any;
    @Input() staticUrl: any;
    @Input() slugUrl: any;
    @Input() currentUser: any;
    @Input() offFavorites: boolean = false;
    @Input() searchQuery!: string;
    @Input() searchLocationId!: number;
    config!: SwiperOptions;

    _favorites: any;
    @Input()
    set favorites(favorites: any) {
        this._favorites = favorites;
    }
    get favorites() {
        return this._favorites;
    }

    isVisibleDoctorModal: boolean = false;
    doctor!: IDoctor;
    doctors!: IDoctor[];
    doctorsLanguages: any[] = [];
    clinicSlugUrl: any = '';
    imageUpdated: boolean = false;
    platform = 'Dental';

    originalLocalSelectedProcedurePrice!: {
        from: number;
        to: number | any;
        currency: string;
    };
    currentCurrency!: string;
    currentCurrencySymbol: any;
    priceValue!: any;
    location: any;
    imagesUrls: any[] = [];
    isFavorite: boolean = false;
    isLoggedIn: boolean = false;
    favoriteData: any;
    clinicData: any;
    clinicModalViews = CardModalViews;
    isModalVisible: boolean = false;
    currentModalContent: any = '';
    isMobileNative: boolean = false;

    badgePremiumTitle = $localize`:@@badgePremiumTitle:Dental Departures Premium Clinic`;
    badgePremiumDescription = $localize`:@@badgePremiumDescription:This clinic offers excellent service, quality and offers the best available prices based on patient reviews &amp; feedback`;
    gpcWinner = $localize`:@@gpcWinner:Global Patients' Choice Winner`;

    badgeSponsoredTitle = $localize`:@@badgeSponsporedTitle:Dental Departures Sponsored Clinic`;
    badgeSponsoredDescription = $localize`:@@badgeSponsoredDescription: This is a paid listing. They are ranked based on several factors, including advertiser bid and ad quality.`;

    clinicInfoToolTip = `<b><h3 class="tip__header_tooltip" >${this.badgePremiumTitle}</h3></b> <br/> ` +
      `<span class="card-wrapper-tooltip"><p>${this.badgePremiumDescription}</p></span>`;

    globalChoiceToolTip = `<div>${this.gpcWinner}</div>`;

    constructor(
        private currencyService: CurrencyService,
        private router: Router,
        private languageSvc: LanguageService,
        private windowResizeHelper: WindowResizeHelper,
        private clinicService: ClinicService,
        private userActivityService: UserActivityService,
        @Inject('env') public env: any) {
    }


    ngOnInit(): void {
        if (this.env) { this.platform = this.env.site_id == 4 ? 'Medical' : this.platform; };
        if (this.isSkeletonOnly) return;
        if (this.clinicSearchResult) {
            const clinicData: any | IClinic | IClinicSearchResult = this.isClinicSearchResult ? this.clinicSearchResult : this.clinic;

            if (clinicData && clinicData.photos) {
                this.imagesUrls = [...[], ...clinicData.photos];
            }
            this.imagesUrls = this.imagesUrls.map((data) => {
                return this.staticUrl + data;
            });
            if (this.imagesUrls && this.imagesUrls.length > 5) {
                this.imagesUrls = this.imagesUrls;
            }

            if (clinicData.favorite_id) {
                this.isFavorite = true;
            }

            const [ i18n ] = clinicData.i18n;
            const { doctor } = clinicData;
            const { doctors } = clinicData;
            const { languages } = this.languageSvc;

            if (this.checkHasDoctor(doctor)) {
                this.doctor = doctor;
                const { languages_spoken } = this.doctor;
                if (languages_spoken && !Array.isArray(languages_spoken)) {
                    const languagesTemp = languages_spoken.split(',');
                    this.doctorsLanguages = languagesTemp;
                }
            }

            if (doctors && doctors.length) {
                this.doctors = doctors;
                this.doctors.map((doctor: IDoctor) => {
                    const { languages_spoken } = doctor;
                    if (languages_spoken && !Array.isArray(languages_spoken)) {
                        const languagesTemp = languages_spoken.split(',');
                        doctor.languages_spoken = languagesTemp;
                    }
                    return doctor;
                });
                let cloneDoctors = JSON.parse(JSON.stringify(this.doctors));
                const [ leadDoctor ] = cloneDoctors.filter((data: any)=> data && data.ID == doctor.ID || data.name == doctor.name);
                this.doctors = [leadDoctor].concat(this.doctors.filter((data: any)=> !(data && data.ID == doctor.ID || data.name == doctor.name)));
            }

            if (i18n) {
                const cityName =  i18n.location && i18n.location.city && i18n.location.city.name ? i18n.location.city.name + ', ' : '';
                const countryName =  i18n.location && i18n.location.country && i18n.location.country.name ? i18n.location.country.name : '';
                this.location =  cityName + countryName;
            }


            if (this.clinicSearchResult) {
                const { procedure } = this.clinicSearchResult;
                if (procedure) {
                    let currencyNewLogic = this.clinicSearchResult?.currency;
                    if (!currencyNewLogic) {
                        const currency =  i18n.currency;
                        this.clinicSearchResult.currency = currency;
                        currencyNewLogic = currency;
                    } else {
                        this.clinicSearchResult.currency = currencyNewLogic;
                    }
                    this.originalLocalSelectedProcedurePrice = {
                        from: procedure.price,
                        to: null,
                        currency: currencyNewLogic
                    }
                    this.clinicSearchResult.selectedProcedure = {
                        currency: currencyNewLogic,
                        name: procedure.name,
                        from: procedure.price
                    }
                }
                this.currencyService.currentCurrency
                    .subscribe(currency => {
                        if (this.clinicSearchResult.selectedProcedure) {
                            if (!this.currentCurrency) {
                                this.currentCurrency = currency === '£€$' ? this.clinicSearchResult.currency : currency;
                                if (this.currentCurrency !== this.clinicSearchResult.currency) {
                                    this.clinicSearchResult.selectedProcedure.from = this.currencyService.exchange(
                                        this.clinicSearchResult.selectedProcedure.from,
                                        this.clinicSearchResult.currency,
                                        this.currentCurrency);

                                    this.clinicSearchResult.selectedProcedure.to = this.currencyService.exchange(
                                        this.clinicSearchResult.selectedProcedure.to,
                                        this.clinicSearchResult.currency,
                                        this.currentCurrency);
                                }
                            } else {
                                if (currency === '£€$') {
                                    this.clinicSearchResult.selectedProcedure.from = this.originalLocalSelectedProcedurePrice.from;
                                    this.clinicSearchResult.selectedProcedure.to = this.originalLocalSelectedProcedurePrice.to;
                                    this.currentCurrency = this.originalLocalSelectedProcedurePrice.currency;
                                } else {
                                    this.clinicSearchResult.selectedProcedure.from = this.currencyService.exchange(
                                        this.originalLocalSelectedProcedurePrice.from,
                                        this.originalLocalSelectedProcedurePrice.currency,
                                        currency);
                                    this.clinicSearchResult.selectedProcedure.to = this.currencyService.exchange(
                                        this.originalLocalSelectedProcedurePrice.to,
                                        this.originalLocalSelectedProcedurePrice.currency,
                                        currency);
                                    this.currentCurrency = currency;
                                }
                            }
                        }
                    });
            }

            this.clinicData = {...{}, ...clinicData};
            this.doctors = clinicData.doctors;
            this.isMobileNative = this.windowResizeHelper.isMobileNative();
        }
        if (this.clinic) {
            const { pricing } = this.clinic;
            const { procedure } = this.clinic;
            if (pricing) {

            this.originalLocalSelectedProcedurePrice = {
                from: procedure ? procedure.price : 0,
                to: null,
                currency: 'USD'
            };
            if (this.clinic.favorite_id) {
                this.isFavorite = true;
            }
            if (this.clinic && this.clinic.photos) {
                this.imagesUrls = [...[], ...this.clinic.photos];
            }
            this.imagesUrls = this.imagesUrls.map((data) => {
                return this.staticUrl + data;
            });
            if (this.imagesUrls && this.imagesUrls.length > 5) {
                this.imagesUrls = this.imagesUrls.slice(0, 5);
            }
            const [i18n] = this.clinic.i18n;
            const { doctor } = this.clinic;
            const { languages } = this.languageSvc;

            if (this.checkHasDoctor(doctor)) {
                this.doctor = doctor;
                const { languages_spoken } = this.doctor;
                if (languages_spoken) {
                    const languagesTemp = languages_spoken.split(',');
                    if (languagesTemp) {
                        languages.forEach((data) => {
                            if (languagesTemp.includes(data.code) || languagesTemp.includes(data.tempCode)) {
                                this.doctorsLanguages.push(data.value)
                            }
                        });
                    }
                }
            }

            if (i18n) {
                const { city, country } = i18n.location;
                this.location = ( city ? city.name : ' ') + ', ' + ( country ? country.name : ' ');
            }
            this.currencyService.currentCurrency.pipe(untilDestroyed(this))
                .subscribe(currency => {
                    this.priceValue = this.currencyService.exchange(
                        this.originalLocalSelectedProcedurePrice.from,
                        this.originalLocalSelectedProcedurePrice.currency, currency);
                    this.currentCurrency = currency;
                    this.currentCurrencySymbol = this.currencyService.findSymbol(currency);
                });
            }
        }

        this.clinicSlugUrl = this.isClinicSearchResult ? this.clinicSearchResult.slug : this.clinic.slug;
    }

    mockDoctor(): any {
        return { "img": "doctors/md_202206020102_drchatchai-kunavisarut.jpg", "name": "Assist. Prof. Dr.Chatchai Kunavisarut DDS., MSc.,", "title": null, "memberships": [{ "name": "The Dental Council of Thailand ", "type": "Association" }, { "name": "DDS., Prince of Songkhla University", "type": "Dentist/Doctor" }, { "name": "MSc. Prosthetic Dentistry, University of North Carolina, USA", "type": "Dentist/Doctor" }, { "name": "Certificate in Clinical Dentistry (Prosthodontics), University of North Carolina, USA", "type": "Dentist/Doctor" }, { "name": "Certificate in Clinical Dentistry (Implantology), University of Florida, USA", "type": "Dentist/Doctor" }, { "name": "Certificate in Clinical Dentistry (in Oral and Maxillofacial Surgery), University of Florida, USA", "type": "Dentist/Doctor" }], "languages_spoken": "eng,tha", "started_practice": 0 };
    }

    onClickNavigate($event?: any) {
        setTimeout(() => {
            this.router.navigate(['/' +(this.slugUrl ? this.slugUrl + this.clinicSlugUrl : 'clinic/' + this.clinicSlugUrl)]);
            this.onClickSponsoredAd();
        }, 300);
    }

    checkHasDoctor(doctor: IDoctor) {
        return doctor && doctor.name
    }

    activiateSlider() {
        if (this.isVisibleDoctorModal) {
            this.config = this.swiperConfig();
        }
    }

    swiperConfig(): any {
        return {
          updateOnWindowResize: true,
          direction: 'horizontal',
          slidesPerView: 1,
          keyboard: false,
          mousewheel: false,
          scrollbar: false,
          navigation: true,
          pagination: false
        };
    }

    updateImageUrls(event: any) {
        if (!this.imageUpdated) {
            const slugUrl = this.clinicSlugUrl;
            this.clinicService.getOtherImages(slugUrl).pipe(untilDestroyed(this),
            take(1), map((data) =>  data.map((res: any) =>  res.image)
            )).subscribe((data) =>{
                if (data) {
                    const otherImages = data.slice(5, data.length);
                    this.imagesUrls = [...[], ...this.imagesUrls, ...otherImages];
                }
                this.imageUpdated = true;
            });
        }
    }

    onClickSponsoredAd() {
      const clinicDetails = this.isClinicSearchResult ? this.clinicSearchResult : this.clinic;
      if (clinicDetails.type === 'SPONSORED') {
        this.trackSponsoredAd(clinicDetails);
      }
    }

    private trackSponsoredAd(clinicDetails: IClinic) {
      this.userActivityService.IPInfo.subscribe( res => {
        if (res) {
          this.userActivityService.logSponsoredAdActivity(clinicDetails.id, UserActivities.SponsoredAdAction.Click, this.searchLocationId);
        }
      });
    }

    bookClinic(clinic: any) {
        this.router.navigate(['/book/dentist/', clinic.slug]);
    }

    openModal(type: any) {
        this.isModalVisible = true;
        this.currentModalContent = type;
    }

    onToggleVisible(isHidden?: boolean) {
        if (this.currentModalContent && this.currentModalContent == this.clinicModalViews.contactClinic) {
            const [chatFloat] = document.getElementsByClassName('zsiq_floatmain') as any;
            if (chatFloat) {
                if (isHidden) {
                    chatFloat.classList.remove('zsiq_floatmain-hidden');
                } else
                    chatFloat.classList.add('zsiq_floatmain-hidden');
            }
        }
    }

    async navigatorShare(clinicData?: any) {
        const shareOptions = {
            title: `${clinicData.name}`,
            url: window.location.href
        };
        if (navigator.share) {
            try {
                navigator.share(shareOptions);
            } catch (err) {
                console.log('Error: ', err);
            }
        } else {
            console.warn('Native Web Sharing not supported');
        }
    }
}


export interface ClinicCard extends IClinic {
    rating: {
        reviews: number;
        star_rating: number;
    };
}

export interface ClinicStats {
    clinicIndex?: any;
    clinicTotal?: any;
    rankingLocation?: any;
}


export enum CardModalViews {

    contactClinic = 'contact-clinic',
    share = 'share'
}
