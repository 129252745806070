import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IPartnerSignUp } from '@lib/models/partner.model';

export declare type PartnerSignUpSubmitStatus = 'none' | 'loading' | 'failed' | 'success';
@Injectable({
  providedIn: 'root'
})
export class BroadcastePartnerSignupService {

  partnerSignupValue: BehaviorSubject<IPartnerSignUp> = new BehaviorSubject<IPartnerSignUp>({} as any);

  partnerSignup$: Observable<IPartnerSignUp> = this.partnerSignupValue.asObservable();

  
  partnerSignUpSubmit: BehaviorSubject<any> = new BehaviorSubject<PartnerSignUpSubmitStatus>('none');
  partnerSignUpSubmit$: Observable<PartnerSignUpSubmitStatus> = this.partnerSignUpSubmit.asObservable();

  constructor() { }

  setPartnerSignupValue(value: IPartnerSignUp) {
    this.partnerSignupValue.next(value);
  }

  /**
   * 
   * @param status 'none' | 'loading' | 'failed' | 'success'
   */
  setPartnerSignUpSubmitValue(status: PartnerSignUpSubmitStatus) {
    this.partnerSignUpSubmit.next(status);
  }

}
