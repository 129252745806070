import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appCustomSeoMenubarPatch]'
})
export class CustomSeoMenubarPatchDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.modifyMenubarButton();
  }

  private modifyMenubarButton() {
    const menubarButton = this.el.nativeElement;
    if(menubarButton) {
      setTimeout(()=> {
        const menuButton: any = menubarButton.querySelectorAll('.p-menubar-button'); 
        if(menuButton) {
          menuButton.forEach((e:any) => {
            this.renderer.setAttribute(e, 'href', '/#');
          });
        }
      });
    }
  }
}