import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Carousel } from 'primeng/carousel';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  type: 'photo' | any = '';
  items: any[] = [];
  private _dataCarousel!: CarouselModel;
  currentIndex: number = 0;
  clinicName: string = "";

  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: false
  };

  disabled: boolean = false;
  show: boolean = true;

  @Input()
  customWrapper: string = 'card';

  @Input()
  showIndicators: boolean = false;

  @Input()
  showNavigators: boolean = false;

  @Input()
  autoPlayInterval: number | any = null;

  @Input()
  hasTotalSlide: boolean = false;

  @Input()
  primaryImage: any = '';

  @Input()
  set dataCarousel(carouselData: CarouselModel) {
    if (carouselData) {
      this._dataCarousel = carouselData;
      const { type, data, clinicName } = carouselData;
      this.type = type;
      this.items = [];
      this.items = [...[], ...data];
      const hasImageWidth = carouselData && carouselData.customWidth ? '?width=' + carouselData.customWidth : '';
      if (this.items) {
        this.items = this.items.map((data: any, index) => {
          if(typeof data === 'object' && 'image' in data) {
            return {
              index: index,
              image: data.image + hasImageWidth,
              clinicName: clinicName
            }
          } else
          return {
            index: index,
            image: data + hasImageWidth,
            clinicName: clinicName
          }
        })
      };
      setTimeout(()=>{
        this.config.slidesPerView = 1;
        this.config.pagination = {
          type: 'bullets',
          el: '.swiper-pagination',
          dynamicBullets: true,
          dynamicMainBullets: 5,
          renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
          },
        };
      } );
    }
  }
  get dataCarousel(): CarouselModel {
    return this._dataCarousel;
  }

  @Output()
  onClickEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onSwipeOrNext: EventEmitter<any> = new EventEmitter<any>();


  responsiveOptions: any[] = [];
  constructor() { }

  ngOnInit(): void {
    Carousel.prototype.onTouchMove = () => { };
    this.config.pagination = !this.hasTotalSlide;
  }


  mapDataToGallery() {

  }

  alerts($event: any) {
    alert($event)
  }

  public toggleKeyboardControl(): void {
    this.config.keyboard = !this.config.keyboard;
  }

  public toggleMouseWheelControl(): void {
    this.config.mousewheel = !this.config.mousewheel;
  }

  public onIndexChange(index: number): void {
    this.currentIndex = index;
    this.onSwipeOrNext.emit(index);
  }
  public onSwiperEvent(event: string): void {}

}

export interface CarouselModel {
  type: 'photo' | any | 'template',
  data: any[],
  customWidth?: any;
  clinicName?: string;
}
