import { Component, Input, OnInit } from '@angular/core';
import { ICardListItem } from './card-list.model';

@Component({
  selector: 'app-card-list-wrapper',
  templateUrl: './card-list-wrapper.component.html',
  styleUrls: ['./card-list-wrapper.component.scss']
})
export class CardListWrapperComponent implements OnInit {
  
  constructor() { }

  @Input() items!: ICardListItem[];

  ngOnInit(): void {
  }

}
