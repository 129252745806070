import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IHome } from '../../../../../core/src/lib/modules/home/home.models';
import { UserActivityService } from '@lib/features/user-activity/user-activity.service';
import { UserActivities } from '@lib/constants/user-activities';
import { NEW_LOCATIONS } from '../../constants/locations.constant';
import { STATIC_URL } from '../../constants/static.constant';
import { CommonService } from '@lib/common/common.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WhyDeparturesData } from '@lib/shared-core/layouts/why-departures/why-departures.component';
import { PageService } from '@lib/features/page/page.service';

@UntilDestroy()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy {

  home!: IHome;
  subscriptionHomeApi!: Subscription;
  whyInfo: WhyDeparturesData[] = [];
  whyTitle: any = $localize`:@@whyUs:Why Medical Departures` + `?`;

  readonly pressList = [
    {
      photo: 'https://static.medicaldepartures.com/press/dd_201604030028_523b9864e5fef-thumbnail.png',
      link: 'http://www.usatoday.com/story/travel/hotels/2013/09/16/hotels-corporate-rates/2821597/',
      pressName: 'USA Today'
    },
    {
      photo: 'https://static.medicaldepartures.com/press/dd_201604030028_525df289610a2-thumbnail.png',
      link: 'http://www.businessweek.com/articles/2013-10-14/run-your-business-from-overseas-instead-of-the-local-echo-chamber',
      pressName: 'Business Week'
    },
    {
      photo: 'https://static.medicaldepartures.com/press/dd_201604030028_54b4c8089694f-thumbnail.png',
      link: 'http://www.huffingtonpost.com/jen-consalvo/get-involved-in-your-star_b_5901064.html',
      pressName: 'Huffington Post'
    },
    {
      photo: 'https://static.medicaldepartures.com/press/dd_201604030028_56cd4d4737813-thumbnail.jpg',
      link: 'http://www.entrepreneur.com/article/250704',
      pressName: 'Entrepreneur'
    },
    {
      photo: 'https://static.medicaldepartures.com/press/dd_201604030028_56f8b45216f4f-thumbnail.jpg',
      link: 'http://finance.yahoo.com/news/medical-departures-secures-series-investment-032600158.html',
      pressName: 'Yahoo Finance'
    },
    {
      photo: 'https://static.medicaldepartures.com/press/dd_201604030028_56fb41e4123c2-thumbnail.jpg',
      link: 'http://www.msn.com/en-us/money/topstories/dental-departures-recognizes-the-worlds-best-dental-clinics-with-2016-global-patients-choice-awards/ar-BBqbzAp',
      pressName: 'MSN'
    }
  ];

  readonly popularLocations: any[] = [
    ...NEW_LOCATIONS
  ];

  readonly staticUrl = STATIC_URL;

  constructor(
    private userActivityService: UserActivityService,
    public commonService: CommonService,
    private pageService: PageService
  ) {
    userActivityService.IPInfo.subscribe(res => {
      if (res) {
        this.userActivityService.logUserActivity(UserActivities.HomePage.Name, UserActivities.HomePage.Landed);
      }
    });
  }

  ngOnInit() {
    const _title =  $localize`:@@homeGenericMetaTitle:` ;
    const _description = $localize`:@@homeGenericMetaDescription:`;

    this.pageService.updateCurrentPage();
    this.pageService.setTitle(_title);
    this.pageService.setDescription(_description);
    this.pageService.setOG(_title, _description, '');
    this.pageService.setBreadcrumb([]);

    this.commonService.common$.pipe((untilDestroyed(this))).subscribe((data)=> {
      const common: any = data;
      if (common) {
        this.whyInfo = [{
          title: $localize`:@@awesomeSaving:Awesome Savings.`,
          info: $localize`:@@awesomeSavingDescription:Save up to 70% compared to treatment at home.`,
          icon: 'fa fa-piggy-bank'
        },
        {
          title: $localize`:@@lowestPrices:Get the lowest prices.`,
          info: $localize`:@@lowestPricesDescription:Best price guaranteed.`,
          icon: 'ri-bank-card-line',
          link: '/guarantee'
        },
        {
          title: $localize`:@@maximumSelection:Maximum Selection.`,
          info: `Browse ` + common.stats.clinics + ` clinics in ` + common.stats.countries + ` countries`,
          icon: 'ri-award-line'
        },
        {
          title: $localize`:@@yourCurrency:Shop in your currency.`,
          info: $localize`:@@yourCurrencyDescription:Browse for the cost of treatment in your currency.`,
          icon: 'fa fa-money-bill'
        }];
      }
    });
  }

  ngOnDestroy() {
    if (this.subscriptionHomeApi) {
      this.subscriptionHomeApi.unsubscribe();
    }
  }
}
