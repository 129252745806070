<div class="clinic-preview-card" *ngIf="clinic">
    <div class="row">
        <div class="col img-col">
            <div class="img-wrapper" [ngStyle]="{'background-image':' url(' + imageUrl + ')'}">
            </div>
        </div>
        <div class="col-8 title-col" style="position: relative;">
            <div class="title">
                {{ clinic.name }}
            </div>
            <div class="location">
                {{location}}
            </div>
            <div class="rating" *ngIf="clinic.reviews">
                <i class="fa fa-star"></i> <span class="rating-average">{{clinic.reviews.rating | number:
                    '1.2-2'}}</span> &nbsp; &nbsp;
                <span *ngIf="clinic.clinic_type ==='premium' ">
                    <svg width="8" height="17" viewBox="0 0 8 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Icon" clip-path="url(#clip0_4491_16833)">
                            <path id="Vector"
                                d="M6.78697 6.37502C6.90975 6.28058 6.97114 6.17196 6.97114 6.04919V3.88169C6.97114 3.84391 6.95225 3.81085 6.91447 3.78252C6.8767 3.75419 6.8342 3.74002 6.78697 3.74002H0.893639C0.846417 3.74002 0.803917 3.75419 0.766139 3.78252C0.728362 3.81085 0.709473 3.84391 0.709473 3.88169V6.04919C0.709473 6.17196 0.770862 6.28058 0.893639 6.37502L3.52864 8.59919C3.62308 8.65585 3.72697 8.68419 3.84031 8.68419C3.96308 8.68419 4.06697 8.65585 4.15197 8.59919L6.78697 6.37502ZM3.84031 14.1667C3.40586 14.1667 3.00683 14.0604 2.64322 13.8479C2.27961 13.6354 1.99156 13.3474 1.77906 12.9838C1.56656 12.6202 1.46031 12.2211 1.46031 11.7867C1.46031 11.3522 1.56656 10.9532 1.77906 10.5896C1.99156 10.226 2.27961 9.93558 2.64322 9.71835C3.00683 9.50113 3.40586 9.39252 3.84031 9.39252C4.27475 9.39252 4.67378 9.50113 5.03739 9.71835C5.401 9.93558 5.68906 10.226 5.90156 10.5896C6.11406 10.9532 6.22031 11.3522 6.22031 11.7867C6.22031 12.2211 6.11406 12.6202 5.90156 12.9838C5.68906 13.3474 5.401 13.6354 5.03739 13.8479C4.67378 14.0604 4.27475 14.1667 3.84031 14.1667Z"
                                fill="#222222" />
                        </g>
                        <defs>
                            <clipPath id="clip0_4491_16833">
                                <rect width="6.90737" height="17" fill="white" transform="matrix(1 0 0 -1 0.669922 17)" />
                            </clipPath>
                        </defs>
                    </svg>
                    <ng-container i18n="@@premium">Premium</ng-container>
                </span>
            </div>
        </div>
    </div>
</div>
