import {Inject, Injectable, isDevMode} from '@angular/core';
import {MedidApiService} from '@lib/data/medid-api.service.ts';
import {UserActivity, SponsoredAdActivity} from '@lib/models/user-activity';
import {LogService} from '@lib/features/log/log.service';
import {IpService} from '@lib/features/geolocation/ip.service';
import {IIpApiIpInfo} from '@lib/features/geolocation/ip.models';
import { BehaviorSubject, Subscription } from 'rxjs';
import {AuthService} from '@lib/auth/auth.service';
import { ApiService } from '@lib/data/core-api.service';
import { BrowserEventsService } from '@lib/features/browser-events/browser-events.service';
import { first } from 'rxjs/operators';
import { ClinicTrackAction } from '@lib/constants/user-activities';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {
  private readonly siteId: number;
  public IPInfo: BehaviorSubject<IIpApiIpInfo> = new BehaviorSubject<IIpApiIpInfo>(null as any);
  isSuccessful$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly siteTag: string;

  constructor(
    @Inject('env') private env: any,
    private medidService: MedidApiService,
    private apiService: ApiService,
    private logService: LogService,
    private ipService: IpService,
    private authService: AuthService,
    private browserEventsService: BrowserEventsService
  ) {
    this.checkIfBrowserGetIP();

    if (!env) {
      this.logService.error('Missing env injecttoken');
    }
    this.siteId = env.site_id;
    this.siteTag = this.siteId === 1 ? 'DD' : this.siteId === 4 ? 'MD' : '';

  }

  checkIfBrowserGetIP() {
    this.browserEventsService.watchBrowserEvents()
      .pipe(first((res) => res))
      .subscribe( value => {
      if (value) {
        this.ipService.getFromIpApi().subscribe( res => {
          this.IPInfo.next(res);
        });
      }
    });

  }

  /**
   * @param activePage the page in context.
   * @param activeActivity action the user performs.
   */
  logUserActivity(activePage: string, activeActivity: string, loginType?: any, otherLog?: OtherLog) {
    let uniqueVisitorId = localStorage.getItem('uniqueVisitorId');
    if (!uniqueVisitorId) {
      uniqueVisitorId = this.generateRandomAlphanumericString();
      localStorage.setItem('uniqueVisitorId', uniqueVisitorId);
    }
    const payload: UserActivity = this.getActivityPayload(uniqueVisitorId, activePage, activeActivity, loginType, otherLog);

    if (!isDevMode()) {
      this.isSuccessful$.next(false);
      this.medidService.userActivity(payload).subscribe( res => {
        this.isSuccessful$.next(true);
        this.logService.log('Post User Activity');
      });
    }

  }

  logSponsoredAdActivity(clinicId: number, action: string, locationId: number) {
    let uniqueVisitorId = localStorage.getItem('uniqueVisitorId');
    if (!uniqueVisitorId) {
      uniqueVisitorId = this.generateRandomAlphanumericString();
      localStorage.setItem('uniqueVisitorId', uniqueVisitorId);
    }
    const payload: SponsoredAdActivity = this.getSponsoredAdPayload(clinicId, locationId);

    if (!isDevMode()) {
      this.isSuccessful$.next(false);
      this.apiService.sponsoredAdsTracking(payload, action).subscribe( res => {
        this.isSuccessful$.next(true);
        this.logService.log('Post Sponsored Ad Tracking');
      });
    }
  }

  private getActivityPayload(uniqueVisitorId: string, activePage: string, activeActivity: string, loginType? : any, otherLog?: OtherLog): UserActivity {
    const withLoginActivity = loginType ? ' ' + loginType : '';
    const logItem: UserActivity =  {
      email: this.authService.currentUserValue?.email,
      activity: {
        visitorId: uniqueVisitorId,
        site: this.siteId.toString()!,
        page: `${this.siteTag}: ${activePage}`,
        activity: `${this.siteTag}: ${activeActivity}${withLoginActivity}`,
        navigatorDetails: this.getAppDetails(),
        ipAddress: {
          ip_address: this.IPInfo?.value?.ip,
          city: this.IPInfo?.value?.city,
          city_geoname_id: '',
          country: this.IPInfo?.value?.country_name,
          country_code: this.IPInfo?.value?.country_code,
          country_geoname_id: '',
          longitude: this.IPInfo?.value?.longitude,
          latitude: this.IPInfo?.value?.latitude,
          region: this.IPInfo?.value?.region,
          region_iso_code: '',
          region_geoname_id: '',
          postal_code: this.IPInfo?.value?.postal,
          country_is_eu: this.IPInfo?.value?.in_eu,
          continent: '',
          continent_code: this.IPInfo?.value?.continent_code,
          continent_geoname_id: '',
          security: {
            is_vpn: undefined
          },
          timeStamp: new Date().toISOString()
        },
      },
    };

    if(otherLog) {
      logItem.action = otherLog.action;
      logItem.clinic_id = otherLog.clinic_id;
    }

    return logItem;
  }

  private getSponsoredAdPayload(clinicId: number, locationId: number ): SponsoredAdActivity {
    return  {
      site: this.siteId.toString()!,
      clinicId: clinicId,
      locationId: locationId,
      ip: this.IPInfo?.value?.ip,
      navigator: this.getAppDetails(),
      user: {
        ip_address: this.IPInfo?.value?.ip,
        city: this.IPInfo?.value?.city,
        city_geoname_id: '',
        country: this.IPInfo?.value?.country_name,
        country_code: this.IPInfo?.value?.country_code,
        country_geoname_id: '',
        longitude: this.IPInfo?.value?.longitude,
        latitude: this.IPInfo?.value?.latitude,
        region: this.IPInfo?.value?.region,
        region_iso_code: '',
        region_geoname_id: '',
        postal_code: this.IPInfo?.value?.postal,
        country_is_eu: this.IPInfo?.value?.in_eu,
        continent: '',
        continent_code: this.IPInfo?.value?.continent_code,
        continent_geoname_id: '',
        security: {
          is_vpn: undefined
        },
        timeStamp: new Date().toISOString()
      },
    };
  }

  private getAppDetails(): {appVersion: string, language: string} {
    let appDetails = localStorage.getItem('AppDetails') as any;
    if (!appDetails) {
      appDetails = {
        appVersion: (typeof window !== 'undefined') ? window.navigator.appVersion : '',
        language: (typeof window !== 'undefined') ? window.navigator.language : '',
      };
      localStorage.setItem('AppDetails', JSON.stringify(appDetails));
    } else {
      appDetails = JSON.parse(appDetails);
    }

    return appDetails;
  }

  private generateRandomAlphanumericString(stringLength = 6) {
    const possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';
    for (let i = 0; i < stringLength; i++) {
      randomString += possibleChars.charAt(Math.floor(Math.random() * possibleChars.length));
    }
    const currentDate = new Date();
    return `${randomString}${currentDate.getFullYear()}${currentDate.getDate()}${currentDate.getTime()}`;
  }
}

export interface OtherLog {
  action?: ClinicTrackAction,
  clinic_id?: number | null
}