import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { RecaptchaService } from '@lib/features/recaptcha/recaptcha.service';

@Injectable({
  providedIn: 'root'
})
export class AttachJsScriptsService {
  x = 0;
  private hasLoaderFormScript!: boolean;
  private hasLoaderMainScripts!: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private recaptchaService: RecaptchaService
  ) {}

  loadFormScripts() {
    if (isPlatformBrowser(this.platformId)) {
      if (document.readyState !== 'loading' && !this.hasLoaderFormScript) {

          setTimeout( () => {

            this.recaptchaService.init();
          }, 1000);
          this.hasLoaderFormScript = true;
      }

    }
  }

  triggerScripts(headScripts: Array<IAttachJsScript>, bodyScripts: Array<IAttachJsScript>) {
    if (isPlatformBrowser(this.platformId)) {
        if (document.readyState !== 'loading' && !this.hasLoaderMainScripts) {

        setTimeout( () => {

          if (headScripts.length > 0) {
            this.loadHeadScripts(headScripts);
          }

          if (bodyScripts.length > 0) {
            this.loadBodyScripts(bodyScripts);
          }

        }, 1000);

        this.hasLoaderMainScripts = true;
      }
    }

  }

  loadHeadScripts(headScripts: Array<IAttachJsScript>) {
    const head = document.querySelector('head');
    headScripts.forEach( s => {
      const script = document.createElement('script');
      if (s.hasSrc) {
        script.src = s.src!;
      }
      script.type = s.type;
      if (s.hasId) {
        script.id = s.id!;
      }
      if (s.hasTextContent) {
        script.textContent = s.textContent!;
      }
      if (s.hasCrossOrigin) {
        script.crossOrigin = s.crossOrigin!;
      }
      if (s.hasIntegrity) {
        script.integrity = s.integrity!;
      }
      head?.appendChild(script);
    });
  }

  loadBodyScripts(bodyScripts: Array<IAttachJsScript>) {
    const body = document.querySelector('body');
    bodyScripts.forEach( scpt => {
      const script = document.createElement('script');
      if (scpt.hasSrc) {
        script.src = scpt.src!;
      }
      script.type = scpt.type;
      if (scpt.hasId) {
        script.id = scpt.id!;
      }
      if (scpt.hasTextContent) {
        script.textContent = scpt.textContent!;
      }
      if (scpt.hasCrossOrigin) {
        script.crossOrigin = scpt.crossOrigin!;
      }
      if (scpt.hasIntegrity) {
        script.integrity = scpt.integrity!;
      }
      body?.appendChild(script);
    });
  }

}

export interface IAttachJsScript {
  hasSrc?: boolean;
  src?: string;
  hasId?: boolean;
  id?: string;
  type: string;
  hasTextContent?: boolean;
  textContent?: string;
  hasCrossOrigin?: boolean;
  crossOrigin?: string;
  hasIntegrity?: boolean;
  integrity?: string;
}
