import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SpinnerInterceptor } from '../shared/components/spinner/spinner-interceptor';
import { EnsureHttpsInterceptor } from './ensure-https-interceptor';
import { LocaleInterceptor } from './locale-interceptor';
import { SiteIdInterceptor } from './site-id-interceptor';
import { TransferHttpResponseInterceptor } from './transfer-state-interceptor';
import { AuthInterceptor } from '@lib/auth/auth-interceptor';
import { XForwardedForInterceptor } from './x-forwarded-for-interceptor';

export const httpInterceptorProviders = [
  // { provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: EnsureHttpsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: XForwardedForInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: SiteIdInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TransferHttpResponseInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
