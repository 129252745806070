import { Inject, Injectable, LOCALE_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  constructor(@Inject(LOCALE_ID) public locale: string) { }

  isGlobalSite = (): boolean => (this.locale === 'en' || this.locale === 'en-US');

  getCurrentLocale = (): string => {
    if (this.locale === 'en' || this.locale === 'en-US') {
      return 'en';
    } else {
      return this.locale;
    }
  }

  getCurrentLocaleButNotGlobal = (): string => {
    return this.isGlobalSite() ? '' : this.getCurrentLocale();
  }

  replaceByKey(value: string, toReplace: { [key: string]: any }): string {
    Object.keys(toReplace).forEach(objKey => {
      value = value.split(`**${objKey}**`).join(toReplace[objKey]);
      // TODO: Should not we just use this instead? Why split and join?
      // value = value.replace(`**$${objKey}**`, toReplace[objKey]);
    });
    return value;
  }

  // getValueAndReplace(key: i18nKeys, toReplace: { [key: string]: any }): string {
  //   let value = this.getValue(key);
  //   Object.keys(toReplace).forEach(objKey => {
  //     value = value.split(`**${objKey}**`).join(toReplace[objKey]);
  //     // TODO: Should not we just use this instead? Why split and join?
  //     // value = value.replace(`**$${objKey}**`, toReplace[objKey]);
  //   });
  //   return value;
  // }
}
