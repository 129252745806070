<div class="general-margin">
    <div
        class="row justify-content-center justify-content-md-center justify-content-lg-center justify-content-xl-center my-5">
        <h3 i18n="@@whyUs">Why Medical Departures?</h3>
    </div>
    <div class="row mb-5 mt-3">
        <div class="col">
            <swiper class="swiper-containers" fxFlex="auto" [config]="config" [disabled]="disabled">
                <ng-container *ngFor="let why of whyList">
                    <div class="swiper-slide swiper-options-header">
                        <div fxLayout="column">
                            <div class="card">
                                <div class="card__icon"><i [class]="why.icon"></i></div>
                                <div class="card__label">{{ why.label}}</div>
                                <div class="card__description">{{why.description}}</div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </swiper>
        </div>
    </div>
</div>
