import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '@lib/data/core-api.service';
import { IClinic } from '@lib/modules/clinic/clinic.models';
import { IClinicSearchResult } from '@lib/modules/search/search.models';
import { MessageService } from 'primeng/api';
import { environment } from 'projects/dental/src/environments/environment';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

  @ViewChild('pUpload') pUpload: any;
  uploadedFiles: any[] = [];

  @Input()
  clinic: IClinic | IClinicSearchResult | any;

  @Input()
  isUploading: boolean = false;

  @Input()
  type: 'photo' | 'file' | any = 'photo';

  @Input() clinicId!: number;

  @Input()
  autoUpload: boolean = false;

  @Output() uploadedImagesChange = new EventEmitter<string>();

  @Output() uploadImagePreview = new EventEmitter<any>();

  uploadedImages: string[] = [];
  uploadedImagesPreview: any[] = [];

  hasUploadedSucess: boolean = false;
  hasUploadedFailed: boolean = false;

  constructor(private apiService: ApiService, private messageService: MessageService,
    private readonly sanitizer: DomSanitizer) { }

  ngOnInit() {

  }

  onSelect($event: any) {
    if ($event) {
      this.uploadedFiles = [];
      this.uploadedFiles = [...$event.currentFiles];

      if (!this.uploadedFiles.length) {
        const files = $event.currentFiles;
        if (files && files.length > 0) {
          files.forEach((file: any) => {
            if (file && file.type != 'image/jpeg' ||  file.type != 'image/jpg' || file.type != 'image/png') {
              this.messageService.add({
                key: 'br',
                severity: 'error',
                summary: 'Error',
                detail: 'Incorrect file format.',
                closable: false,
              });
            } else {
              this.messageService.add({
                key: 'br',
                severity: 'error',
                summary: 'Error',
                detail: 'File is bigger than 10mb.',
                closable: false,
              });
            }
          });
        } else {
          this.messageService.add({
            key: 'br',
            severity: 'error',
            summary: 'Error',
            detail: 'File is bigger than 10mb.',
            closable: false,
          });
        }
      } else {
        this.uploadImages(this.uploadedFiles);
      }
    }
  }

  removeFiles() {
    this.pUpload.clear();
    this.uploadedFiles = [];
  }

  removeFile(index: number) {

    if(this.uploadedFiles.length == 1) {
      this.removeFiles();
    }

    if(this.uploadedFiles && this.uploadedFiles.length > 0 ) {
      this.uploadedFiles.splice(index, 1);
    }

    this.uploadImagePreview.emit(this.uploadedFiles);

  }


  uploadImagesFromInput($event: any) {
    this.uploadImages($event.target.files);
  }


  previewBlobImage(uploadFile: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(uploadFile));
  }

  uploadImages(images: any) {
    this.hasUploadedFailed = false;
    this.hasUploadedSucess = false;
    if (this.autoUpload) {
      this.isUploading = true;
    }
    if (images) {
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        if (!/^image\//.test(image.type)) {
          alert($localize`:@@alertImageTypeError:`);
        }
      }

      this.uploadedImagesPreview = images;

      if (this.autoUpload) {
        this.apiService
          .reviewImageUploadPOST(this.clinicId, images)
          .pipe(catchError(val => of(null)))
          .subscribe(res => {
            this.isUploading = false;
            if (res === null) {
              this.hasUploadedFailed = true;
              alert($localize`:@@alertUploadError:`);
              return;
            }

            if (res.files) {
              for (let i = 0; i < res.files.length; i++) {
                this.uploadedImages.push(
                  `${environment.buckets.static}${res.files[i].name}`
                );
                this.uploadedImagesChange.emit(this.uploadedImages.join(','));
              }
              this.hasUploadedSucess = true;
            }
          });
      } else {
        this.uploadImagePreview.emit(images);
      }
    }
  }

  deleteImage(imagePath: string) {
    if (imagePath) {
      const deletingImageIndex = this.uploadedImages.findIndex(
        c => c === imagePath
      );
      if (deletingImageIndex >= 0) {
        this.uploadedImages.splice(deletingImageIndex, 1);
        this.uploadImagePreview.emit(this.uploadedImages.join(','));
      }
    }
  }

}
