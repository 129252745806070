import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MedidApiService } from '@lib/data/medid-api.service.ts';
import {
  DeleteUserFavorite,
  PostUserFavorite,
  PutUserFavorite,
  UserFavoritesCategory
} from '@lib/models/favorite.model';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {
  protected path = 'user-favorite';
  private favorites$: BehaviorSubject<UserFavoritesCategory> = new BehaviorSubject<UserFavoritesCategory>(undefined as any);

  public favoriteLegacyUpdate$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private medidApiService: MedidApiService
  ) { }

  public fetchUserFavorites(): Observable<UserFavoritesCategory> {
    return this.medidApiService.GETFavorites(this.path);
  }

  public postUserFavorites(payload: PostUserFavorite): Observable<any> {
    return this.medidApiService.POSTFavorites(this.path, payload);
  }

  public putUserFavorites(payload: PutUserFavorite): Observable<any> {
    return this.medidApiService.PUTFavorite(this.path, payload);
  }

  public deleteUserFavorites(payload: DeleteUserFavorite): Observable<any> {
    return this.medidApiService.DELETEFavorite(this.path, payload);
  }

  get Favorites(): BehaviorSubject<UserFavoritesCategory> {
    return this.favorites$;
  }

  setFavorites(fav: UserFavoritesCategory) {
    this.favorites$.next(fav);
  }

  setFavoriteLegacyUpdate(isTriggered: boolean ) {
    this.favoriteLegacyUpdate$.next(isTriggered);
  }

  async refreshFavorites() {
    const res = await this.fetchUserFavorites().toPromise();
    if(res) {
      this.setFavorites(res);
    }
  }
}
