
<div class="star__container star-wrapper" *ngIf="editable">
  <i class="fas fa-star star star--1" [ngClass]="{
      selected: this.ratingSelected >= 1,
      hovering: this.ratingHovering >= 1
    }" (mouseenter)="onHover(1)" (mouseleave)="onLeave()" (click)="onClick(1)"></i>
  <i class="fas fa-star star star--2" [ngClass]="{
      selected: this.ratingSelected >= 2,
      hovering: this.ratingHovering >= 2
    }" (mouseenter)="onHover(2)" (mouseleave)="onLeave()" (click)="onClick(2)"></i>
  <i class="fas fa-star star star--3" [ngClass]="{
      selected: this.ratingSelected >= 3,
      hovering: this.ratingHovering >= 3
    }" (mouseenter)="onHover(3)" (mouseleave)="onLeave()" (click)="onClick(3)"></i>
  <i class="fas fa-star star star--4" [ngClass]="{
      selected: this.ratingSelected >= 4,
      hovering: this.ratingHovering >= 4
    }" (mouseenter)="onHover(4)" (mouseleave)="onLeave()" (click)="onClick(4)"></i>
  <i class="fas fa-star star star--5" [ngClass]="{
      selected: this.ratingSelected >= 5,
      hovering: this.ratingHovering >= 5
    }" (mouseenter)="onHover(5)" (mouseleave)="onLeave()" (click)="onClick(5)"></i>
  <span class="star__description">{{ this.starText }}</span>
</div>

<div class="star__container star-wrapper" *ngIf="!editable">
  <i class="fas fa-star star star--1" [ngClass]="{
      selected: this.ratingSelected >= 1,
      hovering: this.ratingHovering >= 1
    }"></i>
  <i class="fas fa-star star star--2" [ngClass]="{
      selected: this.ratingSelected >= 2,
      hovering: this.ratingHovering >= 2
    }"></i>
  <i class="fas fa-star star star--3" [ngClass]="{
      selected: this.ratingSelected >= 3,
      hovering: this.ratingHovering >= 3
    }"></i>
  <i class="fas fa-star star star--4" [ngClass]="{
      selected: this.ratingSelected >= 4,
      hovering: this.ratingHovering >= 4
    }"></i>
  <i class="fas fa-star star star--5" [ngClass]="{
      selected: this.ratingSelected >= 5,
      hovering: this.ratingHovering >= 5
    }"></i>
  <span class="star__description">{{ this.starText }}</span>
</div>
