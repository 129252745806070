import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from './breadcrumb.service';
import { BreadcrumbItem } from './breadcrumb.models';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  breadcrumbItems: BreadcrumbItem[] = [];

  private breadcrumbSubscription!: Subscription;

  constructor(private breadcrumbService: BreadcrumbService) { }

  ngOnInit() {
    this.breadcrumbSubscription = this.breadcrumbService.breadcrumb$.subscribe(c => {
      this.breadcrumbItems = c;
    });
  }

  ngOnDestroy() {
    this.breadcrumbSubscription.unsubscribe();
  }
}
