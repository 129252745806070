import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BreadcrumbItem } from './breadcrumb.models';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private breadcrumbSubject: BehaviorSubject<BreadcrumbItem[]>;
  public breadcrumb$: Observable<BreadcrumbItem[]>;

  constructor() {
    this.breadcrumbSubject = new BehaviorSubject<BreadcrumbItem[]>([]);
    this.breadcrumb$ = this.breadcrumbSubject.asObservable();
  }

  setPageBreadcrumb = (breadcrumbs: BreadcrumbItem[]): void => this.breadcrumbSubject.next(breadcrumbs);

  setStaticSearchBreadcrumb = (searchFolder: string, treatmentName: string, treatmentSlug: string, countryName: string, countrySlug: string, provinceName: string, provinceSlug: string, cityName: string, citySlug: string): BreadcrumbItem[] => {
    const breadcrumb: BreadcrumbItem[] = [];
    if (treatmentName !== 'treatments') {
      breadcrumb.push(new BreadcrumbItem(treatmentName!, `/${searchFolder}/${treatmentSlug}`));
    }

    if (countryName !== '') {
      breadcrumb.push(new BreadcrumbItem(countryName!, `/${searchFolder}/${treatmentSlug}/${countrySlug}`));

      if (provinceName !== '') {
        breadcrumb.push(new BreadcrumbItem(provinceName!, `/${searchFolder}/${treatmentSlug}/${countrySlug}/${provinceSlug}`));

        if (cityName !== '') {
          breadcrumb.push(new BreadcrumbItem(cityName!, `/${searchFolder}/${treatmentSlug}/${countrySlug}/${provinceSlug}/${citySlug}`));
        }
      }
    }
    return breadcrumb;
  }
}
