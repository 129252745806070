import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrencyService } from '@lib/features/currency/currency.service';
import { LogService } from '@lib/features/log/log.service';
import { I18nService } from '@lib/i18n/i18n.service';
import { IClinicPriceItem } from '@lib/modules/clinic/clinic.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'app-price-discounter',
    templateUrl: './price-discounter.component.html',
    styleUrls: ['./price-discounter.component.scss']
})
export class PriceDiscounterComponent implements OnInit {

    @Input() item!: IClinicPriceItem;
    @Input() currency!: string;

    priceDefault: any;
    finalPrice!: string;
    originalPrice!: string;
    currentCurrency!: string;
    showTreatmentAdditionalInfo = true;

    constructor(
        private decimalPipe: DecimalPipe,
        private currencyService: CurrencyService,
        private logService: LogService,
        private i18nService: I18nService) { }

    ngOnInit(): void {
        this.currencyService.currentCurrency.pipe(untilDestroyed(this)).subscribe(
            currency => {
                this.currentCurrency = currency;
                if (this.item) {
                    this.finalPrice = this.parsePriceRange(this.item.price.discounted);
                    if (this.finalPrice === '') {
                        this.finalPrice = this.parsePriceRange(this.item.price.listed);
                        this.originalPrice = '';
                    } else {
                        this.originalPrice = this.parsePriceRange(this.item.price.listed);
                    }
                }
            }
        );
        this.priceDefault = this.item.price;
        this.showTreatmentAdditionalInfo = this.i18nService.isGlobalSite() ? true : false;
    }

    private parsePriceRange(value: { from: number; to: number }): any {
        try {
            if (!value) {
                return '';
            }

            const decimalFormat = '1.0-0';
            const locale = 'en';

            let from = value.from;
            let to = value.to;

            if (this.currentCurrency !== this.currency && this.currentCurrency !== this.currencyService.clinicCurrency) {
                from = this.currencyService.exchange(from, this.currency, this.currentCurrency);
                to = this.currencyService.exchange(to, this.currency, this.currentCurrency);
            }

            return (from && to)
                ? `${this.decimalPipe.transform(from, decimalFormat, locale)} - ${this.currentCurrency} ${this.decimalPipe.transform(to, decimalFormat, locale)}`
                : (from && !to)
                    ? this.decimalPipe.transform(from, decimalFormat, locale)
                    : (!from && to)
                        ? this.decimalPipe.transform(to, decimalFormat, locale)
                        : '';
        } catch (err) {
            this.logService.error(`parsePriceRange Error: ${value}`, err);
        }
    }


    getTooltip() {
        return `<div class="tip__box" >
        <div class="tip__header">${$localize`:@@priceTooltip:Price you pay at home`}</div>
        <div class="tip__body">
          ${$localize`:@@priceTooltipDescription:Dental Departures has estimated the price you pay at home by gathering public and private medical data from around the globe. These medical prices are estimates: the exact prices you may pay at the clinic down the street will vary based on your location`}
        </div>
      </div>`;
    }

}
