<div class="search-panel-wrapper">
    <div class="counter" *ngIf="model && model.meta && (!isHomePage || isOtherPage )">
        {{model.meta.total}} clinics
        <ng-container *ngIf="model?.query"> - {{ model.query ?? '' }} </ng-container>
        <ng-container *ngIf="!model.query && model.meta.treatment">  - {{ model.meta.treatment ?? '' }} </ng-container>
    </div>
    <div class="promo-button" *ngIf="showPromoButton">
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6 text-center">
                <app-cta-button></app-cta-button>
                <br />
            </div>

        </div>
    </div>
    <div class="row row-padding">
     <ng-container *ngIf="(isLoading || isSSR || !model && !model?.clinics) && !isLoadMore">
        <div class="col-xs-15 col-sm-15 col-md-15 col-lg-3" *ngFor="let item of [].constructor(10); let i = index">
            <app-card-departures [isSkeletonOnly]="true"></app-card-departures>
        </div>
    </ng-container>
    <ng-container *ngIf="model || isSSR">
        <ng-container *ngFor="let item of model?.clinics; let i = index">
            <div class="col-xs-15 col-sm-15" [ngClass]="item.type === 'SPONSORED' ? 'col-md-12 col-lg-12' : ' col-md-15 col-lg-3' ">
                <app-card-departures [clinicSearchResult]="item" [isClinicSearchResult]="true"
                    [favorites]="favoriteService.Favorites | async"
                    [currentUser]="authService.currentUser | async"
                    [clinicIndex]="(model.searchResult.raw.meta.rows_per_page * (model.searchResult.raw.meta.page -1)) + i + 1"
                    [clinicTotal]="model.searchResult.raw.meta.total"
                    [offFavorites]="isSSR"
                    [rankingLocation]="model.searchResult.raw.res.search_criteria.location && model.searchResult.raw.res?.search_criteria.location?.length > 0 ? model.searchResult.raw.res.search_criteria.location[0] : null"
                    [slugUrl]="name?.toLowerCase() === 'medical' ? 'clinic/' : 'dentist/'"
                    [staticUrl]="staticUrl"
                    [searchQuery]="model.query"
                    [searchLocationId]="model?.searchCriteria?.location[0]?.id"
                    ></app-card-departures>
            </div>
        </ng-container>
    </ng-container>
        <ng-container *ngIf="isLoading && isLoadMore">
            <div class="col-xs-15 col-sm-15 col-md-15 col-lg-3" *ngFor="let item of [].constructor(10); let i = index">
                <app-card-departures [isSkeletonOnly]="true"></app-card-departures>
            </div>
        </ng-container>
        <ng-container *ngIf="isError && !isLoading">
            <div class="col-12">
                <h4 class="text-center">No Results Found</h4>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="isLoadMore && !isMaxPage">
        <div class="text-center load-more">
          <h2><span i18n="@@continueBrowsing">Continue Browsing</span> {{name}} <span i18n="@@clinics">clinics</span></h2>
            <button type="button" i18n="@@showMore"
                    class="p-button-outlined p-button-label-zero" pButton title="Show More"
                (click)="onNextPage()" [disabled]="isLoading">
                Show More
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="isMaxPage">
        <div class="text-center load-more">
          <h2 *ngIf="model?.query"><span i18n="@@noMoreClinicBrowsing">No more clinics to show for</span> {{model.query}} </h2>
          <h2 *ngIf="!model?.query"><span i18n="@@noMoreClinicQueryBrowsing">No more clinics to show</span></h2>
        </div>
    </ng-container>
    <ng-container *ngIf="!isLoadMore">
        <ng-container *ngIf="isLoading">
            <div class="row">
                <div class="col-xs-15 col-sm-15 col-md-15 col-lg-3"
                    *ngFor="let item of [].constructor(10); let i = index">
                    <app-card-departures [isSkeletonOnly]="true"></app-card-departures>
                </div>
            </div>
        </ng-container>
        <div class="row" *ngIf="isLoading && model?.clinics && model?.clinics?.length > 1">
            <div class="col-md-12">
                <section class="search-result-paging" *ngIf="model.clinics?.length > 0">
                    <button type="button" class="p-button-outlined p-button-label-zero" pButton title="Previous"
                        (click)="onPreviousPage()" *ngIf="model.meta.page > 1">
                        <i class="fas fa-angle-left"></i>
                    </button>
                    <span class="search-result-paging__text" i18n="@@paging">Page {{ model.meta.page }} of {{
                        model.pages
                        }}</span>
                    <button type="button" class="p-button-outlined p-button-label-zero" pButton title="Next"
                        (click)="onNextPage()" *ngIf="model.meta.page < model.pages">
                        <i class="fas fa-angle-right"></i>
                    </button>
                </section>
            </div>
        </div>
    </ng-container>
</div>
