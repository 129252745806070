import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-quote',
  templateUrl: './content-quote.component.html',
  styleUrls: ['./content-quote.component.scss']
})
export class ContentQuoteComponent implements OnInit {

  @Input()
  title: any = $localize`:@@appointmentHeader:Schedule Your Appointment`;

  @Input()
  subTitle: any = $localize`:@@appointmentSubHeader:FREE cancellations up to 1 day before your appointment`;

  constructor() { }

  ngOnInit(): void {
  }

}
