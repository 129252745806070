<form (submit)="this.submit()" [formGroup]="this.form">
  <section class="partner-signup step-partner-wrapper">
    <div class="container-main">
      <div class="row desktop-only title-partner-fixed">
        <div class="col-md-6">
          <h1 class="step-title" i18n="@@partnerSignUpStep4Title">Dentist</h1>
        </div>
        <div class="col-md-6"></div>
      </div>
      <div class="row ">
        <div class="col-md-6">
          <h1 class="step-title mobile-only" i18n="@@partnerSignUpStep4Title">Dentist</h1>
        </div>
        <div class="col-md-6">
          <div class="row doctor-step">
            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label i18n="@@doctorQuestion1">What is your primary medical license governing body?</label>
                <input formControlName="license" name="license" placeholder="Enter name of governing body" pInputText>
              </div>
            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label i18n="@@doctorQuestion2">What is your medical license registration number with your governing
                  body?</label>
                <input formControlName="registration" name="registration" pInputText
                  placeholder="Enter registration number">
              </div>
            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label i18n="@@doctorQuestion3">What guarantees do you provide for your medical procedures (i.e. 2 year
                  product/services, 5 year products/services)?</label>
                <input formControlName="guarantees" name="guarantees" pInputText
                  placeholder="Enter guarantees you provide">
              </div>
            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label i18n="@@doctorQuestion5">What malpractice insurance do you carry?</label>
                <input formControlName="insurance" name="insurance" pInputText
                  placeholder="Enter malpractice insurance">
              </div>
            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label i18n="@@doctorQuestion6">What are your {{siteName}} clinic sterilization techniques?</label>
                <input formControlName="sterilization" name="sterilization" pInputText
                  placeholder="Enter sterilization techniques">
              </div>
            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label i18n="@@doctorQuestion4">Does your entire clinic speak English fluently?</label>
                <section class="form-radio">
                  <p-radioButton type="radio" formControlName="english" id="english-1" value="1"></p-radioButton>
                  <label i18n="@@formLabelYes" for="english-1">Yes</label>
                  <p-radioButton type="radio" formControlName="english" name="english" id="english-0"
                    value="0"></p-radioButton>
                  <label i18n="@@formLabelNo" for="english-0">No</label>
                </section>
              </div>
              <br/><br/>
            </div>
            <div class="col-12"> <br/><br/></div>
          </div>
        </div>

      </div>
    </div>
  </section>
  <div class="clinic-partner-footer">
    <div class="container-main">
      <div class="progressBar" *ngIf="this.isLoading">
        <p-progressBar mode="indeterminate" [style]="{ height: '3px' }"></p-progressBar>
    </div>
      <div class="row">
        <div class="col-6">
          <button class="custom-text-btn" (click)="onStepBack()" type="button" i18n="@@formButtonBack">Back</button>
        </div>
        <div class="col-6 text-right">
          <button class="min-151 text-center-button" [disabled]="!this.form.valid || this.isLoading" pButton i18n="@@formButtonNext"
            name="submit_step4" type="submit">Submit</button>
        </div>
      </div>
    </div>
  </div>
</form>