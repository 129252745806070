import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IPartnerSignUp } from '@lib/models/partner.model';
import { ILocation } from '@lib/common/common.models';
import { PartnerService } from '../../../../../../../dental/src/app/modules/partner/partner.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { IpService } from '@lib/features/geolocation/ip.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IIpInfo } from '@lib/features/geolocation/ip.models';
@UntilDestroy()
@Component({
  selector: 'app-signup-form-step2',
  templateUrl: './signup-form-step2.component.html',
  styleUrls: ['../../../components/partner-signup-core/partner-signup-core.component.scss',
    '../signup-form/signup-form.component.scss', './signup-form-step2.component.scss']
})
export class SignupFormStep2Component implements OnInit {
  @Input() signUpForm!: IPartnerSignUp;
  @Input() countriesList: {
    callingCode: string,
    code: string,
    id: number,
    name: string,
    slug: string,
    universalName: string
  }[] = [];
  @Input() certificatesList: any[] = [];

  @Output() stepBack: EventEmitter<number> = new EventEmitter();

  @Output() stepSubmitted: EventEmitter<any> = new EventEmitter();
  SearchCountryField = SearchCountryField;
  selectedCountryISO: CountryISO = CountryISO.UnitedStates;
  preferredCountries: Array<string> = [
    CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.Canada, CountryISO.Australia, CountryISO.France
  ];
  PhoneNumberFormat = PhoneNumberFormat;
  separateDialCode = false;

  form: FormGroup;

  constructor(private fb: FormBuilder, private partnerService: PartnerService, private ipService: IpService) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      address1: ['', [Validators.required, Validators.minLength(5)]],
      address2: ['', Validators.minLength(5)],
      city: ['', Validators.required],
      state_province: ['', Validators.required],
      country_code: ['', Validators.required],
      postal_code: ['', Validators.required],
      web_address: ['', Validators.required],
      email: ['', Validators.email],
      phone: ['', Validators.required],
      year_established: ['', Validators.required],
      certifications: ['', Validators.required],
      same_as_contact: [false]
    });
  }

  ngOnInit() {
    this.ipService.ipGeoInfo$.pipe(untilDestroyed(this)).subscribe((data: IIpInfo | null) => {
      if (data !== null) {
        this.selectedCountryISO = data?.countryCode ? data?.countryCode.toLocaleLowerCase() as any : this.selectedCountryISO;
      }
    });
  }

  fillFromContactToggle() {
    const fields = ['name', 'address1', 'address2', 'city', 'state_province', 'country_code',
      'postal_code', 'phone', 'email'];

    if (this.form.controls.same_as_contact.value) {
      this.form.patchValue({
        name: `${this.signUpForm.contact_first_name} ${this.signUpForm.contact_last_name}`,
        address1 : this.signUpForm.contact_address1,
        address2 : this.signUpForm.contact_address2,
        city: this.signUpForm.contact_city,
        state_province: this.signUpForm.contact_state,
        country_code: this.signUpForm.contact_country,
        postal_code: this.signUpForm.contact_zip,
        phone: this.signUpForm.contact_phone,
        email : this.signUpForm.contact_email
      });
      fields.forEach(field => {
        this.form.get(field)?.markAsDirty();
        this.form.get(field)?.markAsTouched();
        this.form.get(field)?.setErrors(null);
      });
    } else {
      this.form.patchValue({
        name: '',
        address1 : '',
        address2 : '',
        city: '',
        state_province: '',
        country_code: '',
        postal_code: '',
        phone: '',
        email : ''
      });
      fields.forEach(field => {
        this.form.get(field)?.reset();
      });
    }

    this.ipService.ipGeoInfo$.pipe(untilDestroyed(this)).subscribe((data: IIpInfo | null) => {
      if (data !== null) {
        this.selectedCountryISO = data?.countryCode ? data?.countryCode.toLocaleLowerCase() as any : this.selectedCountryISO;
      }
    });
    
  }

  onStepBack() {
    this.stepBack.emit(2);
  }

  submit() {
    Object.keys(this.form.controls).forEach(field => {
      this.form.get(field)?.markAsTouched({ onlySelf: true });
    });

    if (!this.form.valid) {
      return;
    }

    this.form.value.phone = this.form.value.phone?.e164Number
      ? this.form.value.phone?.e164Number : this.form.value.phone;

    this.stepSubmitted.emit({ step_id: 2, form: this.form.value });
  }

  toggleCertificate(value: string) {
    value = value.trim();
    const certificateString = this.form.get('certifications')?.value;
    let certificates = (certificateString) ? certificateString.split(',') : [];

    if (certificates.indexOf(value) === -1) {
      certificates.push(value);
    } else {
      certificates = certificates.filter((row: string) => row !== value);
    }

    this.form.get('certifications')?.setValue(certificates.join(','));
  }
}
