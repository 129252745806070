import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPartnerSignUp } from '@lib/models/partner.model';
import { PartnerService } from '../../../../../../../dental/src/app/modules/partner/partner.service';
import { PARTNERSHIP_FORM_ENUM } from '@lib/shared-core/components/partner-signup-core/partner-signup-core.component';
import { BroadcastePartnerSignupService, PartnerSignUpSubmitStatus } from '@lib/features/page-layout/broadcaste-partner-signup.service';
import { timer } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['../../../components/partner-signup-core/partner-signup-core.component.scss',
    './signup-form.component.scss']
})
export class SignupFormComponent implements OnInit {
  @Input() countriesList: {
    callingCode: string,
    code: string,
    id: number,
    name: string,
    slug: string,
    universalName: string
  }[] = [] ;
  @Input() certificatesList: any[] = [];
  @Input()
  siteName!: string;

  @Output() partnerFormState: EventEmitter<PARTNERSHIP_FORM_ENUM> = new EventEmitter();

  signUpForm!: IPartnerSignUp;


  step = 1;
  isLoading: boolean = false;


  isSubmitted = false;

  stepTitle = 'Contact Information';

  constructor(
    private broadcastePartnerSignupService: BroadcastePartnerSignupService
  ) {
    this.signUpForm = {
      contact_first_name: '',
      contact_last_name: '',
      contact_title: '',
      contact_address1: '',
      contact_address2: '',
      contact_city: '',
      contact_state: '',
      contact_country: -1,
      contact_zip: -1,
      contact_email: '',
      contact_phone: '',
      same_as_contact: 'off',
      name: '',
      address1: '',
      address2: '',
      city: '',
      state_province: '',
      country_code: -1,
      postal_code: -1,
      web_address: '',
      email: '',
      phone: '',
      year_established: new Date().getUTCFullYear(),
      certifications: []
    };
  }

  ngOnInit() {
    this.broadcastePartnerSignupService.setPartnerSignUpSubmitValue('none');
    this.broadcastePartnerSignupService.partnerSignUpSubmit$.subscribe((data: PartnerSignUpSubmitStatus)=> {
        if(data == 'loading') {
          this.isLoading = true;
        }
        else if(data == 'success') {
          this.partnerFormState.emit(PARTNERSHIP_FORM_ENUM.PARTNER_THANK_YOU);
          this.step += 1;
        } else {
          this.isLoading = false;
        }
    })

  }

  onStepSubmitted(data: { step_id: number; form: any }) {
    this.signUpForm = Object.assign(this.signUpForm, data.form);
    if (data.step_id === 4) {
      this.broadcastePartnerSignupService.setPartnerSignUpSubmitValue('loading');
      this.isLoading = true;
      this.submitFinal();
    } else {
      this.step += 1;
    }
    window.scrollTo(0, 0);
  }
  onStepBack = ($event: number) => {  this.step = $event - 1;    window.scrollTo(0, 0); };

  submitFinal() {
    window.scrollTo(0, 0);
    this.broadcastePartnerSignupService.setPartnerSignupValue(this.signUpForm);
  }
}
