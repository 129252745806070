import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyQPipe } from './currency-q.pipe';
import { DashToSpacePipe } from './dash-to-space.pipe';
import { GoogleMapQPipe } from './google-map-q.pipe';
import { PriceTagPipe } from './price-tag.pipe';
import { PricingPipe } from './pricing.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { TruncatePipe } from './truncate.pipe';


export const CorePipes = [
  SafeHtmlPipe,
  SafeUrlPipe,
  CurrencyQPipe,
  GoogleMapQPipe,
  PriceTagPipe,
  TruncatePipe,
  DashToSpacePipe,
  PricingPipe
];

/**
 * This is a clone common pipe from the ShareModule.
 * A copy to ensure that the exisitng will not be affected if there
 * are new changes on the new logic based on the new Design
 */
@NgModule({
  declarations: [
    ...CorePipes
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ...CorePipes
  ]
})
export class CorePipesModule { }
