<div class="map-wrapper" *ngIf="latlong">
    <h3>{{title}}</h3>
    <iframe
      width="100%"
      height="483.14"
      frameborder="0"
      style="border:0"
      [src]="this.mapSrc | safeUrl"
      allowfullscreen
    >
    </iframe>
    <br/>
</div>
  