<div class="container page-container">
  <app-breadcrumb></app-breadcrumb>
</div>
<div class="container page-container">
  <div class="content-container">
    <h1 class="page-title" i18n="@@pageNotFoundTitle">Oh no!</h1>
    <h2 i18n="@@pageNotFoundDescription">Sorry, the page you were looking for cannot be found</h2>
    <p i18n="@@pageNotFoundLinkMD">
      Visit <a routerLink="/">www.medicaldepartures.com</a> and you'll find what you are
      looking for.
    </p>
    <p i18n="@@pageNotFoundContactMD">
      If you are still having problems please contact us at
      <a href="mailto:care@medicaldepartures.com">care@medicaldepartures.com</a>
    </p>
  </div>
</div>
