import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-featured-on',
  templateUrl: './featured-on.component.html',
  styleUrls: ['./featured-on.component.scss']
})
export class FeaturedOnComponent implements OnInit {

  @Input()
  pressList = [];

  config!: SwiperOptions;
  disabled: boolean = false;
 

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.config = this.swiperConfig();
  }

  onClickUrl() {
    this.router.navigate(['/press']);
  }

  swiperConfig(): any {
    return {
      a11y: { enabled: true },
      updateOnWindowResize: true,
      direction: 'horizontal',
      slidesPerView: 4,
      keyboard: false,
      mousewheel: false,
      scrollbar: false,
      navigation: false,
      pagination: false,
      loop: true,
      autoplay: {
        delay: 1000,
       },
      breakpoints: {
        120: {
          slidesPerView: 2.5,
          spaceBetween: 20
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 40
        }
      }
    };
  }

  public toggleKeyboardControl(): void {
    this.config.keyboard = !this.config.keyboard;
  }

  public toggleMouseWheelControl(): void {
    this.config.mousewheel = !this.config.mousewheel;
  }

  public onIndexChange(index: number): void { }
  public onSwiperEvent(event: string): void { }


}
