import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { IClinic } from '@lib/modules/clinic/clinic.models';
import { Clipboard } from '@angular/cdk/clipboard';
import { WindowResizeHelper } from '@lib/shared-core/helper/window.resize.helper';

@Component({
  selector: 'app-share-profile',
  templateUrl: './share-profile.component.html',
  styleUrls: ['./share-profile.component.scss']
})
export class ShareProfileComponent implements OnInit {

  @Input()
  clinic!: IClinic;

  @Input()
  title: any = 'Share this clinic';

  isEmbed: boolean = false;
  isCopied: boolean = false;
  previewClinic: any;
  currentUrl = window.location.href;
  platFormSite = 'Dental';
  hashTags = '#dentalDepartures';

  constructor(private clipboard: Clipboard, @Inject('env') public env: any) { }

  ngOnInit(): void {
    this.isCopied = false;
    this.platFormSite = this.env && this.env.site_id == 4 ? 'Medical' : this.platFormSite;
    this.hashTags = this.env && this.env.site_id == 4 ? '#medicalDepartures' : this.hashTags;
  }

  copyClinicUrl(): void {
    this.isCopied = true;
    this.clipboard.copy(window.location.href);
    setTimeout(()=> {
      this.isCopied = !this.isCopied;
    }, 2000)
  }

  email() {

  }

  message() {

  }

}
