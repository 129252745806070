import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageLayoutService {

  private toggleHeaderSubject: BehaviorSubject<HEADER_DISPLAY_STATE> =
    new BehaviorSubject<HEADER_DISPLAY_STATE>(HEADER_DISPLAY_STATE.SHOW_HEADER);

  toggleHeader$: Observable<HEADER_DISPLAY_STATE> = this.toggleHeaderSubject.asObservable();

  private toggleFooterSubject: BehaviorSubject<FOOTER_DISPLAY_STATE> =
    new BehaviorSubject<FOOTER_DISPLAY_STATE>(FOOTER_DISPLAY_STATE.SHOW_FOOTER);

  toggleFooter$: Observable<FOOTER_DISPLAY_STATE> = this.toggleFooterSubject.asObservable();

  private toggleBookingAppointmentSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  toggleBookingAppointment$: Observable<boolean> = this.toggleBookingAppointmentSubject.asObservable();

  private toggleContactClinicSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  toggleContactClinic$: Observable<boolean> = this.toggleContactClinicSubject.asObservable();

  private toggleHasOtherFooterSubject: Subject<boolean> = new Subject<boolean>();
  toggleHasOtherFooter$: Observable<boolean> = this.toggleHasOtherFooterSubject.asObservable();

  private toggleHasLoadedClinic: Subject<boolean> = new Subject<boolean>();
  toggleHasLoadedClinic$: Observable<boolean> = this.toggleHasLoadedClinic.asObservable();

  constructor() { }

  setToggleHeader(state: HEADER_DISPLAY_STATE) {
    this.toggleHeaderSubject.next(state);
  }

  setToggleFooter(state: FOOTER_DISPLAY_STATE) {
    this.toggleFooterSubject.next(state);
  }

  setToggleBooking(state: boolean) {
    this.toggleBookingAppointmentSubject.next(state);
  }

  setToggleContactClinic(state: boolean) {
    this.toggleContactClinicSubject.next(state);
  }
  
  setHasOtherFooter(state: boolean) {
    this.toggleHasOtherFooterSubject.next(state);
  }

  setHasLoadedClinic(state: boolean) {
    this.toggleHasLoadedClinic.next(state);
  }

}


export type ComponentDisplayState = HEADER_DISPLAY_STATE | FOOTER_DISPLAY_STATE;

export enum HEADER_DISPLAY_STATE {
  SHOW_HEADER = 'showHeader' ,
  HIDE_HEADER = 'hideHeader',
}
export enum FOOTER_DISPLAY_STATE {
  SHOW_FOOTER = 'showFooter',
  HIDE_FOOTER = 'hideFooter',
  SHOW_ONLY_SMALL_FOOTER = 'showOnlySmallFooter'
}
