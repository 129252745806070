import { Component, Inject, OnInit } from '@angular/core';
import { ICommon } from '@lib/common/common.models';
import { CommonService } from '@lib/common/common.service';
import { PageService } from '@lib/features/page/page.service';
import { SwiperOptions } from 'swiper';

interface ReviewCard {
  name: string;
  rate: number;
  star: number;
  // starIcon: Array<string>
  link: string;
}
@Component({
  selector: 'app-how-we-rate',
  templateUrl: './how-we-rate.component.html',
  styleUrls: ['./how-we-rate.component.scss']
})
export class HowWeRateComponent implements OnInit {

  config!: SwiperOptions;
  disabled: boolean = false;
  platFormSite = 'Dental';
  review: ReviewCard[] = [
    { name: 'feefo', rate: 4.6, star: 4, link: 'https://www.feefo.com/en-US/reviews/dental-departures' },
    { name: 'reviewcentre', rate: 4.6, star: 4, link: 'http://www.reviewcentre.com/Dentists/Dental-Departures-www-dentaldepartures-com-reviews_1413026' },
    { name: 'liveChat', rate: 456, star: 4, link: 'https://lc.chat/qa/2175381/1' },
  ];

  reviewMedical: ReviewCard[] = [
    { name: 'feefo', rate: 4.5, star: 4, link: 'https://www.feefo.com/en-US/reviews/medical-departures?displayFeedbackType=SERVICE&timeFrame=YEAR' },
    { name: 'reviewcentre', rate: 3.7, star: 4, link: 'https://www.reviewcentre.com/medical/medical_departures_inc_-_wwwmedicaldeparturescom-reviews_3603145' },
    { name: 'liveChat', rate: 4.2, star: 4, link: 'https://maps.app.goo.gl/cqjMgJCLSaicExZZ9' }
  ];

  common!: ICommon;

  constructor(private commonService: CommonService, private pageService: PageService,
    @Inject('env') public env: any) {
 
  }

  ngOnInit() {
    this.platFormSite = this.env && this.env.site_id == 4 ? 'Medical' : this.platFormSite;
    if( this.env && this.env.site_id == 4 ) {
        this.review = JSON.parse(JSON.stringify(this.reviewMedical));
    }
    this.commonService.common$.subscribe(res => this.common = res!);
    this.pageService.setScript('//cdn.livechatinc.com/qb/qb-2175381-transparent-200-1.js', true);
    this.config = this.swiperConfig();
  }

  swiperConfig(): any {
    return {
      a11y: { enabled: true },
      updateOnWindowResize: true,
      direction: 'horizontal',
      slidesPerView: 4,
      keyboard: false,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: false,
      breakpoints: {
        120: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40
        }
      }
    };
  }

}
