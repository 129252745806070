<div class="book" *ngIf="!isBookExpanded" (click)="onClick.emit(true)">
  <div class="back"></div>
  <div class="front">
    <div class='image-profile' [style.background-image]="'url(' + userImage + '?width=110px&format=webp' + ')'"></div>
  </div>
</div>

<div class="book-expanded-new" [ngClass]="isBookExpandedClass" *ngIf="isBookExpanded"
  [class.hasOtherInfo]="hasOtherInfo">
  <div class="doc-info-thumbnail">
    <div class="row">
      <div class="col-6">
        <div class="doc-image">
          <div class='image-profile' [style.background-image]="'url(' + userImage + '?width=110px&format=webp' + ')'"></div>
          <div class="verified-icon" *ngIf="docInfo.is_verified" [tooltipStyleClass]="'verified-wrapper'"
            [pTooltip]="getToolTip()" [escape]="false">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M14.9334 4.02565L10.757 2.46139C10.3241 2.30193 9.61795 2.30193 9.18513 2.46139L5.00873 4.02565C4.20382 4.32938 3.55078 5.27097 3.55078 6.12903V12.2797C3.55078 12.8948 3.95323 13.7073 4.44681 14.0718L8.62321 17.1927C9.35978 17.747 10.5671 17.747 11.3037 17.1927L15.4801 14.0718C15.9737 13.6997 16.3761 12.8948 16.3761 12.2797V6.12903C16.3837 5.27097 15.7307 4.32938 14.9334 4.02565ZM12.6098 8.27798L9.34459 11.5432C9.23069 11.6571 9.08641 11.7102 8.94214 11.7102C8.79786 11.7102 8.65358 11.6571 8.53968 11.5432L7.32473 10.313C7.10452 10.0928 7.10452 9.72833 7.32473 9.50812C7.54494 9.28791 7.90943 9.28791 8.12964 9.50812L8.94973 10.3282L11.8125 7.46548C12.0327 7.24527 12.3972 7.24527 12.6174 7.46548C12.8376 7.68569 12.8376 8.05777 12.6098 8.27798Z"
                fill="white" />
            </svg>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="doc-info">
          <div class="name">{{docInfo.name}}</div>
          <div class="profession"> <i class="ri-award-fill"></i>{{docInfo.title}}</div>
          <div class="practice" *ngIf="docInfo.started_practice"> <span>{{ ( currentYear - (+docInfo.started_practice) )
              }}</span> Years of Practice
          </div>
          <div class="practice" *ngIf="docInfo.years_of_practicing"> <span>{{ docInfo.years_of_practicing }}</span>
            <span class="years" i18n="@@practicingYears">Years of Practice</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="book-other-info" *ngIf="hasOtherInfo">
  <div class="other-info">
    <div>
      <h5><i class="ri-briefcase-line"></i> &nbsp; <span i18n="@@languageSpoken">Language Spoken</span></h5>
      <ul *ngIf="docInfo">
        <ng-container *ngFor="let docLang of docInfo.languages">
          <li> {{docLang}} </li>
        </ng-container>
      </ul>
    </div>
    <div>
      <h5><i class="ri-briefcase-5-fill"></i> &nbsp; <span i18n="@@associations"> Associations </span></h5>

      <ul *ngIf="docInfo && docInfo.associations && docInfo.associations.length > 0 ">
        <ng-container *ngFor="let association of docInfo.associations">
          <li> {{ association }} </li>
        </ng-container>
      </ul>
    </div>
    <div>
      <h5><i class="ri-graduation-cap-line"></i> &nbsp; <span i18n="@@education"> Education </span></h5>

      <ul *ngIf="docInfo && docInfo.education && docInfo.education.length > 0 ">
        <ng-container *ngFor="let docEd of docInfo.education">
          <li> {{docEd}} </li>
        </ng-container>
      </ul>

    </div>
  </div>
</div>