<div class="p-gallery-wrapper" *ngIf="isVisible && images && images.length">
    <p-dialog [closeOnEscape]="true" [closable]="true"
              [dismissableMask]="true"
              (visibleChange)="isVisibleChange($event)"
              [showHeader]="false" styleClass="gallery-image"
              [(visible)]="isVisible" [modal]="true"
              [style]="{ width: '100%' }"
              [draggable]="false"
              [resizable]="false">
         <div (click)="isVisibleChange(false)" ><i class="ri-close-line"></i> </div>
        <swiper class="swiper-container" fxFlex="auto" [config]="config" >
            <ng-container *ngFor="let item of images">
                <div class="swiper-slide">
                    <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
                           <div class="photo" style="background-image: url({{item.image}});">
                            </div>
                    </div>
                </div>
            </ng-container>
        </swiper>
    </p-dialog>


</div>
