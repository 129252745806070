import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../data/core-api.service';
import { BreadcrumbItem } from '../../shared/components/breadcrumb/breadcrumb.models';
import { ClinicContact, IClinic, IClinicLocation, IClinicPrice, IClinicQuote } from './clinic.models';

@Injectable({
  providedIn: 'root'
})
export class ClinicService {
  constructor(private api: ApiService) { }

  get(id: string): Observable<any> {
    return this.api.clinicGET(id);
  }

  getPreview(id: string): Observable<any> {
    return this.api.clinicPreviewGET(id);
  }

  contactClinic(model: ClinicContact) {
    return this.api.clinicContactPOST(model);
  }

  requestQuote(model: IClinicQuote) {
    return this.api.clinicQuotePOST(model);
  }

  getPagingReviews(id: string, nextLink?: string): Observable<{ reviews: any }> {
    return this.api.clinicReviewsGET(id, nextLink);
  }

  setUsefulness(clinicId: number, model: { review_id: string; vote: string; 'g-recaptcha-response': string; }): Observable<any> {
    return this.api.clinicReviewUsefulnessPOST(clinicId, model);
  }

  getOtherImages(id: string) {
    return this.api.clinicImagesGET(id);
  }

  getOpenHours = (workingHours: any) => {
    const openHours = [];
    for (const dayKey in workingHours) {
      if (workingHours.hasOwnProperty(dayKey)) {
        openHours.push({
          dayOfWeek: dayKey.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase()),
          open: workingHours[dayKey].from,
          close: workingHours[dayKey].to
        });
      }
    }
    return openHours;
  }

  // getOpenHoursAsString = (workingHour: { from: string, to: string }): string => {
  //   let workingHourText = '';

  //   if (workingHour !== undefined) {
  //     if (
  //       workingHour.from !== undefined &&
  //       workingHour.from !== '' &&
  //       (workingHour.to !== undefined && workingHour.to !== '')
  //     ) {
  //       workingHourText = `${workingHour.from} - ${workingHour.to}`;
  //     }
  //   }

  //   return workingHourText;
  // }

  getPriceRangeText = (prices: IClinicPrice[]): string => {
    let high = 0;
    let low = 0;
    prices.filter((c)=> c!=null).map(c => {
     
      c.children.map(p => {
        high = high === 0
          ? p.price.discounted.from
          : p.price.discounted.from > 0 && p.price.discounted.from > high
            ? p.price.discounted.from
            : high;
        low = low === 0
          ? p.price.discounted.from
          : p.price.discounted.from > 0 && p.price.discounted.from < low
            ? p.price.discounted.from
            : low;
      });
    });

    return `${low} - ${high}`;
  }

  getClinicLocation = (location: IClinicLocation): string => {
    return location
      ? location.city && location.city.name !== ''
        ? location.city.name
        : location.province && location.province.name !== ''
          ? location.province.name
          : location.country && location.country.name !== ''
            ? location.country.name
            : ''
      : '';
  }

  getProcedureNames = (prices: IClinicPrice[]): string[] => {
    const procedures: string[] = [];

    if (prices && prices.length > 0) {
      if (prices[0].children && prices[0].children.length > 0) {
        if (prices[0].children[0] && prices[0].children[0].name !== '') {
          procedures.push(prices[0].children[0].name);
        }
        if (prices[0].children[1] && prices[0].children[1].name !== '') {
          procedures.push(prices[0].children[1].name);
        }
        if (prices[0].children[2] && prices[0].children[2].name !== '') {
          procedures.push(prices[0].children[2].name);
        }
      }
    }

    return procedures;
  }

  getNewClinicSlugUrl = (deprecatedSlug: string): Promise<string> => {
    try {
      return new Promise((resolve) => {
        this.api.redirectCheckGET(deprecatedSlug)
          .toPromise()
          .then(
            res => {
              let newSlugUrl = '';

              if (res) {
                if (+res['http_status'] === 301 && res['new_path'].indexOf('/dentist/') > -1) {
                  newSlugUrl = res['new_path'];
                }
              }

              resolve(newSlugUrl);
            }
          ).catch(err => resolve(''));
      });
    } catch (error) { throw error; }
  }

  buildBreadcrumb = (clinic: IClinic, premiumContentFolder: string, clinicFolder: string): BreadcrumbItem[] => {
    const breadcrumb: BreadcrumbItem[] = [];
    if (clinic.location.country) {
      breadcrumb.push(new BreadcrumbItem(
        clinic.location.country.name,
        `/${premiumContentFolder}/treatments/${clinic.location.country.slug}`));
    }

    if (clinic.location.province) {
      breadcrumb.push(new BreadcrumbItem(
        clinic.location.province.name,
        `/${premiumContentFolder}/treatments/${clinic.location.country.slug}/${clinic.location.province.slug}`));
    }

    if (clinic.location.city) {
      breadcrumb.push(new BreadcrumbItem(
        clinic.location.city.name,
        `/${premiumContentFolder}/treatments/${clinic.location.country.slug}/${clinic.location.province.slug}/${clinic.location.city.slug}`));
    }

    breadcrumb.push(new BreadcrumbItem(clinic.name, `/${clinicFolder}/${clinic.slug}`));
    return breadcrumb;
  }

  mapSummaryReport(ratings: any) {
    const { cleanliness, communication, value, quality, overall, service, comfort } = ratings;
    let summmaryReport = [];
    if (cleanliness) {
        summmaryReport.push({
            label: $localize`:@@ratingCleanliness:Cleanliness`,
            value: cleanliness.toFixed(2),
            percentageValue: (cleanliness / 5) * 100,
        });
    }

    if (communication) {
        summmaryReport.push({
            label: $localize`:@@ratingCommunication:Communication`,
            value: communication.toFixed(2),
            percentageValue: (communication / 5) * 100,
        });
    }

    if (value) {
        summmaryReport.push({
            label: $localize`:@@ratingValue:Value`,
            value: value.toFixed(2),
            percentageValue: (value / 5) * 100,
        });
    }

    if (quality) {
        summmaryReport.push({
            label: $localize`:@@ratingQuality:Quality`,
            value: quality.toFixed(2),
            percentageValue: (quality / 5) * 100,
        });
    }

    if (overall) {
        summmaryReport.push({
            label: $localize`:@@ratingOverAll:Overall`,
            value: overall.toFixed(2),
            percentageValue: (overall / 5) * 100,
        });
    }

    if (service) {
        summmaryReport.push({
            label: $localize`:@@ratingService:Service`,
            value: service.toFixed(2),
            percentageValue: (service / 5) * 100,
        });
    }

    if (comfort) {
        summmaryReport.push({
            label: $localize`:@@ratingComfort:Comfort`,
            value: comfort.toFixed(2),
            percentageValue: (comfort / 5) * 100,
        });
    }

    return summmaryReport;

}


}
