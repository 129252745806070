import { Component, Input, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-why-departures',
  templateUrl: './why-departures.component.html',
  styleUrls: ['./why-departures.component.scss']
})
export class WhyDeparturesComponent implements OnInit {

  @Input()
  title: any = $localize`:@@whyUs:Why Medical Departures`;

  @Input()
  subtle: any = '';

  @Input()
  whyInfo: WhyDeparturesData[] = [];

  config!: SwiperOptions;
  disabled: boolean = false;
  constructor() {}

  ngOnInit(): void {
    this.config = this.swiperConfig();
  }

  swiperConfig(): any {
    return {
      a11y: { enabled: true },
      updateOnWindowResize: true,
      direction: 'horizontal',
      slidesPerView: 4,
      keyboard: false,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: false,
      breakpoints: {
        120: {
          slidesPerView: 1.6,
          spaceBetween: 20
        },
        640: {
          slidesPerView: 2.5,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 40
        }
      }
    };
  }

}

export interface WhyDeparturesData {
  title?: any;
  info?: any;
  icon?: any;
  link?: any;
}
