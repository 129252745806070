<div *ngIf="!isInModalMode">
    <ng-container [ngTemplateOutlet]="accountComponentTemplates"></ng-container>
</div>


<ng-template #accountComponentTemplates>
    <div class="close-action-pusher" *ngIf="isInModalMode"></div>
    <ng-container [ngSwitch]="currentAccountState">
        <ng-container *ngSwitchCase="accountActions.REGISTER">
            <app-register (userActivity)="logUserActivity($event)" [isInModalMode]="isInModalMode" ></app-register>
        </ng-container>
        <ng-container *ngSwitchCase="accountActions.FORGOT_PASSWORD">
            <app-forgot-password (userActivity)="logUserActivity($event)" [isInModalMode]="isInModalMode"></app-forgot-password>
        </ng-container>
        <ng-container *ngSwitchCase="accountActions.LOGIN">
            <app-login (userActivity)="logUserActivity($event)" [isInModalMode]="isInModalMode"></app-login>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="currentAccountState !=accountActions.FORGOT_PASSWORD" [ngTemplateOutlet]="socialLogin"></ng-container>
</ng-template>

<ng-template #socialLogin>
    <div class="auth-social-div">
        <div class="separator">
            <div class="row">
                <div class="col-6">
                    <hr />
                </div>
                <div class="col-1"><span i18n="@@or">or</span></div>
                <div class="col-6">
                    <hr />
                </div>
            </div>
        </div>
        <div class="apple-icon" (click)="onClickAppleSignIn()">
            <img src="assets/apple.svg" class="pointer" height="25" alt="Apple">
        </div>
        <div id="google-button"></div>
    </div>
</ng-template>

<ng-container *ngIf="isInModalMode">
    <p-dialog [(visible)]="isModalVisible" [modal]="true" [showHeader]="false" class="custom-account-dialog"
        [position]="'center'">
        <div class="close-action"><i class="ri-close-fill" (click)="close();"></i></div>
        <ng-container [ngTemplateOutlet]="accountComponentTemplates"></ng-container>
    </p-dialog>
</ng-container>
