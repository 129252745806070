<ng-container *ngIf="this.isSubmittedSuccessfully">
    <div class="clinic-review-wrapper text-center" >
        <ng-container>
            <h3 class="title">Your review has been submitted. Thank you for your feedback.</h3><br/>
            <p>
                Additionally, if you have a moment, please tell us how Medical Departures
                did. We would love to hear your feedback!
            </p>
        </ng-container><br/>
        <a href="{{ siteId == 1 ? 'https://g.page/r/Cb6KsGw0UTZdEBM/review' : 'https://maps.app.goo.gl/cqjMgJCLSaicExZZ9'}}" target="_blank" >
            <button pButton i18n="@@formButtonClickHere">Click here</button>
        </a>
    </div>
</ng-container>


<div class="clinic-review-wrapper" *ngIf="this.clinicName && !this.isSubmittedSuccessfully">
    <h1 class="title" i18n="@@reviewTitle">{{ this.clinicName }} Review Form</h1>
    <p class="description" i18n="@@reviewDescription">
        We want to hear what you think about your dental treatment with
        {{ this.clinicName }}. Please complete the following details to provide a
        review (it should take about a minute).
    </p>
    <form class="form" hiddenInput [formGroup]="clinicReviewForm" (ngSubmit)="onSubmit()">
        <header class="form-header">
            <h3 class="form-title" i18n="@@rating">Rating</h3>
        </header>
        <div class="form-body">
            <div class="row group-field-wrapper">
                <div class="col-md-6">
                    <fieldset>
                        <div class="stars">
                            <p class="rating-description" i18n="@@ratingDescription">
                                Please rate your experience for the following where 1 star is very
                                bad and 5 stars is very good.
                            </p>
                            <div class="control star-rating">
                                <label class="label" for="" i18n="@@ratingQuality">Quality</label>
                                <input class="input" type="hidden" formControlName="star_quality" />
                                <app-star-review-input-core
                                    (ratingSelectionChanges)="updateRating('star_quality', $event)">
                                </app-star-review-input-core>
                                <div class="error"
                                    *ngIf="isSubmitted && this.clinicReviewForm.get('star_quality')?.errors">
                                    <span *ngIf="this.clinicReviewForm.get('star_quality')?.errors.required"
                                        i18n="@@formInputValidationRequiredField">This value is required</span>
                                </div>
                            </div>
                            <div class="control star-rating">
                                <label class="label" for="" i18n="@@ratingService">Service</label>
                                <input class="input" type="hidden" formControlName="star_service" />
                                <app-star-review-input-core
                                    (ratingSelectionChanges)="updateRating('star_service', $event)">
                                </app-star-review-input-core>
                                <div class="error" *ngIf="this.clinicReviewForm.get('star_service')?.errors">
                                    <span
                                        *ngIf="this.isSubmitted && this.clinicReviewForm.get('star_service')?.errors.required"
                                        i18n="@@formInputValidationRequiredField">
                                        This value is required
                                    </span>
                                </div>
                            </div>
                            <div class="control star-rating">
                                <label class="label" for="" i18n="@@ratingCleanliness">Cleanliness</label>
                                <input class="input" type="hidden" formControlName="star_cleanliness" />
                                <app-star-review-input-core (ratingSelectionChanges)="
                        updateRating('star_cleanliness', $event)
                      "></app-star-review-input-core>
                                <div class="error" *ngIf="this.clinicReviewForm.get('star_cleanliness')?.errors">
                                    <span
                                        *ngIf="this.isSubmitted && this.clinicReviewForm.get('star_cleanliness')?.errors.required"
                                        i18n="@@formInputValidationRequiredField">
                                        This value is required
                                    </span>
                                </div>
                            </div>
                            <div class="control star-rating">
                                <label class="label" for="" i18n="@@ratingComfort">Comfort</label>
                                <input class="input" type="hidden" formControlName="star_comfort" />
                                <app-star-review-input-core
                                    (ratingSelectionChanges)="updateRating('star_comfort', $event)">
                                </app-star-review-input-core>
                                <div class="error" *ngIf="this.clinicReviewForm.get('star_comfort')?.errors">
                                    <span
                                        *ngIf="this.isSubmitted && this.clinicReviewForm.get('star_comfort')?.errors.required"
                                        i18n="@@formInputValidationRequiredField">
                                        This value is required
                                    </span>
                                </div>
                            </div>
                            <div class="control star-rating">
                                <label class="label" for="" i18n="@@ratingCommunication">Communication</label>
                                <input class="input" type="hidden" formControlName="star_communication" />
                                <app-star-review-input-core
                                    (ratingSelectionChanges)="updateRating('star_communication', $event)">
                                </app-star-review-input-core>
                                <div class="error" *ngIf="this.clinicReviewForm.get('star_communication')?.errors">
                                    <span
                                        *ngIf="this.isSubmitted && this.clinicReviewForm.get('star_communication')?.errors.required"
                                        i18n="@@formInputValidationRequiredField">
                                        This value is required
                                    </span>
                                </div>
                            </div>
                            <div class="control star-rating">
                                <label class="label" for="" i18n="@@ratingValue">Value</label>
                                <input class="input" type="hidden" formControlName="star_value" />
                                <app-star-review-input-core
                                    (ratingSelectionChanges)="updateRating('star_value', $event)">
                                </app-star-review-input-core>
                                <div class="error"
                                    *ngIf="this.isSubmitted && this.clinicReviewForm.get('star_value')?.errors">
                                    <span *ngIf="this.clinicReviewForm.get('star_value')?.errors.required"
                                        i18n="@@formInputValidationRequiredField">
                                        This value is required
                                    </span>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="col-md-6">
                    <fieldset>
                        <div class="control">
                            <p class="label" i18n="@@doctorOnTime">Was the doctor on time?</p>
                            <label class="label--inline" for="on_time">
                                <input type="radio" id="on_time" formControlName="on_time" value="true"
                                    (change)="resetMinutesLate()" />
                                <ng-container i18n="@@formLabelYes">Yes</ng-container>
                            </label>
                            <label class="label--inline" for="not_on_time">
                                <input type="radio" id="not_on_time" formControlName="on_time" value="false"
                                    (change)="resetMinutesLate()" />
                                <ng-container i18n="@@formLabelNo">No</ng-container>
                            </label>
                            <div *ngIf="this.clinicReviewForm.get('on_time')?.value === 'false'" class="general-form">
                                <br/>
                                <label class="label--inline" for="minutes_late" i18n="@@doctorNotOnTime">
                                    If not, how many minutes late was the doctor?
                                </label>
                                <input pInputText class="input--number" id="minutes_late" type="number"
                                    formControlName="minutes_late" />
                            </div>
                            <div class="error" *ngIf="
                      this.isSubmitted && this.clinicReviewForm.get('on_time')?.errors
                    ">
                                <span *ngIf="this.clinicReviewForm.get('on_time')?.errors.required"
                                    i18n="@@formInputValidationRequiredField">This value is required</span>
                            </div>
                        </div>
                    </fieldset>
                    <br />
                    <fieldset>
                        <div class="general-form">
                            <label for="appointmentDate">Date of Appointment</label>
                            <p-calendar formControlName="appointment_date" dateFormat="yy-mm-dd" id="appointmentDate"
                                [appendTo]="'body'" class="w-100 w-100-calendar" [disabledDays]="[0]"></p-calendar>
                            <div class="error"
                                *ngIf="this.isSubmitted && this.clinicReviewForm.get('appointment_date')?.errors">
                                <span *ngIf="this.clinicReviewForm.get('appointment_date')?.errors.required"
                                    i18n="@@formInputValidationRequiredField">This value is required</span>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <div class="general-form">
                            <label class="label" for="comment" i18n="@@comment">Comment</label>
                            <textarea pInputTextarea id="comment" class="input" formControlName="comment" cols="80"
                                rows="5"></textarea>
                            <div class="error" *ngIf="this.isSubmitted && this.clinicReviewForm.get('comment')?.errors">
                                <span *ngIf="this.clinicReviewForm.get('comment')?.errors.required"
                                    i18n="@@formInputValidationRequiredField">This value is required</span>
                                <span *ngIf="this.clinicReviewForm.get('comment')?.errors.minLength"
                                    i18n="@@formInputValidationRequiredField">This value is required</span>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="col-md-12">
                    <div class="hr"></div>
                </div>
            </div>

            <div class="row group-field-wrapper-2">
                <div class="col-md-6">
                    <div class="general-form">
                        <label class="label" for="first_name" i18n="@@formLabelFirstName">First Name</label>
                        <input pInputText class="input" id="first_name" type="text"
                            i18n-placeholder="@@formInputFirstNamePlaceholder" placeholder="Please type your first name"
                            formControlName="first_name" />
                        <div class="error" *ngIf="this.isSubmitted && this.clinicReviewForm.get('first_name')?.errors">
                            <span *ngIf="this.clinicReviewForm.get('first_name')?.errors.required"
                                i18n="@@formInputValidationRequiredField">This value is required</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="general-form">
                        <label class="label" for="last_name" i18n="@@formLabelLastName">Last Name</label>
                        <input pInputText class="input" id="last_name" type="text"
                            i18n-placeholder="@@formInputLastNamePlaceholder" placeholder="Please type your last name"
                            formControlName="last_name" />
                        <div class="error" *ngIf="this.isSubmitted && this.clinicReviewForm.get('last_name')?.errors">
                            <span *ngIf="this.clinicReviewForm.get('last_name')?.errors.required"
                                i18n="@@formInputValidationRequiredField">This value is required</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="general-form">
                        <label class="label" for="city" i18n="@@formLabelCity">City</label>
                        <input pInputText class="input" id="city" type="text"
                            i18n-placeholder="@@formInputCityPlaceholder" placeholder="Please type your city"
                            formControlName="city" />
                        <div class="error" *ngIf="this.isSubmitted && this.clinicReviewForm.get('city')?.errors">
                            <span *ngIf="this.clinicReviewForm.get('city')?.errors.required"
                                i18n="@@formInputValidationRequiredField">This value is required</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="general-form">
                        <label class="label" for="country" i18n="@@formLabelCountry">Country</label>
                        <input pInputText class="input" id="country" type="text" i18n-placeholder="@@formLabelCountry"
                            placeholder="Country" formControlName="country" />
                        <div class="error" *ngIf="this.isSubmitted && this.clinicReviewForm.get('country')?.errors">
                            <span *ngIf="this.clinicReviewForm.get('country')?.errors.required"
                                i18n="@@formInputValidationRequiredField">This value is required</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="general-form">
                        <label class="label" for="email" i18n="@@formLabelEmail">E-mail</label>
                        <input pInputText class="input" id="email" type="email" formControlName="email" />
                        <div class="error" *ngIf="this.isSubmitted && this.clinicReviewForm.get('email')?.errors">
                            <span *ngIf="this.clinicReviewForm.get('email')?.errors.required"
                                i18n="@@formInputValidationRequiredField">This value is required</span>
                            <span *ngIf="this.clinicReviewForm.get('email')?.errors.email"
                                i18n="@@formInputValidationEmailField">Invalid e-mail address</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="hr"></div>
                </div>
            </div>
            <div class="row group-field-wrapper-2">
                <div class="col-md-6">
                    <label class="label" for="has_share_photos">
                        <input class="input" type="checkbox" id="has_share_photos" formControlName="has_share_photos" />
                        <ng-container i18n="@@photosShare">Do you have photos to share?</ng-container>
                    </label>
                </div>
                <div class="col-md-6 text-right">
                    <div pTooltip='<div class="tip__box"> <header class="tip__header" i18n="@@photosTipsAll">All photos must be...</header><div class="tip__body"><ul><li i18n="@@photosTips1">Family-friendly</li><li i18n="@@photosTips2">Original, non-copyrighted images</li> <li i18n="@@photosTips3">Non-commercial</li> <li i18n="@@photosTips4">Virus-free</li> <li i18n="@@photosTips5">In .gif, .jpg, or .png format</li> </ul> <span i18n="@@photosTipsMore">Read our complete" <a target="_blank" routerLink="/photo-guidelines"
                                    title="Photo submission guidelines">photo submission guidelines</a></span></div></div>'
                        [escape]="false" >
                        <ng-container>Tips and guidelines <i class="ri-question-line"></i></ng-container>
                    </div>
                </div>

                <div class="col-md-12">
                    <div *ngIf="this.clinicReviewForm.get('has_share_photos')?.value === true">
                        <div class="upload__widget">
                            <app-file-uploader [type]="'photo'" [clinicId]="clinic.id" (uploadedImagesChange)="photosUploaded($event)"
                                (uploadImagePreview)="preUploaded($event)"
                            ></app-file-uploader>
                        </div>
                        <div class="upload__disclaimer">
                            <label class="label--inline" for="share_photos"><input id="share_photos" class="input"
                                    type="checkbox" formControlName="share_photos" />
                                <ng-container i18n="@@photosTermsOfUse">
                                    I am the owner of these photos and I accept the <a routerLink="/terms">Terms of
                                        Use</a>
                                </ng-container>
                            </label>
                            <div class="error" *ngIf="this.isSubmitted && this.clinicReviewForm.get('share_photos')?.errors">
                            <span *ngIf="this.clinicReviewForm.get('share_photos')?.errors.required"
                            i18n="@@formInputValidationRequiredField">This value is required</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <br/>
                    <div class="hr"></div>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12 ">
                    <div class="check-box-wrapper">
                        <label class="label--inline" for="agree"><input class="input" type="checkbox" id="agree"
                                formControlName="agree" />
                            <ng-container i18n="@@reviewAcknowledgement">
                                By checking this box, I certify that this review is my genuine option
                                of this dental provider, and that I have no personal or business
                                affiliation with this dental clinic or its staff, and have not been
                                offered any free incentive or payment originating from the dental
                                clinic to write this review.
                            </ng-container>
                        </label>
                        <div class="error" *ngIf="this.isSubmitted && this.clinicReviewForm.get('agree')?.errors">
                            <span *ngIf="this.clinicReviewForm.get('agree')?.errors.required"
                                i18n="@@formInputValidationRequiredField">This value is required</span>
                            <span *ngIf="this.clinicReviewForm.get('agree')?.errors.requiredTrue"
                                i18n="@@formInputValidationRequiredField">This value is required</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <br/>
                    <p-progressBar mode="indeterminate" *ngIf="isSubmitLoading" [style]="{ height: '3px' }"></p-progressBar>
                    <button pButton class="wb-100" [disabled]="isSubmitLoading">
                        <ng-container i18n="@@reviewSubmitButton">Submit Review</ng-container>
                    </button>
                </div>
            </div>
        </div>
        <footer class="clinic-review__form-footer">
            <input type="hidden" formControlName="clinic_id" />
            <div class="error" *ngIf="this.isSubmitted && this.clinicReviewForm.get('clinic_id')?.errors">
                <span *ngIf="this.clinicReviewForm.get('clinic_id')?.errors.required"
                    i18n="@@formInputValidationClinicId">Clinic ID is missing</span>
            </div>
        </footer>
    </form>
</div>
<p-toast position="bottom-center" key="bcReview"></p-toast>
