export enum RecaptchaActions {
  NewsletterSubscribe = 'newsletter/subscribe',
  AffiliateSignUp = 'affiliate/signup',
  ClinicAppointment = 'clinic/appointment',
  ClinicContact = 'clinic/contact',
  ClinicQuote = 'clinic/quote',
  ClinicReviewUsefulness = 'clinic/review/usefulness',
  Contact = 'contact',
  FreeQuote = 'freequote',
  PartnerSignUp = 'partner/signup',
  ClinicReview = 'clinic/review'
}
