import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '@lib/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    @Inject('env') private env: any,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authToken = this.authService.getUserAuthentication();

    if (authToken && authToken.access_token && authToken.access_token !== '') {
      const tokenValue = this.env.api.jwt_prefix + ' ' + authToken.access_token;

      document.cookie = `access_token=${tokenValue}; Domain=${this.env.host_domain};`;

      const authReq = req.clone({
        headers: req.headers
          .set(
            'Authorization', tokenValue
          )
          .set('Content-Type', 'application/json')
      });

      return next.handle(authReq);
    } else {
      return next.handle(req);
    }
  }
}
