import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IPaging } from './paging.models';
import { PageService } from '../../../features/page/page.service';
import { I18nService } from '../../../i18n/i18n.service';

@Injectable({
  providedIn: 'root'
})
export class PagingService {

  page!: {
    current: number,
    prev: number,
    next: number,
    total: number
  };

  constructor(private router: Router, private pageService: PageService, private i18nService: I18nService) { }

  // Process URL
  processUrl = () => {
    throw new Error('processUrl() is not implemented yet');
  }

  // Add support for old paging with params ?page=1,2,3
  redirectPagingQueryStringToFriendlyUrl = (route: ActivatedRoute, url: string) => {
    route.queryParams.subscribe((p: Params) => {
      if (p.hasOwnProperty('page')) {
        let newFriendlyUrl = `${url.split('?')[0]}${(p['page'] > 1) ? `/${p['page']}` : ''}`;
        this.pageService.responseRedirect(newFriendlyUrl, 301);
      }
    });
  }

  // Check if last fragment is page number 1
  removePage1FromUrl = (route: ActivatedRoute) => {
    route.params.subscribe(async (params) => {
      this.firstPageHasNumberInUrl(404);
    });
  }

  firstPageHasNumberInUrl(responseCode: number) {
    return new Promise((resolve, reject) => {
      const fragments = this.router.url.match(/([^\/]*)\/*$/);

      if (fragments && fragments?.length >= 2) {
        const lastFragment = fragments[1];
        if (lastFragment === '1') {
          if (responseCode === 404) {
            this.pageService.response404(null);
          } else if (responseCode === 301) {
            this.pageService.response301(this.router.url.replace(/\/[^\/]*$/, ''));
          } else {
            reject(new Error('Invalid response code: ' + responseCode));
          }
          resolve(true);
        }
      }

      resolve(false);
    });
  }

  setNavigationLink(paging: IPaging | null) {
    if (paging !== null) {
      const locale = this.i18nService.isGlobalSite()
        ? ''
        : `/${this.i18nService.getCurrentLocale()}`;

      this.pageService.setPrevLink(
        (paging.current && (paging.current !== 1))
          ? `${locale}${this.buildPagingLink(paging.previous || 1)}`
          : '');

      this.pageService.setNextLink(
        ((paging.current || 1) < (paging.pages || 1) && paging.current !== paging.pages)
          ? `${locale}${this.buildPagingLink(paging.next || 1)}`
          : '');
    }
  }

  buildPagingLink(page: number): string {
    const fragments = this.router.url.match(/([^\/]*)\/*$/)
    if (fragments?.length !== 2) return '';

    const lastFragment = fragments[1];
    const link = (page === 1)
      ? isNaN(+lastFragment)
        ? this.router.url
        : this.router.url.replace(/\/[^\/]*$/, '') // remove the existing page number if page === 1
      : isNaN(+lastFragment)
        ? this.router.url + '/' + page
        : this.router.url.replace(/\/[^\/]*$/, '/' + page);
    return link;
  }

  setPaging = (currentPage: number, pageSize: number, totalAmount: number): IPaging => {
    if (!pageSize) throw new Error('pageSize is undefined');
    if (!currentPage) currentPage = 1;
    if (!totalAmount) totalAmount = 1;

    const pageCount = Math.ceil(totalAmount / pageSize);

    if (pageCount && currentPage > pageCount) {
      throw new Error(`Error in setPaging(): Given page (${currentPage}) is larger than total of pages (${pageCount})`);
    }

    return {
      total: totalAmount,
      size: pageSize,
      pages: pageCount,
      current: currentPage,
      next: (currentPage < pageCount) ? currentPage + 1 : pageCount,
      previous: (currentPage > 1) ? currentPage - 1: 1
    }
  }

  getSearchPageNumberFromUrlParams = (params: Params): number => {
    if (!params) {
      throw Error('Error in getSearchPageNumberFromUrlParams(): missing params argument.');
    }

    if (params['paging']) {
      if (isNaN(+params['paging'])) {
        throw Error('Error in getSearchPageNumberFromUrlParams(): paging is expected to be a number.');
      }

      if (+params['paging'] < 1) {
        throw Error('Error in getSearchPageNumberFromUrlParams(): paging cannot less than 1');
      }
    }

    return (params['paging'] && !isNaN(+params['paging']))
      ? +params['paging']
      : (params['cityOrPaging'] && !isNaN(+params['cityOrPaging']))
        ? +params['cityOrPaging']
        : (params['provinceOrPaging'] && !isNaN(+params['provinceOrPaging']))
          ? +params['provinceOrPaging']
          : (params['countryOrPaging'] && !isNaN(+params['countryOrPaging']))
            ? +params['countryOrPaging']
            : 1;
  }
}
