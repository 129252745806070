import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { PARTNERSHIP_FORM_ENUM } from '@lib/shared-core/components/partner-signup-core/partner-signup-core.component';

@Component({
  selector: 'app-why-partner',
  templateUrl: './why-partner.component.html',
  styleUrls: ['../../../components/partner-signup-core/partner-signup-core.component.scss',
    './why-partner.component.scss']
})
export class WhyPartnerComponent implements OnInit {

  partnershipFormEnum = PARTNERSHIP_FORM_ENUM;
  @Input() clinicsCount = 0;
  @Input() countriesCount = 0;
  @Input() siteName!: string;

  @Output() partnerFormState: EventEmitter<PARTNERSHIP_FORM_ENUM> = new EventEmitter();

  whyDepartures: Array<{title: string, subtitle: string, icon: string}> = [];

  constructor(private titlePipe: TitleCasePipe) { }

  ngOnInit() {
    this.whyDepartures = [
      {
        title: `${this.titlePipe.transform(this.siteName)} Departures is #1`,
        subtitle: 'We are the #1 place for patients to find global clinics',
        icon: 'https://dd-become-a-partner.s3.ap-southeast-1.amazonaws.com/1.svg'
      },
      {
        title: 'Free Photos of Your Clinic',
        subtitle: `${this.titlePipe.transform(this.siteName)} Departures takes high resolution photos of your clinic *only specific countries`,
        icon: 'https://dd-become-a-partner.s3.ap-southeast-1.amazonaws.com/2.svg'
      },
      {
        title: 'No set-up fees',
        subtitle: 'No set-up, monthly, annual or premium listing fees',
        icon: 'https://dd-become-a-partner.s3.ap-southeast-1.amazonaws.com/3.svg'
      },
      {
        title: 'Pay For Completed Appointments',
        subtitle: `Pay ${this.titlePipe.transform(this.siteName)} Departures a commission`,
        icon: 'https://dd-become-a-partner.s3.ap-southeast-1.amazonaws.com/4.svg'
      },
      {
        title: 'Patients Pay You',
        subtitle: `Patients pay your clinic and then your clinic pays ${this.titlePipe.transform(this.siteName)} Departures a commission afterwards`,
        icon: 'https://dd-become-a-partner.s3.ap-southeast-1.amazonaws.com/5.svg'
      },
      {
        title: '24/7 Customer Care Support',
        subtitle: 'Customer Care for patients and your clinic',
        icon: 'https://dd-become-a-partner.s3.ap-southeast-1.amazonaws.com/6.svg'
      },
      {
        title: 'Appointments are Free',
        subtitle: 'Customer do not pay to schedule an appointment',
        icon: 'https://dd-become-a-partner.s3.ap-southeast-1.amazonaws.com/7.svg'
      },
      {
        title: 'Multi-Currency Pricing',
        subtitle: `Your ${this.siteName} clinic pricing will be priced in 8 different currencies`,
        icon: 'https://dd-become-a-partner.s3.ap-southeast-1.amazonaws.com/8.svg'
      },
    ];
  }

}
