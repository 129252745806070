<div class="forgot-wrapper">
  <h1 i18n="@@forgotPassword">Forgot password</h1>
  <div class="forgot-password">
    <ng-container *ngIf="!isTokenSent; else resetPasswordTokenSent">
      <p i18n="@@resetPasswordEmailNote">Enter your email address, and we'll send you a link to reset your password.</p>
      <br />
      <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" hiddenInput>
        <div class="general-form">
          <app-custom-form-field>
            <input pInputText #input customCoreInput i18n i18n-placeholder="@@formLabelEmail" [inputElement]="input" type="email" placeholder="Email address"
              class="input-field mb-0" id="email" aria-describedby="emailHelp" formControlName="email" required>
          </app-custom-form-field>
        </div>
        <br />
        <div class="alert-text-danger" *ngIf="loginError">{{loginError}}</div>
        <p-progressBar mode="indeterminate" *ngIf="processing" [style]="{ height: '3px' }"></p-progressBar>
        <button pButton type="submit" i18n="@@send" [disabled]="processing" class='wb-100'
          [disabled]="!forgotPasswordForm?.valid || processing">Send
        </button>
        <br/>
        <button pButton type="button" (click)="updateAccountState(modalAccountAction.LOGIN)" i18n="@@appointmentCancel"
          class="p-element wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary p-component">Cancel
        </button>
      </form>

    </ng-container>
    <ng-template #resetPasswordTokenSent>
      <p i18n="@@resetPasswordNote">A link to reset your password is sent to your email,
        <span class="font-weight-bold">{{ email }}</span>. Please check your inbox.
      </p>
      <hr>
      <button pButton type="button" i18n="@@backToLoginPage" (click)="updateAccountState(modalAccountAction.LOGIN)"
      class="p-element wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary p-component">
      Back to login Page
    </button>

    </ng-template>
  </div>
