import { Component, OnInit } from '@angular/core';
import { CarouselModule } from 'primeng/carousel';
import { SwiperOptions } from 'swiper';
interface WhyList {
    icon?: string;
    label: string;
    description?: string;

}

@Component({
    selector: 'app-why-dental',
    templateUrl: './why-dental.component.html',
    styleUrls: ['./why-dental.component.scss']
})

export class WhyDentalComponent implements OnInit {

    whyList !: WhyList[];
    config!: SwiperOptions;
    disabled: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
        this.whyList = [
            { icon: 'ri-mic-line',
              label: $localize`:@@whyListLabel1:Superior Customer Service`,
              description: $localize`:@@whyListDescription1:24/7 availability with a 4.6 Rating` },
            { icon: 'ri-image-2-line',
              label: $localize`:@@whyListLabel2:Know before you go`,
              description: $localize`:@@whyListDescription2:19,902 high resolution photos, 141 virtual tours` },
            { icon: 'ri-group-line',
              label: $localize`:@@whyListLabel3:Real reviews from Real People`,
              description: $localize`:@@whyListDescription3:Read 83,486 past patient reviews` },
            { icon: 'ri-hospital-line',
              label: $localize`:@@whyListLabel4:Quality Clinic`,
              description: $localize`:@@whyListDescription4:All Dental Departures certified doctors and clinics are pre-screened` },
            { icon: 'ri-price-tag-3-line',
              label: $localize`:@@whyListLabel5:Free Quote`,
              description: $localize`:@@whyListDescription5:Fast, accurate quotes in 25 currencies` },
            { icon: 'ri-calendar-2-line',
              label: $localize`:@@whyListLabel6:Easy Booking`,
              description: $localize`:@@whyListDescription6:Schedule your appointment online or call Toll Free` },
            { icon: 'ri-hand-heart-line',
              label: $localize`:@@whyListLabel7:Dental Financing`,
              description: $localize`:@@whyListDescription7:Get the dental care today at competitive interest rates` },
            { icon: 'ri-check-double-line',
              label: $localize`:@@whyListLabel8:Dental Travel Insurance`,
              description: $localize`:@@whyListDescription8:Get your Free online quote for dental insurance today` },
        ];

        this.config = this.swiperConfig();
    }

    swiperConfig(): any {
        return {
            a11y: { enabled: true },
            updateOnWindowResize: true,
            direction: 'horizontal',
            slidesPerView: 4,
            keyboard: false,
            mousewheel: false,
            scrollbar: false,
            navigation: true,
            pagination: false,
            breakpoints: {
                120: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                500: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 4,
                    spaceBetween: 10
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 10
                },
                1124: {
                    slidesPerView: 6,
                    spaceBetween: 10
                },
                1510: {
                    slidesPerView: 8,
                    spaceBetween: 10
                },
            }
        };
    }

}
