<div class="general-margin">
  <div class="row justify-content-center my-5">
    <h3 i18n="@@proofSeeRates">Discover Real Customer Reviews</h3>
  </div>
  <div class="container mb-5 how-we-rate-container">
    <div class="row justify-content-center">
      <div class="col">
        <swiper class="swiper-containers" fxFlex="auto" [config]="config" [disabled]="disabled">
          <ng-container *ngFor="let product of review">
            <div class="swiper-slide swiper-options-header">
              <div fxLayout="column">
                <div>
                  <div *ngIf="product.name !== 'liveChat'" class="rating__link-box rating-livechat"
                    [ngClass]="{'rating-reviewcentre':product.name == 'reviewcentre','rating-feefo':product.name == 'feefo'}">
                    <div class="rating__body">
                      <a target="_blank"
                         attr.aria-label="{{product.name == 'reviewcentre' ? 'Dental Departures reviews on Review Center' :
                         'Dental Departures reviews on Feefo'}}"
                        href="{{product.link}}">
                        <img class="rating__body-image" loading="lazy" [ngClass]="
                              {'rating__body-image--reviewcentre' : product.name == 'reviewcentre','rating__body-image--feefo' : product.name == 'feefo'}
                              " src="{{ product.name == 'reviewcentre' ? 'https://static.medicaldepartures.com/DD-Review-center.png?format=webp&width=196' : 'https://static.medicaldepartures.com/DD-feefo.png?format=webp&width=195'
                              }}" alt="" />
                      </a>
                      <div class="rating__body-stat">
                        <div class="rating__body-score">
                          {{ product.name == 'feefo' ? this.common.stats.external_reviews.feefo.rating :
                          this.common.stats.external_reviews.review_centre.rating }}/{{ product.name == 'feefo'
                          ?this.common.stats.external_reviews.feefo.rating_scale:
                          this.common.stats.external_reviews.review_centre.rating_scale }}
                        </div>
                        <div class="rating__body-text">
                          <span class="rating__body-stars">
                            <i *ngFor="let item of [].constructor(product.star); let i= index"
                              class="rating__body-star fas fa-star"></i>
                          </span>
                          <ng-container i18n="@@proofFromReviews">
                            {{ product.name == 'feefo' ? this.common.stats.external_reviews.feefo.reviews :
                            this.common.stats.external_reviews.review_centre.reviews | number }} reviews
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="rating__link-box rating-livechat  " *ngIf="product.name == 'liveChat'">
                    <a class="rating-livechat" [attr.aria-label]="'Google Map Reviews for ' + platFormSite == 'Dental' ? 'Dental' : 'Medical' + 'Departures'"
                       href="{{ platFormSite == 'Dental' ? 'https://maps.app.goo.gl/GgKv6V1MPwZYCjcB8' : 'https://maps.app.goo.gl/cqjMgJCLSaicExZZ9' }}" target="_blank">

                      <div class="rating__body">
                        <p class="mb-2">EXCELLENT SERVICE</p>
                        <div class="rating__body-text row justify-content-center ">
                          <span class="rating__body-stars">
                            <i *ngFor="let item of [].constructor(product.star); let i= index"
                              class="cst-star fas fa-star"></i>
                          </span>
                        </div>
                        <div class="row justify-content-center align-items-center rating_text">
                          <p class="cst-p">{{platFormSite == 'Dental' ? '4.5' : '4.2'}}/5.0</p>
                          <p>{{platFormSite == 'Dental' ? '130' : '16'}} Ratings</p>
                        </div>
                        <div class="row justify-content-around cst-footer">
                          <p>Google Map Reviews for {{ platFormSite == 'Dental' ? 'Dental' : 'Medical' }} Departures</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </swiper>

      </div>
    </div>

  </div>
</div>
